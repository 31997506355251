import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../App';
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContext } from '../../../LoaderProvider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function EditVehicle() {
    const [checkPassword, setCheckPassword] = useState(true);
    const [formChange, setFormChange] = useState('driver');
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { isLoading, setIsLoading } = useContext(LoaderContext);

    const navigate = useNavigate()
    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {

        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await axios.get(`https://xertzdigital.net:8001/api/v1/accounts/company/drivers-short/`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
                    },
                });

                setData(response.data);

            } catch (err) {
                setError(err.message || 'Xato yuz berdi!');
            } finally {
                setLoading(false);
                setIsLoading(false)
            }
        };

        if (accessToken) {
            fetchData();
        } else {
            setError('AccessToken topilmadi!');
            setLoading(false);
        }
    }, [accessToken]);






    // ---------------------------------------------------------------

    const [driverInfo, setDriverInfo] = useState({
        vehicle_number: "",
        license_plate: "",
        license_plate_state: "",
        fuel_type: '',
        make: "",
        model: "",
        vin: "",
        year: "",
        driver: "",
        eld_device_type: "",
        notes: "",
        eld_serial_number: ""
    });


    const params = useParams();

    useEffect(() => {

        const fectData = async () => {
            try {
                const response = await axios.get(`https://xertzdigital.net:8001/api/v1/accounts/vehicle/${params.id}/`);
                setDriverInfo(response.data);


            } catch (e) {
                console.log("error message", e);

            }
        }

        fectData()



    }, [params.id])


    const handleEditPassword = async (e) => {
        e.preventDefault();



        setCheckPassword(true)
        try {
            const response = await axios.put(`https://xertzdigital.net:8001/api/v1/accounts/vehicle/${params.id}/update/`, driverInfo);
            console.log("Data saved:", response.data);
            navigate('/layout/vehicles/')

            if (response.status == 400) {
                alert(response.data)
            }

        } catch (error) {
            console.error("Error saving data:", error);
        }

        // Agar "Save" bosilgan bo'lsa, API ga ma'lumotlarni yuboramiz


    };

    const handleInputChangePassword = (e) => {
        const { name, value } = e.target;
        setDriverInfo((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
        console.log(driverInfo);

    };


    const user = useContext(UserContext)



    return (
        <div className='pl-2 pr-2' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>



            <main className="main-content">

                <div>
                    {isLoading && (
                        <div style={
                            {
                                width: "100%",
                                height: "100vh",
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: 'center',
                                position: "absolute",
                                top: "0px",
                                bottom: "0px",
                                left: "100px",
                                right: "0px",
                            }}>
                            <CircularProgress />
                        </div>
                    )}

                </div>

                <div className=' w-full pl-2 pr-2'>


                    <form onSubmit={handleEditPassword} className='p-3'>

                        <div className="flex items-center justify-between">
                            <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Edit vehicle</h2>
                            <div className="flex items-center gap-3">
                                <Link to='/layout/vehicles' className="edit-button">
                                    Cansel
                                </Link>
                                <button className="edit-button" type="submit">
                                    Save
                                </button>
                            </div>
                        </div>



                        {/* ---------------------------------------------------------- */}

                        <div className='flex items-start justify-between gap-4' >
                            <div className="w-[33.3%]">

                                <div className="form-group">
                                    <label>Truck number (unit)</label>
                                    <input
                                        type="text"
                                        name="vehicle_number"
                                        value={driverInfo.vehicle_number}
                                        onChange={handleInputChangePassword}
                                        placeholder='Enter your Truck number'
                                        className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'


                                    />
                                </div>

                                <div className="form-group">
                                    <label>License plate no</label>
                                    <input
                                        type="text"
                                        name="license_plate"
                                        value={driverInfo.license_plate}
                                        onChange={handleInputChangePassword}
                                        maxLength={100}
                                        placeholder='Enter your License plate'
                                        className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'

                                    />
                                </div>

                                <div className="form-group">
                                    <label>License plate issuing state</label>
                                    <input
                                        type="text"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.license_plate_state}
                                        name="license_plate_state"
                                        className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        placeholder='Enter your License plate issuing'

                                        minLength={1}
                                        maxLength={2}
                                    />

                                </div>

                                <div className="form-group">
                                    <label>Fuel type</label>
                                    {/* <select
                                        onChange={handleInputChangePassword}
                                        name="fuel_type"
                                        value={driverInfo.fuel_type}

                                        className=' focus:outline-none border-[1px] bg-gray-100 p-[10px] text-gray-400'
                                    >
                                        <option value="" selected>Fuel type</option>
                                        <option value="Diesel">Diesel</option>
                                        <option value="Gasoline">Gasoline</option>
                                        <option value="Electric">Electric</option>
                                        <option value="Hybrid">Hybrid</option>
                                    </select> */}

                                    <FormControl fullWidth>
                                        <Select

                                            onChange={handleInputChangePassword}
                                            name="fuel_type"
                                            value={driverInfo.fuel_type}
                                            className='focus:outline-none bg-gray-100  text-gray-400 h-[40px]'
                                        >



                                            <MenuItem value={driverInfo.fuel_type} selected>{driverInfo.fuel_type}</MenuItem>
                                            <MenuItem value="Diesel">Diesel</MenuItem>
                                            <MenuItem value="Gasoline">Gasoline</MenuItem>
                                            <MenuItem value="Electric">Electric</MenuItem>
                                            <MenuItem value="Hybrid">Hybrid</MenuItem>



                                        </Select>
                                    </FormControl>

                                </div>

                            </div>


                            <div className="w-[33.3%]">

                                <div className="form-group">
                                    <label>Make</label>
                                    <input
                                        type="text"
                                        name="make"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.make}
                                        placeholder='Enter your make'
                                        maxLength={100}
                                        className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'

                                    />
                                </div>

                                <div className="flex flex-col form-group">
                                    <label className='font-semibold'>Model</label>
                                    <div className='w-full flex items-center justify-betwee'>
                                        <input
                                            type="text"
                                            name="model"
                                            onChange={handleInputChangePassword}
                                            value={driverInfo.model}
                                            maxLength={50}
                                            placeholder='Enter model'
                                            className='text-black w-full bg-gray-100 border-none focus:outline-none placeholder:text-gray-400'

                                        />

                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>VIN</label>
                                    <input
                                        type="text"
                                        name="vin"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.vin}
                                        maxLength={16}
                                        placeholder='Enter vin'
                                        className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'

                                    />

                                </div>

                                <div className="form-group">
                                    <label>Year</label>
                                    <input
                                        type='number'
                                        name="year"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.year}
                                        placeholder='Enter your notes'
                                        maxLength={100}
                                        className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'

                                    />
                                </div>

                            </div>

                            <div className='w-[33.3%]'>


                                <div className="form-group">
                                    <label>Driver</label>
                                    {/* <select
                                        onChange={handleInputChangePassword}
                                        name='driver'
                                        value={driverInfo.driver}
                                        className='focus:outline-none border-[1px] bg-gray-100 p-[10px] text-gray-400'
                                    >
                                        <option value="" selected>{driverInfo.driver}</option>
                                        {data.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.first_name}
                                            </option>
                                        ))}
                                    </select> */}


                                    <FormControl fullWidth>
                                        <Select

                                            onChange={handleInputChangePassword}
                                            name='driver'
                                            value={driverInfo.driver}
                                            className='focus:outline-none  bg-gray-100  text-gray-400 h-[40px]'
                                        >
                                            {data.map((item) => (

                                                <MenuItem key={item.id} value={item.id}>{item.first_name}</MenuItem>

                                            ))}

                                        </Select>
                                    </FormControl>


                                </div>


                                <div className="form-group">
                                    <label>Eld device</label>
                                    {/* <select
                                        onChange={handleInputChangePassword}
                                        name="eld_device_type"
                                        value={driverInfo.eld_device_type}
                                        className=' focus:outline-none border-[1px] bg-gray-100 p-[10px] text-gray-400'

                                    >
                                        <option value="" selected className='p-3'>{driverInfo.eld_device_type}</option>
                                        <option value="pt">pt</option>
                                        <option value="iosix">IOSiX</option>
                                    </select> */}


                                    <FormControl fullWidth>
                                        <Select

                                            onChange={handleInputChangePassword}
                                            name="eld_device_type"
                                            value={driverInfo.eld_device_type}
                                            className='focus:outline-none  bg-gray-100  text-gray-400 h-[40px]'
                                        >


                                            <MenuItem value="">{driverInfo.eld_device_type}</MenuItem>
                                            <MenuItem value="pt">PT</MenuItem>
                                            <MenuItem value="iosix">IOSIX</MenuItem>



                                        </Select>
                                    </FormControl>


                                </div>



                                <div className="form-group">
                                    <label>Notes</label>
                                    <input
                                        type="text"
                                        name="notes"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.notes}
                                        placeholder='Enter your notes'
                                        maxLength={100}
                                        className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'

                                    />
                                </div>

                                <div className="form-group">
                                    <label>Eld serial number</label>
                                    <input
                                        type="text"
                                        name="eld_serial_number"
                                        onChange={handleInputChangePassword}
                                        value={driverInfo.eld_serial_number}
                                        placeholder='Eld serial number'
                                        maxLength={100}
                                        className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'

                                    />
                                </div>

                            </div>

                        </div>


                    </form>


                </div>
            </main>

        </div>
    )
}

export default EditVehicle;