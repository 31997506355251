import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';

import passwordHideIcon from './../../Assets/signin/Component 39.png'
import passwordShowIcon from './../../Assets/signin/Component 38.png'
import { UserContext } from '../../../App';
import { adminCompanies, AdminData } from '../../Registration/AdminCompanies/AdminCompanies';
import { Link, useNavigate } from 'react-router-dom';



function AddDriver() {


    const [checkPassword, setCheckPassword] = useState(true);
    const [showPassword, setShowPassword] = useState(true);
    const [data, setData] = useState([
        { "name": "Alabama", "abbreviation": "AL" },
        { "name": "Alaska", "abbreviation": "AK" },
        { "name": "Arizona", "abbreviation": "AZ" },
        { "name": "Arkansas", "abbreviation": "AR" },
        { "name": "California", "abbreviation": "CA" },
        { "name": "Colorado", "abbreviation": "CO" },
        { "name": "Connecticut", "abbreviation": "CT" },
        { "name": "Delaware", "abbreviation": "DE" },
        { "name": "Florida", "abbreviation": "FL" },
        { "name": "Georgia", "abbreviation": "GA" },
        { "name": "Hawaii", "abbreviation": "HI" },
        { "name": "Idaho", "abbreviation": "ID" },
        { "name": "Illinois", "abbreviation": "IL" },
        { "name": "Indiana", "abbreviation": "IN" },
        { "name": "Iowa", "abbreviation": "IA" },
        { "name": "Kansas", "abbreviation": "KS" },
        { "name": "Kentucky", "abbreviation": "KY" },
        { "name": "Louisiana", "abbreviation": "LA" },
        { "name": "Maine", "abbreviation": "ME" },
        { "name": "Maryland", "abbreviation": "MD" },
        { "name": "Massachusetts", "abbreviation": "MA" },
        { "name": "Michigan", "abbreviation": "MI" },
        { "name": "Minnesota", "abbreviation": "MN" },
        { "name": "Mississippi", "abbreviation": "MS" },
        { "name": "Missouri", "abbreviation": "MO" },
        { "name": "Montana", "abbreviation": "MT" },
        { "name": "Nebraska", "abbreviation": "NE" },
        { "name": "Nevada", "abbreviation": "NV" },
        { "name": "New Hampshire", "abbreviation": "NH" },
        { "name": "New Jersey", "abbreviation": "NJ" },
        { "name": "New Mexico", "abbreviation": "NM" },
        { "name": "New York", "abbreviation": "NY" },
        { "name": "North Carolina", "abbreviation": "NC" },
        { "name": "North Dakota", "abbreviation": "ND" },
        { "name": "Ohio", "abbreviation": "OH" },
        { "name": "Oklahoma", "abbreviation": "OK" },
        { "name": "Oregon", "abbreviation": "OR" },
        { "name": "Pennsylvania", "abbreviation": "PA" },
        { "name": "Rhode Island", "abbreviation": "RI" },
        { "name": "South Carolina", "abbreviation": "SC" },
        { "name": "South Dakota", "abbreviation": "SD" },
        { "name": "Tennessee", "abbreviation": "TN" },
        { "name": "Texas", "abbreviation": "TX" },
        { "name": "Utah", "abbreviation": "UT" },
        { "name": "Vermont", "abbreviation": "VT" },
        { "name": "Virginia", "abbreviation": "VA" },
        { "name": "Washington", "abbreviation": "WA" },
        { "name": "West Virginia", "abbreviation": "WV" },
        { "name": "Wisconsin", "abbreviation": "WI" },
        { "name": "Wyoming", "abbreviation": "WY" }
      ]);


    const navigate = useNavigate()
    const companyId = localStorage.getItem('companyId');


    const [driverInfo, setDriverInfo] = useState({
        first_name: "",
        last_name: "",
        email: "",
        credential: "",
        password: "",
        notes: "",
        company: companyId,
        licence_state: "",
        phone: "",
        licence_number: "",
    });
    const handleEditPassword = async (e) => {
        e.preventDefault();



        setCheckPassword(true)
        try {
            const response = await axios.post("https://xertzdigital.net:8001/api/v1/accounts/drivers/create/", driverInfo);
            console.log("Data saved:", response.data);
            navigate("/layout/driver")
            // Ma'lumotlar saqlandi, tahrirlash rejimini o'chiramiz

        } catch (error) {
            console.error("Error saving data:", error);
            alert("An error occurred while saving the data.")
        }

        // Agar "Save" bosilgan bo'lsa, API ga ma'lumotlarni yuboramiz


    };

    const handleInputChangePassword = (e) => {
        const { name, value } = e.target;
        setDriverInfo((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));





    };




    return (
        <div className=' pl-2 pr-2'>

   


            <main className="main-content">
              
                <form onSubmit={handleEditPassword} className='p-3'>

                    <div className="flex items-center justify-between">
                        <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>New driver</h2>
                        <div className="flex items-center gap-3">
                            <Link to='/layout/driver' className="edit-button" >
                                Cansel
                            </Link>
                            <button className="edit-button" type="submit">
                                Save
                            </button>
                        </div>
                    </div>

                    <div className="border-b-2 pb-2 mb-[30px] ">

                        <span className="tab border-b-2 pb-2 font-semibold" style={{ borderColor: '#1996F0', color: '#1996F0' }}>Driver informations</span>
                    </div>

                    {/* ---------------------------------------------------------- */}

                    <div className='flex items-start justify-between gap-4'>
                        <div className="w-[33.3%]">

                            <div className="form-group">
                                <label className=''>First name</label>
                                <input
                                    type="text"
                                    name="first_name"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your first name'

                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className=''>Last name</label>
                                <input
                                    type="text"
                                    name="last_name"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your first name'

                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label className=''>Email address</label>
                                <input
                                    type="email"
                                    name="email"

                                    onChange={handleInputChangePassword}

                                    maxLength={100}
                                    placeholder='Enter your email'
                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>



                        </div>


                        <div className="w-[33.3%]">



                            <div className="form-group">
                                <label className=''>Password</label>
                                <div className='w-full flex items-center justify-between bg-gray-100 border-[1px] pr-1 rounded-md'>
                                    <input
                                        type={showPassword ? "password" : 'text'}
                                        name="password"
                                        style={{ border: "none" }}
                                        onChange={handleInputChangePassword}

                                        maxLength={50}
                                        className='text-black w-full focus:outline-none placeholder:text-gray-400 bg-transparent '
                                        required
                                    />
                                    <img src={showPassword ? passwordHideIcon : passwordShowIcon} alt="" width={20} onClick={() => setShowPassword(showPassword != true)} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className=''>Credential</label>
                                <input
                                    type="text"
                                    name="credential"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your login name'

                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label className=''>Notes</label>
                                <input
                                    type="text"
                                    name="notes"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your notes'
                                    maxLength={100}
                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>



                        </div>

                        <div className='w-[33.3%]'>
                            <div className="form-group">
                                <label className=''>Phone number</label>
                                <input
                                    type="text"
                                    name="phone"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your phone number'
                                    maxLength={100}
                                    className='text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className=''>Drivers license number</label>
                                <input
                                    type="text"
                                    name="licence_number"

                                    onChange={handleInputChangePassword}

                                    placeholder='Enter your license number'
                                    maxLength={100}
                                    className=' text-black focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                    required
                                />
                            </div>

                            <div className='form-group'>
                                <label htmlFor="company">Driver's license issuing state</label>
                                <select
                                    
                                    name="licence_state"
                                    id="company"                            
                                    onChange={handleInputChangePassword}                                
                                    
                                    className=' focus:outline-none border-[1px] text-[16] bg-gray-100 p-[10px] text-gray-400'
                                    required
                                >
                                    <option value="" selected >Enter your license state</option>
                                    {
                                        data.map((item) =>(
                                           <option value={item.abbreviation} key={item.name} className='text-black'>{item.abbreviation}</option>
                                        ))
                                    }
                                </select>
                            </div>

                        </div>

                    </div>


                </form>
            </main>

        </div>
    )
}

export default AddDriver

