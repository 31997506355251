import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



function CreateVehicle() {
    const [checkPassword, setCheckPassword] = useState(true);
    const [formChange, setFormChange] = useState('driver');
    const [data, setData] = useState([]);
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const accessToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://xertzdigital.net:8001/api/v1/accounts/company/drivers-short/`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
                    },
                });
                console.log("short driver", response);

                setData(response.data);

            } catch (err) {
                setError(err.message || 'Xato yuz berdi!');
            } finally {
                setLoading(false);
            }
        };

        if (accessToken) {
            fetchData();
        } else {
            setError('AccessToken topilmadi!');
            setLoading(false);
        }
    }, [accessToken]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://xertzdigital.net:8001/api/v1/accounts/makes/?page=1`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
                    },
                });
                console.log("make: ", response);

                setMake(response.data);

            } catch (err) {
                setError(err.message || 'Xato yuz berdi!');
            } finally {
                setLoading(false);
            }
        };

        if (accessToken) {
            fetchData();
        } else {
            setError('AccessToken topilmadi!');
            setLoading(false);
        }
    }, [accessToken]);




    const companyId = localStorage.getItem('companyId')

    // ---------------------------------------------------------------

    const [driverInfo, setDriverInfo] = useState({
        vehicle_number: "",
        license_plate: "",
        license_plate_state: "",
        fuel_type: '',
        make: "",
        model: "",
        vin: "",
        year: "",
        driver: "",
        eld_device_type: "",
        notes: "",
        eld_serial_number: "",
        company: companyId
    });

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://xertzdigital.net:8001/api/v1/accounts/makes/${driverInfo.make}/models/`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
                    },
                });
                console.log("model", response);
                setModel(response.data);

            } catch (err) {
                setError(err.message || 'Xato yuz berdi!');
            } finally {
                setLoading(false);
            }
        };

        if (accessToken) {
            fetchData();
        } else {
            setError('AccessToken topilmadi!');
            setLoading(false);
        }
    }, [accessToken, driverInfo]);

    const [automaticInfo, setAutomaticInfo] = useState({
        truck_number: "",
        license_plate: "",
        license_plate_issuing: "",
        fuel_type: '',
        driver: "",
        eld_device: "",
        notes: ""
    });


    const handleEditPassword = async (e) => {
        e.preventDefault();



        setCheckPassword(true)
        try {
            const response = await axios.post("https://xertzdigital.net:8001/api/v1/accounts/vehicle/create/", driverInfo);
            console.log("Data saved:", response);


            if (response.status == 201) {
                navigate("/layout/vehicles")
            }
            else {

                alert("An error occurred while saving the data.")
            }


        } catch (error) {
            console.error("Error saving data:", error);
        }

        // Agar "Save" bosilgan bo'lsa, API ga ma'lumotlarni yuboramiz


    };

    const handleInputChangePassword = (e) => {
        const { name, value } = e.target;
        setDriverInfo((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
        console.log(driverInfo);

    };

    const handleEditAutomatic = async (e) => {
        e.preventDefault();



        setCheckPassword(true)
        try {
            const response = await axios.post("https://671747adb910c6a6e027483d.mockapi.io/register/user", automaticInfo);
            console.log("Data saved:", response.data);
            alert("your password succesfuly changed")

        } catch (error) {
            console.error("Error saving data:", error);
        }



    };

    const handleInputChangeAutomatic = (e) => {
        const { name, value } = e.target;
        setAutomaticInfo((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
        console.log(driverInfo);

    };


    const formCreator = (value) => {
        setFormChange(value)
    }



    return (
        <div className=' w-full pl-2 pr-2'>

            {
                formChange == 'driver' ?
                    <form onSubmit={handleEditPassword} className='p-3'>

                        <div className="flex items-center justify-between">
                            <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Create vehicle</h2>
                            <div className="flex items-center gap-3">
                                <Link to='/layout/vehicles' className="edit-button">
                                    Cansel
                                </Link>
                                <button className="edit-button" type="submit">
                                    Save
                                </button>
                            </div>
                        </div>

                        <div className=" flex items-center gap-4 border-b-2 mb-[30px] ">

                            <span className="tab border-b-2 pb-3 font-semibold" style={{ borderColor: formChange == 'driver' ? '#1996F0' : '', color: formChange == 'driver' ? '#1996F0' : '' }} onClick={() => formCreator('driver')}>Driver informations</span>
                            <span className="tab border-b-2 pb-3 font-semibold" style={{ borderColor: formChange == 'automatic' ? '#1996F0' : '', color: formChange == 'automatic' ? '#1996F0' : '' }} onClick={() => formCreator('automatic')}>Automatic</span>
                        </div>

                        {/* ---------------------------------------------------------- */}

                        <div className='flex items-start justify-between gap-4'>
                            <div className="w-[33.3%]">

                                <div className="form-group">
                                    <label >Truck number (unit)</label>
                                    <input
                                        type="text"
                                        name="vehicle_number"

                                        onChange={handleInputChangePassword}

                                        placeholder='Enter your Truck number'

                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label >License plate no</label>
                                    <input
                                        type="text"
                                        name="license_plate"

                                        onChange={handleInputChangePassword}

                                        maxLength={100}
                                        placeholder='Enter your License plate'
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label>License plate issuing state</label>
                                    <input
                                        type="text"
                                        onChange={handleInputChangePassword}
                                        name="license_plate_state"
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        placeholder='Enter your License plate issuing'
                                        required
                                        minLength={1}
                                        maxLength={2}
                                    />

                                </div>

                                <div className="form-group">
                                    <label >Fuel type</label>
                                    {/* <select
                                        onChange={handleInputChangePassword}
                                        name="fuel_type"

                                        required
                                        className=' focus:outline-none border-[1px] text-[16] bg-gray-100 p-[10px] text-gray-400'
                                    >
                                        <option value="" selected>Fuel type</option>
                                        <option value="Diesel">Diesel</option>
                                        <option value="Gasoline">Gasoline</option>
                                        <option value="Electric">Electric</option>
                                        <option value="Hybrid">Hybrid</option>
                                    </select> */}

                                    <FormControl fullWidth>
                                        <Select

                                            onChange={handleInputChangePassword}
                                            name="fuel_type"
                                            className='focus:outline-none  bg-gray-100  text-gray-400 h-[40px]'
                                        >


                                            <MenuItem value="" selected>Fuel type</MenuItem>
                                            <MenuItem value="Diesel">Diesel</MenuItem>
                                            <MenuItem value="Gasoline">Gasoline</MenuItem>
                                            <MenuItem value="Electric">Electric</MenuItem>
                                            <MenuItem value="Hybrid">Hybrid</MenuItem>



                                        </Select>
                                    </FormControl>


                                </div>

                            </div>


                            <div className="w-[33.3%]">

                                <div className="form-group">
                                    <label>Make</label>
                                    {/* <select 
                                    onChange={handleInputChangePassword} 
                                    name='make'
                                   className=' focus:outline-none border-[1px] bg-gray-100 p-[10px] text-gray-400'
                                    >
                                        <option value="" selected>Select Make</option>
                                        {make.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select> */}

                                    <FormControl fullWidth>
                                        <Select

                                            onChange={handleInputChangePassword}
                                            name='make'
                                            className='focus:outline-none  bg-gray-100  text-gray-400 h-[40px]'
                                        >

                                            {make.map((item) => (
                                                <MenuItem key={item.id} value={item.id}> {item.title}</MenuItem>
                                            ))}





                                        </Select>
                                    </FormControl>

                                </div>



                                <div className="form-group">
                                    <label>Model</label>
                                    {/* <select
                                        onChange={handleInputChangePassword}
                                        name='model'
                                        className='focus:outline-none border-[1px] bg-gray-100 p-[10px] text-gray-400'
                                    >
                                        <option value="" selected>Select model</option>
                                        {model.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </select> */}

                                    <FormControl fullWidth>
                                        <Select

                                            onChange={handleInputChangePassword}
                                            name='model'
                                            className='focus:outline-none  bg-gray-100  text-gray-400 h-[40px]'
                                        >

                                            {model.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                            ))}




                                        </Select>
                                    </FormControl>
                                </div>


                                <div className="form-group">
                                    <label>VIN</label>
                                    <input
                                        type="text"
                                        name="vin"

                                        onChange={handleInputChangePassword}

                                        maxLength={16}
                                        placeholder='Enter vin'
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />

                                </div>

                                <div className="form-group">
                                    <label >Year</label>
                                    <input
                                        type='number'
                                        name="year"

                                        onChange={handleInputChangePassword}

                                        placeholder='Enter your notes'
                                        maxLength={100}
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />
                                </div>

                            </div>

                            <div className='w-[33.3%]'>


                                <div className="form-group">
                                    <label>Driver</label>
                                    {/* <select 
                                        onChange={handleInputChangePassword}
                                        name='driver'
                                        className=' focus:outline-none border-[1px] bg-gray-100 p-[10px] text-gray-400'
                                    >
                                        <option value="" selected>Select driver</option>
                                        {data.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.first_name}
                                            </option>
                                        ))}
                                    </select> */}
                                    <FormControl fullWidth>
                                        <Select

                                            onChange={handleInputChangePassword}
                                            name='driver'
                                            className='focus:outline-none  bg-gray-100  text-gray-400 h-[40px]'
                                        >

                                            {data.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.first_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>


                                <div className="form-group">
                                    <label>Eld device</label>
                                    {/* <select
                                        onChange={handleInputChangePassword}
                                        name="eld_device_type"

                                        required
                                        className=' focus:outline-none border-[1px] bg-gray-100 p-[10px] text-gray-400'
                                    >
                                        <option value="" selected>Device</option>
                                        <option value="pt">pt</option>
                                        <option value="iosix">IOSiX</option>

                                    </select> */}

                                    <FormControl fullWidth>
                                        <Select

                                            onChange={handleInputChangePassword}
                                            name="eld_device_type"
                                            className='focus:outline-none  bg-gray-100  text-gray-400 h-[40px]'
                                        >

                                            <MenuItem  value="PT">PT</MenuItem>
                                            <MenuItem  value="IOSIX">IOSIX</MenuItem>
                                            
                                        </Select>
                                    </FormControl>

                                </div>

                                <div className="form-group">
                                    <label >Notes</label>
                                    <input
                                        type="text"
                                        name="notes"

                                        onChange={handleInputChangePassword}

                                        placeholder='Enter your notes'
                                        maxLength={100}
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label >Eld serial number</label>
                                    <input
                                        type="text"
                                        name="eld_serial_number"
                                        onChange={handleInputChangePassword}

                                        placeholder='Eld serial number'
                                        maxLength={100}
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />
                                </div>

                            </div>

                        </div>


                    </form>


                    :


                    <form onSubmit={handleEditAutomatic} className='p-3'>

                        <div className="flex items-center justify-between">
                            <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Create vehicle</h2>
                            <div className="flex items-center gap-3">
                                <p className="edit-button">
                                    cansel
                                </p>
                                <button className="edit-button" type="submit">
                                    Save
                                </button>
                            </div>
                        </div>

                        <div className=" flex items-center gap-4 border-b-2 mb-[30px] ">

                            <span className="tab border-b-2 pb-3 font-semibold" style={{ borderColor: formChange == 'driver' ? '#1996F0' : '', color: formChange == 'driver' ? '#1996F0' : '' }} onClick={() => formCreator('driver')}>Driver informations</span>
                            <span className="tab border-b-2 pb-3 font-semibold" style={{ borderColor: formChange == 'automatic' ? '#1996F0' : '', color: formChange == 'automatic' ? '#1996F0' : '' }} onClick={() => formCreator('automatic')}>Automatic</span>
                        </div>

                        {/* ---------------------------------------------------------- */}

                        <div className='flex items-start  gap-4'>
                            <div className="w-[33.3%]">

                                <div className="form-group">
                                    <label >Truck number (unit)</label>
                                    <input
                                        type="text"
                                        name="truck_number"

                                        onChange={handleInputChangeAutomatic}

                                        placeholder='Enter your Truck number'

                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label >License plate no</label>
                                    <input
                                        type="text"
                                        name="license_plate"

                                        onChange={handleInputChangeAutomatic}

                                        maxLength={100}
                                        placeholder='Enter your License plate'
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label>License plate issuing state</label>
                                    <select
                                        onChange={handleInputChangeAutomatic}
                                        name="license_plate_issuing"

                                        required
                                        className='focus:outline-none border-[1px] bg-gray-100 p-[8px] rounded-lg text-gray-400'
                                    >
                                        <option value="" selected>Enter your license plate issuing</option>
                                        <option value="junior">Junior</option>
                                        <option value="middle">Middle</option>
                                        <option value="senior">Senior</option>
                                    </select>

                                </div>

                                <div className="form-group">
                                    <label >Fuel type</label>
                                    <select
                                        onChange={handleInputChangeAutomatic}
                                        name="fuel_type"

                                        required
                                        className='focus:outline-none border-[1px] bg-gray-100 rounded-lg text-gray-400'
                                    >
                                        <option value="" selected>Fuel type</option>
                                        <option value="diesel">Diesel</option>
                                        <option value="oil">oil</option>
                                        <option value="gass">gass</option>
                                    </select>

                                </div>

                            </div>

                            <div className='w-[33.3%]'>
                                <div className="form-group">
                                    <label>Driver</label>
                                    <select
                                        onChange={handleInputChangeAutomatic}
                                        name="driver"

                                        required
                                        className='focus:outline-none border-[1px] bg-gray-100 p-2 rounded-lg text-gray-400'
                                    >
                                        <option value="" selected>driver</option>
                                        <option value="mykle">Mykle</option>
                                        <option value="alisher">Alisher</option>
                                        <option value="ruslan">Ruslan</option>
                                    </select>

                                </div>
                                <div className="form-group">
                                    <label>Eld device</label>
                                    <select
                                        onChange={handleInputChangeAutomatic}
                                        name="eld_device"

                                        required
                                        className='focus:outline-none border-[1px] bg-gray-100 p-2 rounded-lg text-gray-400'
                                    >
                                        <option value="" selected>Device</option>
                                        <option value="tabble">Tabble</option>
                                        <option value="phone">Phone</option>
                                        <option value="laptob">Laptob</option>
                                    </select>

                                </div>

                                <div className="form-group">
                                    <label >Notes</label>
                                    <input
                                        type="text"
                                        name="notes"

                                        onChange={handleInputChangeAutomatic}

                                        placeholder='Enter your notes'
                                        maxLength={100}
                                        className='focus:outline-none border-[1px] bg-gray-100 placeholder:text-gray-400'
                                        required
                                    />
                                </div>
                            </div>

                        </div>


                    </form>

            }


        </div>
    )
}

export default CreateVehicle;