import { useEffect } from "react";

const REFRESH_TOKEN_INTERVAL = 3300000; // 1 soat = 3600 * 1000 ms

function useTokenRefresher() {
  useEffect(() => {
    const refreshToken = async () => {
      const storedRefreshToken = localStorage.getItem("refreshToken");

      if (!storedRefreshToken) {
        console.error("Refresh token topilmadi!");
        return;
      }

      try {
        const response = await fetch("https://xertzdigital.net:8001/api/v1/accounts/api/token/refresh/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh: storedRefreshToken }),
        });

        if (response.ok) {
          const data = await response.json();
          
          localStorage.setItem("accessToken", data.access);
           // Yangilangan tokenni saqlash
          console.log("Access token muvaffaqiyatli yangilandi.");
        } else {
          console.error("Access tokenni yangilashda xatolik yuz berdi.");
        }
        if (response.status === 401) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            window.location.href = "/"; // Login sahifasiga yo'naltirish
          }
      } catch (error) {
        console.error("Tokenni yangilash so'rovida xatolik:", error);
      }
    };

    // Funksiyani darhol ishga tushirish
    refreshToken();

    // Har 1 soatda tokenni yangilash uchun interval o'rnatish
    const intervalId = setInterval(refreshToken, REFRESH_TOKEN_INTERVAL);

    // Komponent unmounted bo'lsa, intervalni tozalash
    return () => clearInterval(intervalId);
  }, []);
}

export default useTokenRefresher;
