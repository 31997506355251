import React,{useState,useEffect} from "react";


const CopyButton = ({text,copy}) => {
    const [copied, setCopied] = useState(false);
  
   
    

    useEffect(() => {
      if (copied) {
        const timer = setTimeout(() => {
          setCopied(false);
        }, 2000);
        return () => clearTimeout(timer);
      }
    }, [copied]);
  
    const handleCopy = () => {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
      });
    };

    console.log("disablet: ", text)
  
    return (
      <div className={` w-full max-w-[16rem] ${copy ? '': 'isB-disabled'}`}>
        <div className="relative">             
      
          <button
            onClick={handleCopy}
            className=" text-gray-500 dark:text-gray-400  rounded-lg p-2 inline-flex items-center justify-center"
          >
            {!copied ? (
              <span id="default-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                <path d="M12.5 11.2001V12.7001C12.5 14.7001 11.7 15.5001 9.7 15.5001H7.8C5.8 15.5001 5 14.7001 5 12.7001V10.8001C5 8.80006 5.8 8.00006 7.8 8.00006H9.3" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.4998 11.2001H10.8998C9.6998 11.2001 9.2998 10.8001 9.2998 9.60006V8.00006L12.4998 11.2001Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.7998 5.50006H11.7998" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.5 7.00006C7.5 6.17006 8.17 5.50006 9 5.50006H10.31" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.9997 8.50006V11.5951C14.9997 12.3701 14.3697 13.0001 13.5947 13.0001" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15 8.50006H13.5C12.375 8.50006 12 8.12506 12 7.00006V5.50006L15 8.50006Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
              </span>
            ) : (
              <span id="success-icon">
                <svg
                  className="w-3.5 h-3.5 text-blue-700 dark:text-blue-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 12"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5.917 5.724 10.5 15 1.5"
                  />
                </svg>
              </span>
            )}
          </button>
          <div
            id="tooltip-copy-npm-install-copy-button"
            role="tooltip"
            className={`absolute ${text.active ? "left-[-18px]" : "right-[-18px]"} bottom-10  z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 ${copied ? 'opacity-100' : 'opacity-0 invisible'}`}
          >
            <span id="default-tooltip-message" className={!copied ? '' : 'hidden'}>
              Copy to clipboard
            </span>
            <span id="success-tooltip-message" className={copied ? '' : 'hidden'}>
              Copied!
            </span>
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
        </div>
      </div>
    );
  };
  export default CopyButton;