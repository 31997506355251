import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import './dashboardregister.css'
import SubscriptionPlan from "./SubscriptionPlan";
import BillingHistory from "./BillingHistory";
import { UserContext } from "../../../App";
import { LoaderContext } from "../../../LoaderProvider";
import CircularProgress from '@mui/material/CircularProgress';

const RegistrationForm = () => {

    const [data, setData] = useState([]);
    const [activeRegister,setActiveRegister] = useState('companydetails')
    const { isLoading, setIsLoading } = useContext(LoaderContext);
    const [activePage,setActivePage] = useState("companydetails")
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const accessToken = localStorage.getItem('accessToken');
    const companyId = localStorage.getItem("companyId")
    useEffect(() => {
        
        const fetchData = async () => {
        setIsLoading(true)
          try {
            const response = await axios.get('https://xertzdigital.net:8001/api/v1/accounts/user/companies-list/', {
              headers: {
                Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
              },
            });
            
                     
            
            setData(response.data.filter(item => item.id == companyId));
          
          } catch (err) {
            setError(err.message || 'Xato yuz berdi!');
          } finally {
            setIsLoading(false)
           
          }
        };
    
        if (accessToken) {
          fetchData();
        } else {
          setError('AccessToken topilmadi!');
          setLoading(false);
        }
      }, [accessToken]);


    const formChanger = (click) =>{
       setActiveRegister(click)
       setActivePage(click)
    }
   
    console.log("data: ",data);
    

    const user = useContext(UserContext)

    return (
        <div className=" pl-2 pr-2" style={{background:user.night ? '#fff' : "#1E2D4C",color:user.night ? '' : "#fff",transition:'all 0.3s ease'}}>


            <main className="main-content" >
       

                <div className=" w-full p-4 bg-white rounded-lg" style={{background:user.night ? '#fff' : "#1E2D4C",color:user.night ? '' : "#fff",transition:'all 0.3s ease'}}>

                    <h2 className="text-2xl font-bold" style={{ color: "#1996F0" }}>Company</h2>
                    <div className="flex items-center gap-3 border-[1px]-b-2 pb-2">
                        <p className={`font-semibold cursor-pointer ${activePage == 'companydetails' ? 'text-blue-500': ''}`} onClick={ () =>formChanger('companydetails')}>Company details</p>
                        <p className={`font-semibold cursor-pointer  ${activePage == 'subscriptionplan' ? 'text-blue-500': ''}`} onClick={() =>formChanger('subscriptionplan')}>Subscription plan</p>
                        <p className={`font-semibold cursor-pointer ${activePage == 'billinghistory' ? 'text-blue-500': ''}`} onClick={ () =>formChanger('billinghistory')}>Billing history</p>
                        <p className={`font-semibold cursor-pointer ${activePage == 'apitoken' ? 'text-blue-500': ''}`} onClick={ () =>formChanger('apitoken')}>API & Token</p>
                    </div>

                    <div>
                                                        {isLoading && (
                                                            <div style={
                                                                { 
                                                                width:"100%",
                                                                height:"100vh",
                                                                display: 'flex', 
                                                                alignItems:"center",
                                                                justifyContent: 'center', 
                                                                position:"absolute",
                                                                top:"0px",
                                                                bottom:"0px",
                                                                left:"100px",
                                                                right:"0px",
                                                                }}>
                                                                <CircularProgress />
                                                            </div>
                                                        )}
                                                        
                                    </div>                

                {
                    activeRegister === 'companydetails'
                    ?


        
                    <div className="flex items-start justify-between gap-3">

                    {
                        data.map((item) =>(
                            <>
                            
                        <div className="w-1/3">
                            <label className="w-3/6 font-semibold">
                                Company name: <br />
                                <input type="text"  name="companyName"  className=" w-full border-[1px] p-2 mb-2 border-gray-300 text-gray-400 rounded-lg mt-2" value={item.name} disabled/>
                            </label>

                            <label className="w-3/6 font-semibold">
                                Company address: <br />
                                <input type="text"  name="companyAddress" className=" w-full border mb-2 border-gray-300 text-gray-400 p-2 rounded-lg mt-2" value={item.address} disabled/>
                            </label>

                            <label className="w-3/6 font-semibold">
                                Company timezone: 
                                <select name="timezone"  className=" w-full border-[1px] border-gray-300 p-2 mb-2 rounded-lg mt-2 text-gray-400" disabled>
                                    <option value="" selected>{item.timezone}</option>
                                   
                                    {/* Qo'shimcha vaqt zonalarini qo'shing */}
                                </select>
                            </label>

                        </div>


                        <div className="w-1/3">
                            <label className="w-3/6 font-semibold">
                                Email: <br />
                                <input type="email"  name="email"  className=" w-full border mt-2 mb-2 border-gray-300 text-gray-400 p-2 rounded-lg" value={item.email} disabled/>
                            </label>

                            <label className="w-3/6 font-semibold">
                                Terminal address: <br />
                                <input type="text"  name="terminalAddress"  className=" w-full mt-2 mb-2 border border-gray-300 text-gray-400 p-2 rounded-lg" value={item.terminal_address} disabled/>
                            </label>

                        </div>


                        <div className="w-1/3">
                            <label className="w-3/6 font-semibold">
                                Phone number: <br />
                                <input type="text"  name="phoneNumber"  className=" w-full border-[1px] mb-2 border-gray-300 text-gray-400 p-2 mt-2 rounded-lg" value={item.phone} disabled/>
                            </label>
                            <label className="w-3/6 font-semibold">
                                USDOT: <br />
                                <input type="text"  name="usdot"   className=" w-full border-[1px] border-gray-300 text-gray-400 p-2 rounded-lg mt-2 mb-2" value={item.weekly_limit} disabled/>
                            </label>
                            
                        </div></>
                        ))
                    }

                    </div>
              


                :
                activeRegister === 'subscriptionplan'
                ?
                <SubscriptionPlan/>
                :
                activeRegister === 'billinghistory'
                ?
                <BillingHistory/>
                :
                <></>
                }

                

                </div>

                

            </main>

        </div>
    );
};

export default RegistrationForm;
