import React from 'react'
import CreateVehicle from './CreateVehicle'

function CreateVehicleLayout() {
  return (
    <div className='pl-2 pr-2'>

       

        <main className="main-content">
           
            <CreateVehicle/>
        </main>

    </div>
  )
}

export default CreateVehicleLayout