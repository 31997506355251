import React, { useContext, useEffect, useState } from 'react';
import DashboardMap from '../DashboardMap/DashboardMap';
import CloseIcon from '../../Assets/DashboardMap/close.png'
import RefreshIcon from '../../Assets/DashboardMap/refresher.png'
import FrameGreen from '../../Assets/Sidebar/Frame-green.png'
import FrameGray from '../../Assets/Sidebar/Frame-gray.png'
import axios from 'axios';
import { UserContext } from '../../../App';
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContext } from '../../../LoaderProvider';
import CopyButton from '../Logs/CopyButton';




const DashboardMapLayout = () => {

  const [linkActive, setLinkActive] = useState('drivers');
  const [selectLocation, setSelectLocation] = useState([]);
  const [searchDriverArr, setSearchDriverArr] = useState('');
  const [checkedType, setCheckedType] = useState('')
  const [closeBar, setCloseBar] = useState(true)
  const [typeId, setTypeId] = useState('roadmap') // Hozirgi sahifa
  const [data, setData] = useState([]); // Hozirgi ko'rsatilgan ma'lumotlar
  const [page, setPage] = useState(1); // Sahifa 1-dan boshlanadi
  const [totalCount, setTotalCount] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  // const getCardData = async () => {
  //   await axios.get('https://xertzdigital.net/geolocation.json').then(res => {
  //     setLocationCard(res.data)

  //   })

  //   setIsloaded(true)
  // }


  const accessToken = localStorage.getItem('accessToken');
  const companyId = localStorage.getItem("companyId");

  const fetchData = async () => {
    setIsLoading(true)
    try {
      // const params = {};
      // if (checkedType.length > 0) {
      //   params.last_activity_status = checkedType.join(","); // To'g'ri formatda yuborish
      // }
      // if (searchDriverArr.trim() !== "") {
      //   params.full_name = searchDriverArr;
      // }

      const response = await axios.get(
        `https://xertzdigital.net:8001/api/v1/main/dashboard/driver_list/${companyId}?last_activity_status=${checkedType}&full_name=${searchDriverArr}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
          },
          // params: params, // Parametrlarni birgalikda yuborish
        }
      );


      console.log("layout: ", response);
      setData(response.data.results);
      setTotalCount(response.data.results.count)
    } catch (err) {
      console.log(err.message || "Xato yuz berdi!");
    } finally {
      setIsLoading(false)
    }
  };
  useEffect(() => {
    fetchData();

  }, [checkedType, searchDriverArr, accessToken])


  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100 &&
        !Loading &&
        data.length < totalCount
      ) {
        setPage((prevPage) => Math.max(prevPage + 1, 1)); // Sahifani 1 dan kam bo'lmasligiga ishonch hosil qilish
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, data, totalCount]);

  useEffect(() => {
    setData([]);
    setPage(1); // Birinchi sahifaga qaytarish
    setHasMore(true); // Qo'shimcha ma'lumotlarni yuklashga ruxsat berish
    fetchData();
  }, [checkedType, searchDriverArr]);



  const selectedType = (e) => {
    const value = e.target.value;
    setCheckedType((prevFilters) =>
      prevFilters.includes(value)
        ? prevFilters.filter((item) => item !== value)
        : [...prevFilters, value]
    );
    
  };
  




  const Drivers = async (click) => {
    setIsLoading(true)
    try {
      await axios.get(`https://xertzdigital.net:8001/api/v1/accounts/drivers/status-locations/${companyId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
        }
      }).then(res => {
        setSelectLocation(res.data);
        console.log("Drivers api", res);


      })
      setLinkActive('drivers');
    } catch (e) {
      console.log(e);

    } finally {
      setIsLoading(false)
    }
  }

  const Vehicles = async (active) => {
    setIsLoading(true)
    try {
      await axios.get(`https://xertzdigital.net:8001/api/v1/main/dashboard/trailer_list/${companyId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
        }
      }).then(res => {
        setSelectLocation(res.data);


      })

      setLinkActive(active);
    } catch (e) {
      console.log(e);

    } finally {
      setIsLoading(false)
    }

  }

  const WeightSation = async (active) => {
    setIsLoading(true)
    try {
      await axios.get('https://xertzdigital.net/weightstation.json').then(res => {

        setSelectLocation(res.data)
      })
      setLinkActive(active);
    } catch (e) {
      console.log(e);

    } finally {
      setIsLoading(false)
    }
  }





  const changeMap = () => {
    setTypeId(typeId == 'roadmap' ? "hybrid" : "roadmap")
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)

  };

  const user = useContext(UserContext);
  

  return (
    <div className="" style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }} onLoad={Drivers}>

      <main className="main-content">

        <div className='main-content'>
          <img
            src={CloseIcon}
            alt="closeicon"
            onClick={() => setCloseBar(!closeBar)}
            style={{
              position: "absolute",
              right: "100px",
              top: "110px",
              zIndex: '10',
              display: closeBar ? 'none' : 'block',

            }}
          />
          <div className='flex justify-between'>

            <div>
              {isLoading && (
                <div style={
                  {
                    width: "100%",
                    height: "100vh",
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: 'center',
                    position: "absolute",
                    top: "0px",
                    bottom: "0px",
                    left: "100px",
                    right: "0px",
                    zIndex: '10'
                  }}>
                  <CircularProgress />
                </div>
              )}

            </div>

            <DashboardMap typeId={typeId} vehicles={selectLocation} drivers={selectLocation} weightstation={selectLocation} activeLink={linkActive} />


            <div className={` ${closeBar ? 'openData' : 'closeData'}`} style={{ height: "90vh", maxHeight: "85vh", overflowY: 'scroll', background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff",}}>

              

              <div className='flex items-center justify-between'>
                <img
                  src={CloseIcon}
                  alt="closeicon"
                  onClick={() => setCloseBar(!closeBar)}

                />
                <div className='flex items-center gap-2'>
                  <button className='p-1 pl-4 pr-4 rounded-lg font-semibold' style={{ background: "#E8F3FF", color: "#1996F0" }} onClick={() => changeMap()} >{typeId == "roadmap" ? "Satellite" : "roadMap"}</button>
                  <button onClick={fetchData}>
                    <img src={RefreshIcon} alt="" className='pointer' />
                  </button>
                </div>
              </div>


              <ul className='flex items-center justify-between mt-3 mb-3 text-gray-400 cursor-pointer '>
                <li onClick={() => { Drivers('drivers') }} className={`${linkActive === "drivers" ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}>Drivers</li>
                <li onClick={() => Vehicles('vehicles')} className={`${linkActive === "vehicles" ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}>Trailer</li>
                <li onClick={() => WeightSation('weightstation')} className={`${linkActive === "weightstation" ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}>Weight Sation</li>
              </ul>



              <div>

                <div className='w-full p-2 flex items-center justify-between rounded-lg gap-2' style={{ border: "2px solid #E6E6E6" }}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.58341 17.5001C13.9557 17.5001 17.5001 13.9557 17.5001 9.58348C17.5001 5.21122 13.9557 1.66681 9.58341 1.66681C5.21116 1.66681 1.66675 5.21122 1.66675 9.58348C1.66675 13.9557 5.21116 17.5001 9.58341 17.5001Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.3334 18.3335L16.6667 16.6668" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <input
                    type="text"
                    maxLength={100}
                    placeholder='Search driver'
                    className='w-full focus:outline-none bg-transparent'
                    style={{ color: "#808080" }}
                    onChange={(e) => setSearchDriverArr(e.target.value)}
                    value={searchDriverArr}
                  />
                </div>

                <form>
                  <div className='w-full flex items-center gap-2 mt-5'>


                    <div className='p-1 pl-2 pr-2 flex gap-1 rounded-[4px] ' style={{ background: "#F8F8FA" }}>
                      <input type="checkbox" onClick={selectedType} value='' style={{ width: "10px" }} />
                      <span className='text-[13px] font-semibold text-gray-500'>All</span>
                    </div>

                    <div className='p-1  flex gap-2 rounded-[4px]' style={{ background: "#E2F0ED" }}>
                      <input type="checkbox" onChange={selectedType} value='dr' style={{ width: "10px" }} />
                      <span className='text-[13px] font-semibold text-green-600'>DR</span>
                    </div>

                    <div className='p-1 pl-2 pr-2 flex gap-2 rounded-[4px]' style={{ background: "#E8F3FF" }}>
                      <input type="checkbox" onClick={selectedType} value='on' style={{ width: "10px" }} />
                      <span className='text-[13px] font-semibold text-blue-600'>ON</span>
                    </div>

                    <div className='p-1 pl-2 pr-2 flex gap-2 rounded-[4px]' style={{ background: "#FFEECE" }}>
                      <input type="checkbox" onClick={selectedType} value='sb' style={{ width: "10px" }} />
                      <span className='text-[13px] font-semibold text-yellow-600'>SB</span>
                    </div>

                    <div className='p-1 pl-2 pr-2 flex gap-2 rounded-[4px]' style={{ background: "#FFE2E3" }}>
                      <input type="checkbox" onClick={selectedType} value='off' style={{ width: "10px" }} />
                      <span className='text-[13px] font-semibold text-red-600'>OFF</span>
                    </div>


                  </div>
                </form>

                {
                  data.map((item) => {                  

                    return (
                      <div key={item.id}
                        className={`flex items-start mt-4 pb-2 gap-3 border-b-2`}>

                        <div>
                          {item.last_activity != null ? (item.last_activity.activity == 'on' ? (<img src={FrameGreen} />) : item.last_activity.activity == 'dr' ? (<img src={FrameGreen} />) : item.last_activity.activity == 'intermidiate' ? (<img src={FrameGreen} />) : (<img src={FrameGray} />)) : <img src={FrameGray} />}
                        </div>
                        <div className='w-full'>
                          <div className=' w-full flex items-center justify-between'>
                            <p className='text-[14px] font-[600]'>{item.first_name} {item.last_name}</p>
                            <div className='flex items-center justify-between gap-2'>

                              <button  className={`${item.last_activity != null ?  item.last_activity.latitude == null ? 'isB-disabled' : '' : 'isB-disabled'}`}>
                              <a target='_blank'  href={`https://www.google.com/maps/@${item.last_activity != null ? item.last_activity.latitude : ''},${item.last_activity != null ? item.last_activity.longitude : ''},3240m/data=!3m1!1e3?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D`}>
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect y="0.00012207" width="19" height="19" rx="3.8" fill="#E8F3FF" />
                                  <path d="M10.5 9.50015L14.6 5.40015" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M15.0001 7.40012V5.00012H12.6001" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M9.5 5.00012H8.5C6 5.00012 5 6.00012 5 8.50012V11.5001C5 14.0001 6 15.0001 8.5 15.0001H11.5C14 15.0001 15 14.0001 15 11.5001V10.5001" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </a>
                              </button>

                              <CopyButton text={ item.last_activity != null ? `${item.last_activity.latitude} ${item.last_activity.longitude}` : 'not avalible'} copy={item.last_activity != null ? true : false} />

                  
                            

                              <button className={` pr-2 pl-2 rounded-lg text-white ${item.last_activity != null ? (item.last_activity.activity == 'dr' ? 'bg-green-500' : item.last_activity.activity == 'sb' ? 'bg-yellow-400' : item.last_activity.activity == 'on' ? 'bg-blue-300' : 'bg-red-600') : 'bg-red-600 text-[8px] isB-disabled'}`}>
                                {item.last_activity != null ? item.last_activity.activity : "not active"}
                              </button>

                            </div>
                          </div>
                          <div className='text-[12px] text-gray-500'>ID:<span className='text-[14px] font-medium'> {item.id}</span> | <span className={`${item.last_activity != null ? (item.last_activity.activity === 'off' ? 'text-black' : 'text-green-400') : "text-red-600"}`}>{item.last_activity != null ? item.last_activity.activity : "not available"}</span></div>
                          <div className='text-[12px] flex items-center gap'>
                            <svg width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.5 12.1876C10.0888 12.1876 12.1875 10.089 12.1875 7.50012C12.1875 4.91129 10.0888 2.81262 7.5 2.81262C4.91117 2.81262 2.8125 4.91129 2.8125 7.50012C2.8125 10.089 4.91117 12.1876 7.5 12.1876Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.5 9.37512C8.53553 9.37512 9.375 8.53566 9.375 7.50012C9.375 6.46459 8.53553 5.62512 7.5 5.62512C6.46447 5.62512 5.625 6.46459 5.625 7.50012C5.625 8.53566 6.46447 9.37512 7.5 9.37512Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.5 2.50012V1.25012" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M2.5 7.50012H1.25" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M7.5 12.5001V13.7501" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M12.5 7.50012H13.75" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                            </svg><span className='text-[12px] font-normal'>{item.last_activity != null ? item.last_activity.location : "not available"}</span>
                          </div>

                          <div className=' text-[12px] flex items-center gap-2'>
                            <div className='flex items-center gap-1'>
                            <svg width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.1875 13.7501V3.12512C2.1875 1.87512 3.025 1.25012 4.0625 1.25012H9.0625C10.1 1.25012 10.9375 1.87512 10.9375 3.12512V13.7501H2.1875Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M1.25 13.7501H11.875" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M5.24375 6.25014H7.8875C8.5375 6.25014 9.06875 5.93764 9.06875 5.06889V4.30015C9.06875 3.4314 8.5375 3.1189 7.8875 3.1189H5.24375C4.59375 3.1189 4.0625 3.4314 4.0625 4.30015V5.06889C4.0625 5.93764 4.59375 6.25014 5.24375 6.25014Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M4.0625 8.12512H5.9375" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.9375 10.0064L13.75 10.0001V6.25012L12.5 5.62512" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span className='text-gray-500'>Fuel: </span> <span className='text-[12px] font-normal'>{item.last_activity != null ? item.last_activity.fuel_percent : "0%"}</span> <b> | </b>  
                            </div>
                            
                            <div className='text-[12px] flex items-center gap-1'><span className='text-gray-500'>Last connection: </span> <span >{item.last_activity != null ? item.last_activity.start_time : "not available"}</span></div>
                          </div>

                        </div>
                      </div>
                    )
                  })
                }

              </div>

            </div>

          </div>
        </div>

      </main >
    </div >
  );
};

export default DashboardMapLayout;
