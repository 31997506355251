import React from 'react'
import Reports from './Reports'

function ReportsLayout() {
  return (
    <div className='pl-2 pr-2'>

        <main className='main-content'>
           
            <Reports/>
        </main>

    </div>
  )
}

export default ReportsLayout