import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./profile.css";
import { UserContext } from "../../../App";
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContext } from '../../../LoaderProvider';







const ProfileEdit = () => {

    const [hide, setHide] = useState('profile');
    const [checkPassword, setCheckPassword] = useState(true);
    const [changePassword, setChangePassword] = useState({
        password: "",

    });
    const [editProfile, setEditProfile] = useState({
        id: 0,
        first_name: "",
        last_name: "",
        phone: "",
    });
    const [newPassword, setNewPassword] = useState({
        password: ""
    })
    const [succes, setSucces] = useState(false)
    const { isLoading, setIsLoading } = useContext(LoaderContext);
    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                const response = await axios.get('https://xertzdigital.net:8001/api/v1/accounts/user/info/', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                setEditProfile(response.data);

            } catch (err) {
                console.log("navbar", err);

            } finally {
                setIsLoading(false)
            }
        };

        if (accessToken) {
            fetchData();
        }
    }, [accessToken]);





    const handleEditClick = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const response = await axios.patch(`https://xertzdigital.net:8001/api/v1/accounts/user/change-profile/${editProfile.id}/`, editProfile);
            console.log("Data saved:", response);


        } catch (error) {
            console.error("Error saving data:", error);
        }finally{
            setIsLoading(false)
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };


    const handleEditPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        try {

            const response = await axios.post(
                "https://xertzdigital.net:8001/api/v1/accounts/company/check_password/",
                changePassword, // payload to be sent to the API
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            console.log("Old Password:", response.data);

            if (response.data.success) {

                setSucces(true)

                setCheckPassword(true)
                setPassword()

            } else {
                setSucces(false);
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }finally{
            setIsLoading(false)
        }





    };

    const handleInputChangePassword = (e) => {
        const { name, value } = e.target;
        setChangePassword((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
        console.log("change password", changePassword);

    };

    const setPassword = async () => {
        setIsLoading(true)
        try {
            const response = await axios.post("https://xertzdigital.net:8001/api/v1/accounts/company/set_password/",
                newPassword,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                }
            );
            console.log("Data saved:", response.data);




        } catch (error) {
            console.error("Error saving data:", error);
        }finally{
            setIsLoading(false)
        }

    }

    const handleInputNewPassword = (e) => {
        const { name, value } = e.target;
        setNewPassword((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
        console.log("new password", newPassword);

    };






    const user = useContext(UserContext)

    return (
        <div className=" pl-2 pr-2" >


            <main className="main-content">

                {/* /////////////////////////////////////////////// */}

                <div className="profile-edit" style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>

                    <div>
                        {isLoading && (
                            <div style={
                                {
                                    width: "100%",
                                    height: "100vh",
                                    display: 'flex',
                                    alignItems: "center",
                                    justifyContent: 'center',
                                    position: "absolute",
                                    top: "0px",
                                    bottom: "0px",
                                    left: "100px",
                                    right: "0px",
                                }}>
                                <CircularProgress />
                            </div>
                        )}

                    </div>

                    {
                        hide == 'profile' ?
                            <form onSubmit={handleEditClick}>
                                <div className="flex items-center justify-between">
                                    <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Profile</h2>
                                    <button className="edit-button" type="submit">
                                        Edit
                                    </button>
                                </div>

                                <div className="tabs flex items-center justify-between border-b-2 ">
                                    <div className="flex gap-4">
                                        <span className={`tab ${hide == 'profile' ? 'text-blue-500' : ''}`} onClick={() => setHide('profile')} style={{ color: user.night ? '' : "white" }}>Edit profile</span>
                                        <span className={`tab ${hide == 'password' ? 'text-blue-500' : ''}`} onClick={() => setHide('password')} style={{ color: user.night ? '' : "white" }}>Change password</span>
                                    </div>
                                </div>
                                <div className="profile-form">
                                    <div className="form-group">
                                        <label>First name</label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            className="text-black"
                                            value={editProfile.first_name}
                                            onChange={handleInputChange}

                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Second name</label>
                                        <input
                                            type="text"
                                            name="last_name"
                                             className="text-black"
                                            onChange={handleInputChange}
                                            value={editProfile.last_name}

                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Phone number</label>
                                        <input
                                            type="text"
                                            name="phone"
                                             className="text-black"
                                            onChange={handleInputChange}
                                            value={editProfile.phone}

                                        />
                                    </div>
                                </div>
                            </form>
                            :
                            <form onSubmit={handleEditPassword}>
                                <div className="flex items-center justify-between">
                                    <h2 className="text-3xl font-bold" style={{ color: "#1996F0" }}>Profile</h2>
                                    <div className="flex items-center gap-3">
                                        <button className="edit-button" type="submit">
                                            Edit
                                        </button>
                                    </div>
                                </div>

                                <div className="tabs flex items-center justify-between border-b-2">
                                    <div className="flex gap-4">
                                        <span className={`tab ${hide == 'profile' ? 'text-blue-500' : ''}`} onClick={() => setHide('profile')} style={{ color: user.night ? '' : "white" }}>Edit profile</span>
                                        <span className={`tab ${hide == 'password' ? 'text-blue-500' : ''}`} onClick={() => setHide('password')} style={{ color: user.night ? '' : "white" }}>Change password</span>
                                    </div>
                                </div>

                                <div className="profile-form">

                                    <div className="profil">
                                        <label>Old password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={changePassword.password}
                                            onChange={handleInputChangePassword}
                                             className="text-black"

                                        />
                                    </div>

                                    {
                                        succes ?
                                            <>

                                                <div className="form-group">
                                                    <label>New Password</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        onChange={handleInputNewPassword}
                                                         className="text-black"

                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        onChange={handleInputNewPassword}
                                                         className="text-black"

                                                    />
                                                    <span className={`text-xs text-red-600 ${checkPassword ? 'hidden' : 'block'}`}>check again</span>
                                                </div>
                                            </>

                                            : <></>
                                    }


                                </div>
                            </form>
                    }

                </div>


            </main>
        </div>

    );
};

export default ProfileEdit;
