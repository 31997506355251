import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProfileEdit from '../Profile/Profile';
import LogsLayout from '../Logs/LogsLayout';
import DriverDoc from '../DriverDog/DriverDoc';
import TrackingLayout from '../Tracking/TrackingLayout';
import TrackingMap from '../TrackingMap/TrackingMap';
import DriverLayout from '../Driver/DriverLayout';
import AddDriver from '../Driver/AddDriver';
import EditDriver from '../Driver/editDriver';
import VehiclesLayout from '../Vehicles/VehiclesLayout';
import EditVehicle from '../Vehicles/editVehicle';
import CreateVehicleLayout from '../Vehicles/CreateVehicleLayout';
import ResourcesLayout from '../../Resources/ResourcesLayout';
import ReportsLayout from '../Reports/ReportsLayout';
import Telematics from '../Telematics/Telematics';
import Diagnostics from '../Telematics/Diagnostics';
import Inspaction from '../Inspaction/Inspaction';
import TripPannel from '../TripPannel/TripPsnnel';
import AdminCompanies from '../../Registration/AdminCompanies/AdminCompanies';
import DashboardMapLayout from '../DashboardLayout/DashboardLoyaut';
import RegistrationForm from '../DashboardCreateAcc/DashboardNewAcc';













const LayoutRoutes = () => {
  return (
    <Routes>
     
      <Route path="/" element={<DashboardMapLayout />} />  

       {/* <Route path='forgotpassword' element={<ForgotPassword />} />
        <Route path='signupverification' element={<SignUpVerification />} />
        <Route path='passwordset' element={<PasswordSet />} />
        <Route path='emailverification' element={<EmailVerification />} />
        <Route path='passwordreset' element={<PasswordReset />} />      */}
    
        <Route path='registrationform' element={<RegistrationForm />} />
        <Route path='profile' element={<ProfileEdit/>} />
        <Route path='logs' element={<LogsLayout/>} />
        <Route path='logs/:id' element={<DriverDoc/>} />
        <Route path='tracking' element={<TrackingLayout/>} />
        <Route path='tracking/:id' element={<TrackingMap/>} />
        <Route path='driver' element={<DriverLayout/>} />
        <Route path='adddriver' element={<AddDriver/>} />
        <Route path='driver/:id' element={<EditDriver/>} />
        <Route path='vehicles' element={<VehiclesLayout/>} />
        <Route path='vehicles/:id' element={<EditVehicle/>} />
        <Route path='createvehicle' element={<CreateVehicleLayout/>} />
        <Route path='resources' element={<ResourcesLayout/>} />
        <Route path='reports' element={<ReportsLayout/>} />
        <Route path='telematics' element={<Telematics/>} />
        <Route path='telematics/:id' element={<Diagnostics/>} />
        <Route path='logs/:id/telematics' element={<Diagnostics/>} />
        <Route path='inspaction' element={<Inspaction/>} />
        <Route path='logs/:id/trippanel' element={<TripPannel/>} />
        <Route path='admincompanies' element={<AdminCompanies/>} />
      
    </Routes>
  );
};

export default LayoutRoutes;
