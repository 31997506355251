import React,{useCallback, useState} from 'react'
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api'
import MarkerIcon from '../../Assets/signin/direct-right.png'
import FrameOn from '../../Assets/DashboardMap/FrameOn.png'
import FrameOff from '../../Assets/DashboardMap/FrameOff.png'
import WeightStation from '../../Assets/DashboardMap/Weight station.png'


const containerStyle = {
  width: '100%',
  height: '85vh',
}

const center = {
  lat: 44.500000,
  lng: -89.500000,
};

function DashboardMap({ typeId, vehicles, drivers, weightstation, activeLink }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAUS-bGaDXizD-0fPlyMyki5rPPT-rGUD4",
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    // Optionally, you can adjust the center and zoom here:
    mapInstance.setCenter(center);
    mapInstance.setZoom(6);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const options = {
    mapTypeId: typeId, // Satellite mode
    zoomControl: true, // Enable zoom controls
  };



  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6} // Explicit zoom level
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
    >
      <MarkerClusterer
        options={{
          gridSize: 60,
          maxZoom: 15,
          averageCenter: true,
          enableRetinaIcons: true,
        }}
      >
        {(clusterer) =>
          activeLink === "drivers"
            ? drivers.map((item) => {
              if (!item.latitude || !item.longitude) return null; // Agar lat yoki lng noto'g'ri bo'lsa, Marker yaratma
           
              
              return (
                <Marker
                  key={item.id}
                  position={{ lat: +item.latitude, lng: +item.longitude }}
                  options={{
                    icon: {
                      url: MarkerIcon,
                      scaledSize: new window.google.maps.Size(40, 40),
                    },
                  }}
                  clusterer={clusterer}
                />
              );
            })
            
            : activeLink === "vehicles"
            ? vehicles.map((item) => {
              if (!item.latitude || !item.longitude) return null; // Agar lat yoki lng noto'g'ri bo'lsa, Marker yaratma
              return (
                <Marker
                  key={item.id}
                  position={{ lat: +item.latitude, lng: +item.longitude }}
                  options={{
                    icon: {
                      url: MarkerIcon,
                      scaledSize: new window.google.maps.Size(40, 40),
                    },
                  }}
                  clusterer={clusterer}
                />
              );
            })
            

            : activeLink === "weightstation"
            ? weightstation.map((item) => {
              if (!item.latitude || !item.longitude) return null; // Agar lat yoki lng noto'g'ri bo'lsa, Marker yaratma
              return (
                <Marker
                  key={item.id}
                  position={{ lat: +item.latitude, lng: +item.longitude }}
                  options={{
                    icon: {
                      url: MarkerIcon,
                      scaledSize: new window.google.maps.Size(40, 40),
                    },
                  }}
                  clusterer={clusterer}
                />
              );
            })
            
            : null
        }
      </MarkerClusterer>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(DashboardMap);
