import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './admincompanies.css'
import { LoaderContext } from '../../../LoaderProvider';
import CircularProgress from '@mui/material/CircularProgress';


function AdminCompanies() {

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const { isLoading, setIsLoading } = useContext(LoaderContext);

  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      try {
        const response = await axios.get('https://xertzdigital.net:8001/api/v1/accounts/user/companies-list/', {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
          },
        });


        setData(response.data);

      } catch (err) {
        setError(err.message || 'Xato yuz berdi!');
      } finally {
        setIsLoading(false)
      }
    };

    if (accessToken) {
      fetchData();
    } else {
      setError('AccessToken topilmadi!');

    }
  }, [accessToken]);



  const getCompanyId = (id) => {
    localStorage.setItem('companyId', id);
    navigate('/layout');

  }

  return (
    <div className='flex items-center justify-between'>

      <div className='form_container'>
        <h1 className='text-center text-[50px] font-bold mt-5' style={{ color: "#1996F0" }}>Admin Companies</h1>
        <div>
          {isLoading && (
            <div style={
              {
                width: "100%",
                height: "100vh",
                display: 'flex',
                alignItems: "center",
                justifyContent: 'center',
                position: "absolute",
                top: "0px",
                bottom: "0px",
                left: "100px",
                right: "0px",
              }}>
              <CircularProgress />
            </div>
          )}

        </div>
        <div className='company_list m-auto p-2 flex items-center justify-center flex-col border-1' >
          {
            data.map((item) => {
              return (
                <>
                  <div className="minimal-company-card" onClick={() => getCompanyId(item.id)}>
                    <h2>{item.name}</h2>
                    <ul>
                 
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="company_icon"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M4 4h16v16H4z" />
                          <polyline points="22,6 12,13 2,6" />
                        </svg>
                        {item.email}
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="company_icon"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M12 8v4l3 3" />
                          <circle cx="12" cy="12" r="10" />
                        </svg>
                        {item.timezone}
                      </li>
                    </ul>
                  </div>

                </>
              )
            })
          }
        </div>
      </div>

      <div className="img_container">

      </div>

    </div>
  )
}

export default AdminCompanies;