import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../App';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContext } from '../../../LoaderProvider';
import TableNav from '../TableNav/TableNav';
import CopyButton from './CopyButton';



function LogCarousel() {
    const [logs, setLogs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { isLoading, setIsLoading } = useContext(LoaderContext);
    const [eldStatus, setEldStatus] = useState('');
    const [dutyStatus, setDutyStatus] = useState('');

    let companyId = localStorage.getItem("companyId");
    const accessToken = localStorage.getItem('accessToken')
    const navigate = useNavigate()

    const fetchData = async (page) => {
        setIsLoading(true)
        try {
            const response = await axios.get(`https://xertzdigital.net:8001/api/v1/main/logs/${companyId}/?eld_connection=${eldStatus}&duty_status=${dutyStatus}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            console.log("logs response: ", response);

            setLogs(response.data.results);
            setTotalPages(response.data.total_pages);


        } catch (error) {
            console.error('Xato:', error);
        } finally {
            setIsLoading(false)
        }
    };


    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, eldStatus, dutyStatus]);

    const handleChange = (e, p) => {

        setCurrentPage(p);
    }



    const user = useContext(UserContext);



    function ViolationsCell({ violations }) {
        const MAX_LENGTH = 10; // Maximum characters to display initially
        const violationText = violations.length > 0 ? violations[0] : "Not available"; // Use first element or default message

        const [isExpanded, setIsExpanded] = useState(false);

        const toggleExpand = () => {
            setIsExpanded(!isExpanded);
        };

        // Display truncated text or full text based on the `isExpanded` state
        const displayedText = isExpanded
            ? violationText
            : violationText.slice(0, MAX_LENGTH);

        return (
            <td>

              <Link>

              <div className='w-full h-full pt-3 pb-3 '>
                
              
              {violationText !== "Not available" ? (
                    <div >
                        {displayedText}
                        {violationText.length > MAX_LENGTH && !isExpanded && (
                            <span
                                className="text-blue-500 cursor-pointer"
                                onClick={toggleExpand}
                            >
                                {" "}
                                ...more
                            </span>
                        )}
                        {isExpanded && violationText.length > MAX_LENGTH && (
                            <span
                                className="text-blue-500 cursor-pointer"
                                onClick={toggleExpand}
                            >
                                {" "}
                                Show less
                            </span>
                        )}
                    </div>
                ) : (
                    "Not available"
                )}
                </div>
              </Link>
            </td>
        );
    }



    const LogTable = () => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
         
           const sortedLogs = React.useMemo(() => {
             if (!sortConfig.key) return logs;
         
             return [...logs].sort((a, b) => {
               const aValue = (a[sortConfig.key] || "").toString().charAt(0).toLowerCase();
               const bValue = (b[sortConfig.key] || "").toString().charAt(0).toLowerCase();
         
               if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
               if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
               return 0;
             });
           }, [logs, sortConfig]);
         
           const requestSort = (key) => {
             let direction = "asc";
             if (sortConfig.key === key && sortConfig.direction === "asc") {
               direction = "desc";
             }
             setSortConfig({ key, direction });
           };
         
           const getSortIndicator = (key) => {
             if (sortConfig.key === key) {
               return sortConfig.direction === "asc" ? <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M8.3002 6.27083L5.58353 3.55416C5.2627 3.23333 4.7377 3.23333 4.41686 3.55416L1.7002 6.27083" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
               </svg> : <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1.69981 3.72917L4.41647 6.44584C4.7373 6.76667 5.2623 6.76667 5.58314 6.44584L8.2998 3.72917" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
               </svg>;
             }
             return <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M8.3002 6.27083L5.58353 3.55416C5.2627 3.23333 4.7377 3.23333 4.41686 3.55416L1.7002 6.27083" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
             <path d="M1.69981 13.7292L4.41647 16.4458C4.7373 16.7667 5.2623 16.7667 5.58314 16.4458L8.2998 13.7292" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
             </svg>
             ; // Default indicator for sortable columns
           };
         
    
        return (
            <div className='w-full h-[73vh] border-2 rounded-lg p-4 flex justify-between flex-col'>
                <table className='w-full border-collapse'>
                    <thead className='w-full text-[13px] text-gray-500'>
                        <tr className='border-b-2 pb-3' style={{ color: "#808080" }}>
                            <th className='p-2 text-left'>
                                <button onClick={() => requestSort('status')} className='flex items-center gap-2'>
                                    STATUS {getSortIndicator('status')}
                                </button>
                            </th>
                            <th className='p-2 text-left'>
                                <button onClick={() => requestSort('driver_name')} className='flex items-center gap-2'>
                                    DRIVER {getSortIndicator('driver_name')}
                                </button>
                            </th>
                            <th className='p-2 text-left'>
                                <button onClick={() => requestSort('vehicle_id')} className='flex items-center gap-2'>
                                    VEHICLE ID {getSortIndicator('vehicle_id')}
                                </button>
                            </th>
                            <th className='p-2 text-left'>
                                <div onClick={() => requestSort('last_know_location')} className='flex items-center gap-2'>
                                LAST KNOWN LOCATION {getSortIndicator('last_know_location')}
                                </div>
                            </th>
                            <th className='w-[10%] p-2 text-left'>
                                <button onClick={() => requestSort('violation')} className='flex items-center gap-2'>
                                VIOLATION {getSortIndicator('violation')}
                                </button>
                            </th>
                            <th className='p-2 text-left'>
                                <button onClick={() => requestSort('eld_connection')} className='flex items-center gap-2'>
                                ELD CONNECTION {getSortIndicator('eld_connection')}
                                </button>
                            </th>
                            <th className='p-2 text-left'>
                                <button onClick={() => requestSort('break')} className='flex items-center gap-2'>
                                BREAK {getSortIndicator('break')}
                                </button>
                            </th>
                            <th className='p-2 text-left'>
                                <button onClick={() => requestSort('drive')} className='flex items-center gap-2'>
                                DRIVE {getSortIndicator('drive')}
                                </button>
                            </th>
                            <th className='p-2 text-left'>
                                <button onClick={() => requestSort('shift')} className='flex items-center gap-2'>
                                SHIFT {getSortIndicator('shift')}
                                </button>
                            </th>
                            <th className='p-2 text-left'>
                                <button onClick={() => requestSort('cycle')} className='flex items-center gap-2'>
                                CYCLE {getSortIndicator('cycle')}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-[12px]'>
                        {sortedLogs.map((log, index) => (
                            <tr key={index} className='border-b-[1px] h-[50px] hover:bg-gray-100 hover:text-black table-wrapper'>
                                <td>
                                    <Link to={`/layout/logs/${log.id}`}>
                                        <div className='w-full h-full pt-3 pb-3'>
                                            <button
                                                className='pl-2 pr-2 rounded-lg font-bold bg-green-100'
                                                style={{
                                                    backgroundColor: log.status === 'DR' ? '#E2F0ED' : log.status === 'SB' ? '#FFEECE' : log.status === 'ON' ? '#E8F3FF' : '#FFE2E3',
                                                    color: log.status === 'DR' ? '#5CB800' : log.status === 'SB' ? '#FFA800' : log.status === 'ON' ? '#1996F0' : '#ED3237'
                                                }}
                                            >
                                                {log.status}
                                            </button>
                                        </div>
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/layout/logs/${log.id}`}>
                                        <div className='w-full h-full pt-3 pb-3'>
                                            <p>{log.driver_name}</p>
                                        </div>
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/layout/logs/${log.id}`}>
                                        <div className='w-full h-full pt-3 pb-3'>
                                            <p>{log.vehicle_id}</p>
                                        </div>
                                    </Link>
                                </td>
                                <td className='t-item  h-[50px] flex items-center p-2 gap-4'>
                                            <TableNav url={`/layout/logs/${log.id}`} />
                                            <p>{log.last_known_location}</p>
                                            <div className='flex items-center gap-2'>
                                                <p>
                                                    <a target='_blank' href={`https://www.google.com/search?q=${log.last_known_location}&rlz=1C1VDKB_enUZ1075UZ1075&oq=kun&gs_lcrp=EgZjaHJvbWUyDwgAEEUYORiDARixAxiABDIGCAEQRRg8MgoIAhAAGLEDGIAEMhAIAxAuGIMBGNQCGLEDGIAEMgoIBBAAGLEDGIAEMhAIBRAAGIMBGLEDGIAEGIoFMg0IBhAAGIMBGLEDGIAEMgYIBxAFGEDSAQkxMTE5NmowajSoAgiwAgE&sourceid=chrome&ie=UTF-8`}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                                            <path d="M10.5 9.50002L14.6 5.40002" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M14.9996 7.4V5H12.5996" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M9.5 5H8.5C6 5 5 6 5 8.5V11.5C5 14 6 15 8.5 15H11.5C14 15 15 14 15 11.5V10.5" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                    </a>
                                                </p>

                                                <CopyButton  text={log.last_known_location != null ? log.last_known_location : "not active"} copy={log.last_known_location != null ? true : false} />

                                            

                                            </div>
                                        </td>
                                <ViolationsCell violations={log.cycle.violations} />
                                <td>
                                    <Link to={`/layout/logs/${log.id}`}>
                                        <div className='w-full h-full pt-3 pb-3'>
                                            <p
                                                className='w-[100px] text-white text-center rounded-lg'
                                                style={{ background: log.eld_connection === 'Connected' ? '#5CB800' : '#ED3237' }}
                                            >
                                                {log.eld_connection}
                                            </p>
                                        </div>
                                    </Link>
                                </td>
                                <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-[50px] h-full pt-3 pb-3'>
                                                    <p style={{ color: "#FFA800", textAlign: "center" }}>{log.cycle.sleeper_berth_time.time}</p>
                                                    <div className=' relative w-full h-[4px] rounded-md' style={{ background: "#FFA80035" }}>
                                                        <span className='absolute top-0 left-0 h-[4px] ' style={{ width: `${log.cycle.sleeper_berth_time.percentage}%`, background: "#FFA800", }}></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-[50px] h-full pt-3 pb-3'>
                                                    <p style={{ color: "#5CB800", textAlign: "center" }}>{log.cycle.remaining_drive_time.time}</p>
                                                    <div className=' relative w-full h-[4px] rounded-md' style={{ background: "#5CB80035" }}>
                                                        <span className='absolute top-0 left-0 h-[4px] ' style={{ width: `${log.cycle.remaining_drive_time.percentage}%`, background: "#5CB800", }}></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-[50px] h-full pt-3 pb-3'>
                                                    <p style={{ color: "#06B6D4", textAlign: "center" }}> {log.cycle.remaining_on_duty_time.time}</p>
                                                    <div className=' relative w-full h-[4px] rounded-md' style={{ background: "#06B6D435" }}>
                                                        <span className='absolute top-0 left-0 h-[4px] ' style={{ width: `${log.cycle.remaining_on_duty_time.percentage}%`, background: "#06B6D4", }}></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-[50px] h-full pt-3 pb-3'>
                                                    <p style={{ color: "#ED3237", textAlign: "center" }}>{log.cycle.weekly_on_duty_limit.time}</p>
                                                    <div className=' relative w-full h-[4px] rounded-md overflow-hidden' style={{ background: "#ED323735" }}>
                                                        <span className='absolute top-0 left-0 h-[4px] ' style={{ width: `${log.cycle.weekly_on_duty_limit.percentage}%`, background: "#ED3237", }}></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='flex items-center justify-center'>
                    <Pagination count={totalPages} shape="rounded" color='primary' onChange={handleChange} />
                </div>
            </div>
        );
    };
    
    


    return (
        <div className='w-full p-4' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>
            <div className='main-content'>
                <div className='flex items-center justify-between mb-5'>
                    <h1 className='text-2xl font-bold' style={{ color: "#1996F0" }}>Logs</h1>

                    <div className='flex items-center justify-between gap-2'>

                        <div className='w-[220px] flex items-center justify-between p-1 rounded-lg  bg-gray-50 border border-gray-300 '>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.58366 17.5C13.9559 17.5 17.5003 13.9556 17.5003 9.58335C17.5003 5.2111 13.9559 1.66669 9.58366 1.66669C5.2114 1.66669 1.66699 5.2111 1.66699 9.58335C1.66699 13.9556 5.2114 17.5 9.58366 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.3337 18.3334L16.667 16.6667" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <input type="text" maxLength={50} placeholder='Search driver' className='w-[180px] text-gray-500 font-medium focus:outline-none bg-transparent' />
                        </div>

                        <div className='w-[220px] rounded-lg text-gray-500 font-medium bg-gray-50 border border-gray-300'>
                            <select className=' w-[90%] border-none bg-transparent focus:outline-none' onChange={(e) => setEldStatus(e.target.value)}>
                                <option value="" selected>Filter by ELD status</option>
                                <option value="True">Connected</option>
                                <option value="False">Disconnected</option>
                            </select>
                        </div>

                        <div className='w-[220px] rounded-lg text-gray-500 font-medium bg-gray-50 border border-gray-300'>
                            <select className=' w-[90%] border-none bg-transparent focus:outline-none' onChange={(e) => setDutyStatus(e.target.value)}>
                                <option value="" selected>Filter by Duty status</option>
                                <option value="dr">DR</option>
                                <option value="on">ON</option>
                                <option value="sb">SB</option>
                                <option value="off">OFF</option>
                            </select>
                        </div>

                        <div className='w-[220px] rounded-lg text-gray-500 font-medium bg-gray-50 border border-gray-300'>
                            <select className=' w-[90%] border-none bg-transparent focus:outline-none'>
                                <option value="" selected>Filter by Violation status</option>
                                <option value="connected">Connected</option>
                                <option value="unconnected">Disconnected</option>
                            </select>
                        </div>



                        <button onClick={fetchData}>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.1298 11.32L18.7598 13.78" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </button>

                    </div>
                </div>

                <div>
                    {isLoading && (
                        <div style={
                            {
                                width: "100%",
                                height: "100vh",
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: 'center',
                                position: "absolute",
                                top: "0px",
                                bottom: "0px",
                                left: "100px",
                                right: "0px",
                            }}>
                            <CircularProgress />
                        </div>
                    )}

                </div>

                <div className='w-full h-full' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>


                    
                    {/* <div className='w-full h-[73vh] border-2 rounded-lg p-4 flex justify-between flex-col'>
                        <table className='w-full border-collapse'>
                            <thead className='w-full text-[13px] text-gray-500'>
                                <tr className='border-b-2 pb-3' style={{ color: "#808080" }}>
                                    <th className=' p-2 text-left'>STATUS</th>
                                    <th className=' p-2 text-left'>DRIVER</th>
                                    <th className=' p-2 text-left'>VEHICLE ID</th>
                                    <th className=' p-2 text-left'>LAST KNOW LOCATION</th>
                                    <th className=' w-[10%] p-2 text-left'>VIOLATION</th>
                                    <th className=' p-2 text-left'>ELD CONNECTION</th>
                                    <th className=' p-2 text-left'>BREAK</th>
                                    <th className=' p-2 text-left'>DRIVE</th>
                                    <th className=' p-2 text-left'>SHIFT</th>
                                    <th className=' p-2 text-left'>CYCLE</th>
                                </tr>
                            </thead>
                            <tbody className='text-[12px]'>

                                {logs.map((log, index) => (

                                    <tr key={index} className='border-b-[1px] h-[50px] hover:bg-gray-100 hover:text-black table-wrapper' >
                                        <td>
                                           
                                            <Link to={`/layout/logs/${log.id}`}>
                                               <div className='w-full h-full pt-3 pb-3'>
                                               <button
                                                className=' pl-2 pr-2 rounded-lg font-bold bg-green-100'
                                                style={{

                                                    backgroundColor: log.status == 'DR' ? '#E2F0ED' : log.status == 'SB' ? '#FFEECE' : log.status == 'ON' ? '#E8F3FF' : '#FFE2E3',
                                                    color: log.status == 'DR' ? '#5CB800' : log.status == 'SB' ? '#FFA800' : log.status == 'ON' ? '#1996F0' : '#ED3237'
                                                }}
                                            >{log.status}</button>
                                               </div>
                                            </Link>
                                       
                                        </td>
                                        <td >
                                            
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-full h-full pt-3 pb-3'>
                                                    <p>{log.driver_name}</p>
                                                </div>
                                            </Link>


                                        </td>
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-full h-full pt-3 pb-3'>
                                                    <p>
                                                        {log.vehicle_id}
                                                    </p>
                                                </div>
                                            </Link>

                                        </td>
                                        <td className='t-item  h-[50px] flex items-center p-2 gap-4'>
                                            <TableNav url={`/layout/logs/${log.id}`} />
                                            <p>{log.last_known_location}</p>
                                            <div className='flex items-center gap-2'>
                                                <p>
                                                    <a target='_blank' href={`https://www.google.com/search?q=${log.last_known_location}&rlz=1C1VDKB_enUZ1075UZ1075&oq=kun&gs_lcrp=EgZjaHJvbWUyDwgAEEUYORiDARixAxiABDIGCAEQRRg8MgoIAhAAGLEDGIAEMhAIAxAuGIMBGNQCGLEDGIAEMgoIBBAAGLEDGIAEMhAIBRAAGIMBGLEDGIAEGIoFMg0IBhAAGIMBGLEDGIAEMgYIBxAFGEDSAQkxMTE5NmowajSoAgiwAgE&sourceid=chrome&ie=UTF-8`}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                                            <path d="M10.5 9.50002L14.6 5.40002" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M14.9996 7.4V5H12.5996" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M9.5 5H8.5C6 5 5 6 5 8.5V11.5C5 14 6 15 8.5 15H11.5C14 15 15 14 15 11.5V10.5" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                    </a>
                                                </p>

                                                <CopyButton  text={log.last_known_location != null ? log.last_known_location : "not active"} copy={log.last_known_location != null ? true : false} />

                                            

                                            </div>
                                        </td>
                                        <ViolationsCell violations={log.cycle.violations} />                                       
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-full h-full  pt-3 pb-3'>
                                                    <p
                                                        className='w-[100px] text-white text-center rounded-lg'
                                                        style={{
                                                            background: log.eld_connection == 'Connected' ? '#5CB800' : '#ED3237'
                                                        }}
                                                    >
                                                        {log.eld_connection}
                                                    </p>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-[50px] h-full pt-3 pb-3'>
                                                    <p style={{ color: "#FFA800", textAlign: "center" }}>{log.cycle.sleeper_berth_time.time}</p>
                                                    <div className=' relative w-full h-[4px] rounded-md' style={{ background: "#FFA80035" }}>
                                                        <span className='absolute top-0 left-0 h-[4px] ' style={{ width: `${log.cycle.sleeper_berth_time.percentage}%`, background: "#FFA800", }}></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-[50px] h-full pt-3 pb-3'>
                                                    <p style={{ color: "#5CB800", textAlign: "center" }}>{log.cycle.remaining_drive_time.time}</p>
                                                    <div className=' relative w-full h-[4px] rounded-md' style={{ background: "#5CB80035" }}>
                                                        <span className='absolute top-0 left-0 h-[4px] ' style={{ width: `${log.cycle.remaining_drive_time.percentage}%`, background: "#5CB800", }}></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-[50px] h-full pt-3 pb-3'>
                                                    <p style={{ color: "#06B6D4", textAlign: "center" }}> {log.cycle.remaining_on_duty_time.time}</p>
                                                    <div className=' relative w-full h-[4px] rounded-md' style={{ background: "#06B6D435" }}>
                                                        <span className='absolute top-0 left-0 h-[4px] ' style={{ width: `${log.cycle.remaining_on_duty_time.percentage}%`, background: "#06B6D4", }}></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/layout/logs/${log.id}`}>
                                                <div className='w-[50px] h-full pt-3 pb-3'>
                                                    <p style={{ color: "#ED3237", textAlign: "center" }}>{log.cycle.weekly_on_duty_limit.time}</p>
                                                    <div className=' relative w-full h-[4px] rounded-md overflow-hidden' style={{ background: "#ED323735" }}>
                                                        <span className='absolute top-0 left-0 h-[4px] ' style={{ width: `${log.cycle.weekly_on_duty_limit.percentage}%`, background: "#ED3237", }}></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                    </tr>

                                ))}

                            </tbody>
                        </table>
                        <div className=' flex items-center justify-center'>
                            <Pagination count={totalPages} shape="rounded" color='primary' onChange={handleChange} />
                        </div>
                    </div> */}
                    
                    <LogTable/>

                </div>


            </div>
        </div>
    );
}

export default LogCarousel;
