import React, { createContext, useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SignIn from './Components/Registration/Signin/SingIn';
import SignUp from './Components/Registration/Signup/SignUp';
import GeneralLayout from './Components/Dashboard/genralLayout/generalLayout';
import AdminCompanies from './Components/Registration/AdminCompanies/AdminCompanies';
import useTokenRefresher from './Api/TokenRefresher';
import { LoaderProvider } from './LoaderProvider';

export const UserContext = createContext();



function App() {
  const [night, setNight] = useState(true);

  useTokenRefresher();

  const NightMode = () => {
    setNight(!night);
  };


  

  return (
    <div
      className="App"
 
    >
      <LoaderProvider>
        <UserContext.Provider value={{ NightMode, night }}>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="layout/*" element={<GeneralLayout />} />
            <Route path="admincompanies" element={<AdminCompanies />} />
          </Routes>
        </UserContext.Provider>
      </LoaderProvider>
    </div>
  );
}

export default App;
