import React, { useContext } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../DashboarNavbar/DashboardNavbar';
import { UserContext } from '../../../App';
import LayoutRoutes from './LayoutRoutes';
import '../../../dashboardlayout.css'



const GeneralLayout = () => {
  const user = useContext(UserContext);

  return (
    <div
      className="dashboard"
      style={{
        background: user.night ? '#fff' : '#1E2D4C',
        color: user.night ? '' : '#fff',
        transition: 'all 0.3s ease',
       
      }}
    >
      <Sidebar />
      <main className="main-content">
        <div  className="main-content">
        <Navbar />
        </div>
        <LayoutRoutes/>
        <Outlet />
      </main>
    </div>
  );
};

export default GeneralLayout;
