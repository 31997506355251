import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import { UserContext } from '../../../App';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from 'react-bootstrap';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContext } from '../../../LoaderProvider';



function Vehisles() {
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    const [searchDriver, setSearchDriver] = useState('')
    const [filter, setFilter] = useState("")
    const { isLoading, setIsLoading } = useContext(LoaderContext);


    let companyId = localStorage.getItem("companyId");

    const accessToken = localStorage.getItem('accessToken');
    const navigate = useNavigate()

    const fetchData = async (page) => {
        setIsLoading(true)
        try {
            const response = await axios.get(`https://xertzdigital.net:8001/api/v1/accounts/vehicle/company/${companyId}/?vehicle_number=${searchDriver}&is_active=${filter}&page=${page}`);
            setLogs(response.data.results);
            setTotalPages(response.data.total_pages);
            console.log(response);


        } catch (error) {
            console.error('Xato:', error);
        } finally {
            setIsLoading(false)
        }
    };


    useEffect(() => {
        fetchData(currentPage);
    }, [filter, searchDriver, currentPage]);

    const openDeleteDialog = (id) => {
        setSelectedId(id);
        setOpen(true);
    };


    const handleDelete = async () => {
        try {
            await axios.delete(`https://xertzdigital.net:8001/api/v1/accounts/vehicle/${selectedId}/delete/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            console.log("Driver deleted successfully");
            setOpen(false); // Modalni yopish
            fetchData(currentPage); // Ro'yxatni yangilash
        } catch (error) {
            console.error("Delete error:", error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };


    const handleChange = (e, p) => {

        setCurrentPage(p);
    }

    const filterDriver = (event, newFilter) => {
        if (newFilter !== null) {
            setFilter(newFilter);
        }


    };

    const styles = {
        container: {
            display: "flex",
            border: "1px solid #ddd",
            borderRadius: "8px",
            overflow: "hidden",
            width: "fit-content",
        },
        button: {
            border: "none",
            background: "none",
            color: "#888",
            cursor: "pointer",
        },
        activeButton: {
            border: "none",
            backgroundColor: "#1996F0", // Ko'k rang
            color: "#fff",
            cursor: "pointer",
        },
    };


    const user = useContext(UserContext)

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    // Faylni tanlash
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    // Faylni yuborish
    const handleUpload = async () => {
        if (!file) {
            alert("Please select a file before uploading.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("company_id", companyId);
        setUploading(true);

        try {
            const response = await axios.post("https://xertzdigital.net:8001/api/v1/main/dashboard/excel/vehicle/",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${accessToken}`,
                    },

                });
            console.log("formFata", response);
            alert(`File uploaded successfully: ${response.data.message}`);
        } catch (error) {
            console.error("Error uploading file:", error.response?.data || error.message);
            alert("Failed to upload file. Please try again.");
        } finally {
            setUploading(false);
        }
    };


    return (
        <div className='p-3' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>

            <div className='main-content'>
                <div className='w-full h-full'>


                    <div className='flex items-center justify-between border-b-[1px] border-gray-300 mb-3 pb-[5px]'>
                        <h1 className='text-2xl font-bold' style={{ color: '#1996F0' }}>Vehicles</h1>
                        <div className='flex items-center gap-5'>

                            <div className='flex items-center gap-2  rounded-lg p-1' style={{border:"2px solid #E6E6E6"}}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.58366 17.5C13.9559 17.5 17.5003 13.9556 17.5003 9.58335C17.5003 5.2111 13.9559 1.66669 9.58366 1.66669C5.2114 1.66669 1.66699 5.2111 1.66699 9.58335C1.66699 13.9556 5.2114 17.5 9.58366 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.3337 18.3334L16.667 16.6667" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <input type="text" placeholder='Search' maxLength={100} className='focus:outline-none placeholder:text-gray-500 text-gray-500  bg-transparent' onChange={(e) => setSearchDriver(e.target.value)} />
                            </div>

                            <ToggleButtonGroup
                                value={filter}
                                exclusive
                                onChange={filterDriver}
                                aria-label="status filter"
                                color="primary"

                            >
                                <ToggleButton value={true} style={{ color: user.night ? '' : "white" }}>Active</ToggleButton>
                                <ToggleButton value="" style={{ color: user.night ? '' : "white" }}>All</ToggleButton>
                                <ToggleButton value={false} style={{ color: user.night ? '' : "white" }}>Not active</ToggleButton>
                            </ToggleButtonGroup>

                            <button
                                onClick={() => navigate('/layout/createvehicle')}
                                className='pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none'
                                style={{ background: "#E8F3FF", color: "#1996F0" }}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5003 1.66669V10C12.5003 10.9167 11.7503 11.6667 10.8337 11.6667H1.66699V5.00002C1.66699 3.15835 3.15866 1.66669 5.00033 1.66669H12.5003Z" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.3337 11.6667V14.1667C18.3337 15.55 17.217 16.6667 15.8337 16.6667H15.0003C15.0003 15.75 14.2503 15 13.3337 15C12.417 15 11.667 15.75 11.667 16.6667H8.33366C8.33366 15.75 7.58366 15 6.66699 15C5.75033 15 5.00033 15.75 5.00033 16.6667H4.16699C2.78366 16.6667 1.66699 15.55 1.66699 14.1667V11.6667H10.8337C11.7503 11.6667 12.5003 10.9167 12.5003 10V4.16669H14.0337C14.6337 4.16669 15.1836 4.4917 15.4836 5.00836L16.9087 7.50002H15.8337C15.3753 7.50002 15.0003 7.87502 15.0003 8.33335V10.8334C15.0003 11.2917 15.3753 11.6667 15.8337 11.6667H18.3337Z" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.66667 18.3333C7.58714 18.3333 8.33333 17.5871 8.33333 16.6667C8.33333 15.7462 7.58714 15 6.66667 15C5.74619 15 5 15.7462 5 16.6667C5 17.5871 5.74619 18.3333 6.66667 18.3333Z" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13.3337 18.3333C14.2541 18.3333 15.0003 17.5871 15.0003 16.6667C15.0003 15.7462 14.2541 15 13.3337 15C12.4132 15 11.667 15.7462 11.667 16.6667C11.667 17.5871 12.4132 18.3333 13.3337 18.3333Z" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.3333 10V11.6667H15.8333C15.375 11.6667 15 11.2917 15 10.8333V8.33333C15 7.875 15.375 7.5 15.8333 7.5H16.9083L18.3333 10Z" stroke="#1996F0" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <p>Add vehicle</p>
                            </button>

                            <div>
                                {/* Fayl tanlash inputi */}

                                <div>
                                    <label for="file-upload" className={`pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none ${file && 'hidden'}`} style={{ background: "#E8F3FF", color: "#1996F0" }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_41_6623)">
                                                <path d="M11.0238 0.768342C10.8813 0.650842 10.6925 0.602092 10.51 0.635842L0.51 2.51084C0.21375 2.56584 0 2.82334 0 3.12459V16.8746C0 17.1746 0.21375 17.4333 0.51 17.4883L10.51 19.3633C10.5475 19.3708 10.5875 19.3746 10.625 19.3746C10.7688 19.3746 10.9113 19.3246 11.0238 19.2308C11.1675 19.1121 11.25 18.9346 11.25 18.7496V1.24959C11.25 1.06334 11.1675 0.887092 11.0238 0.768342ZM10 17.9958L1.25 16.3558V3.64334L10 2.00334V17.9958Z" fill="#1996F0" />
                                                <path d="M19.375 3.12463H10.625C10.28 3.12463 10 3.40463 10 3.74963C10 4.09463 10.28 4.37463 10.625 4.37463H18.75V15.6246H10.625C10.28 15.6246 10 15.9046 10 16.2496C10 16.5946 10.28 16.8746 10.625 16.8746H19.375C19.72 16.8746 20 16.5946 20 16.2496V3.74963C20 3.40463 19.72 3.12463 19.375 3.12463Z" fill="#1996F0" />
                                                <path d="M13.125 5.62463H10.625C10.28 5.62463 10 5.90463 10 6.24963C10 6.59463 10.28 6.87463 10.625 6.87463H13.125C13.47 6.87463 13.75 6.59463 13.75 6.24963C13.75 5.90463 13.47 5.62463 13.125 5.62463Z" fill="#1996F0" />
                                                <path d="M13.125 8.12463H10.625C10.28 8.12463 10 8.40463 10 8.74963C10 9.09463 10.28 9.37463 10.625 9.37463H13.125C13.47 9.37463 13.75 9.09463 13.75 8.74963C13.75 8.40463 13.47 8.12463 13.125 8.12463Z" fill="#1996F0" />
                                                <path d="M13.125 10.6246H10.625C10.28 10.6246 10 10.9046 10 11.2496C10 11.5946 10.28 11.8746 10.625 11.8746H13.125C13.47 11.8746 13.75 11.5946 13.75 11.2496C13.75 10.9046 13.47 10.6246 13.125 10.6246Z" fill="#1996F0" />
                                                <path d="M13.125 13.1246H10.625C10.28 13.1246 10 13.4046 10 13.7496C10 14.0946 10.28 14.3746 10.625 14.3746H13.125C13.47 14.3746 13.75 14.0946 13.75 13.7496C13.75 13.4046 13.47 13.1246 13.125 13.1246Z" fill="#1996F0" />
                                                <path d="M16.875 5.62463H15.625C15.28 5.62463 15 5.90463 15 6.24963C15 6.59463 15.28 6.87463 15.625 6.87463H16.875C17.22 6.87463 17.5 6.59463 17.5 6.24963C17.5 5.90463 17.22 5.62463 16.875 5.62463Z" fill="#1996F0" />
                                                <path d="M16.875 8.12463H15.625C15.28 8.12463 15 8.40463 15 8.74963C15 9.09463 15.28 9.37463 15.625 9.37463H16.875C17.22 9.37463 17.5 9.09463 17.5 8.74963C17.5 8.40463 17.22 8.12463 16.875 8.12463Z" fill="#1996F0" />
                                                <path d="M16.875 10.6246H15.625C15.28 10.6246 15 10.9046 15 11.2496C15 11.5946 15.28 11.8746 15.625 11.8746H16.875C17.22 11.8746 17.5 11.5946 17.5 11.2496C17.5 10.9046 17.22 10.6246 16.875 10.6246Z" fill="#1996F0" />
                                                <path d="M16.875 13.1246H15.625C15.28 13.1246 15 13.4046 15 13.7496C15 14.0946 15.28 14.3746 15.625 14.3746H16.875C17.22 14.3746 17.5 14.0946 17.5 13.7496C17.5 13.4046 17.22 13.1246 16.875 13.1246Z" fill="#1996F0" />
                                                <path d="M8.59608 12.0883L4.22108 7.08833C3.99108 6.82708 3.59733 6.80208 3.33858 7.02958C3.07858 7.25708 3.05233 7.65208 3.27983 7.91083L7.65483 12.9108C7.77858 13.0521 7.95108 13.1246 8.12483 13.1246C8.27108 13.1246 8.41733 13.0733 8.53733 12.9696C8.79733 12.7421 8.82358 12.3483 8.59608 12.0883Z" fill="#1996F0" />
                                                <path d="M8.50877 6.38212C8.23627 6.16837 7.84377 6.21962 7.63127 6.49087L3.25627 12.1159C3.04502 12.3884 3.09377 12.7821 3.36627 12.9934C3.48127 13.0821 3.61627 13.1246 3.75002 13.1246C3.93502 13.1246 4.12002 13.0421 4.24252 12.8846L8.61752 7.25962C8.83002 6.98587 8.78127 6.59337 8.50877 6.38212Z" fill="#1996F0" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_41_6623">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p>{uploading ? "Uploading..." : "Upload Excel file"}</p>
                                    </label>
                                    <input
                                        type="file"
                                        accept=".xlsx,.xls"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                        id="file-upload"
                                    />
                                </div>

                                {file && (
                                    <div>
                                        <button
                                            onClick={handleUpload}
                                            className="pl-4 pr-4 p-1 rounded-md border-none"
                                            style={{ background: "#E8F3FF", color: "#1996F0" }}
                                        >
                                            Submit File
                                        </button>
                                    </div>
                                )}
                            </div>

                            <button onClick={fetchData}>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                    <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1298 11.3199L18.7598 13.7799" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </button>

                        </div>
                    </div>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to delete the driver?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>NO</Button>
                            <Button onClick={handleDelete} autoFocus>
                                YES
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* ------------------------------------------------------------------------------------------------------------------ */}

                    <div>
                        {isLoading && (
                            <div style={
                                {
                                    width: "100%",
                                    height: "100vh",
                                    display: 'flex',
                                    alignItems: "center",
                                    justifyContent: 'center',
                                    position: "absolute",
                                    top: "0px",
                                    bottom: "0px",
                                    left: "100px",
                                    right: "0px",
                                }}>
                                <CircularProgress />
                            </div>
                        )}

                    </div>

                    <div className='h-[73vh] border-2 rounded-lg p-4 flex justify-between flex-col driver_doc'>
                        <table className='w-full table-fixed border-collapse'>
                            <thead className='w-full text-[13px] font-[600] text-start' style={{ color: "#808080" }}>
                                <tr>
                                    <th className='w-[8%] text-left font-[600]'>VEHICLE ID</th>
                                    <th className='w-[10%] text-left font-[600]'>DRIVERS</th>
                                    <th className='w-[7%] text-left font-[600]'>MAKE</th>
                                    <th className='w-[7%] text-left font-[600]'>MODEL</th>
                                    <th className='w-[7%] text-left font-[600]'>LICENSE STATE</th>
                                    <th className='w-[7%] text-left font-[600]'>ELD</th>
                                    <th className='w-[10%] text-left font-[600]'>ADDRESS</th>
                                    <th className='w-[10%] text-left font-[600]'>SERIAL NUMBER</th>
                                    <th className='w-[7%] text-left font-[600]'>NOTES</th>
                                    <th className='w-[10%] text-left font-[600]'>VIN</th>
                                    <th className='w-[7%] text-left font-[600]'>STATUS</th>
                                    <th className='w-[10%] text-left font-[600]'>ACTIVATED</th>
                                    <th className='w-[10%] text-left font-[600]'>TERMINATED</th>
                                    <th className='w-[10%] text-left font-[600]'>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody className='text-[12px]'>
                                {logs.map((log) => (
                                    <tr key={log.id} className='border-b-[1px] h-[50px] hover:bg-gray-100 hover:text-black'>
                                        <td className='w-[10%]'>
                                            <div className='flex items-center gap-2'>
                                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_54_15422)">
                                                        <path d="M2.25047 16.5C1.64948 16.5 1.08654 16.267 0.659759 15.8402C0.219229 15.3997 -0.0148335 14.814 0.000728977 14.1912C0.0162914 13.5684 0.279291 12.9952 0.74129 12.5772L7.82359 6.16936C7.26378 4.64099 7.63687 2.89647 8.80165 1.73172C9.8608 0.672595 11.4212 0.254908 12.8739 0.642001C13.0358 0.685126 13.1625 0.811438 13.206 0.973313C13.2495 1.13516 13.2032 1.30794 13.0847 1.42644L11.4099 3.10121L11.7414 4.75862L13.3988 5.09009L15.0736 3.41531C15.1921 3.29678 15.3648 3.25053 15.5267 3.29406C15.6886 3.33756 15.8149 3.46415 15.858 3.62612C16.245 5.07877 15.8274 6.63918 14.7683 7.69833C13.6035 8.86317 11.859 9.23617 10.3306 8.67639L3.92285 15.7587C3.50485 16.2207 2.93166 16.4837 2.30882 16.4993C2.28935 16.4998 2.26988 16.5 2.25047 16.5ZM11.7476 1.43759C10.9007 1.44772 10.0753 1.78397 9.46462 2.39469C8.49396 3.36537 8.23009 4.85362 8.80809 6.09805C8.89462 6.28436 8.84977 6.5053 8.69743 6.64315L1.37032 13.2724C1.10063 13.5164 0.947102 13.851 0.938008 14.2146C0.928915 14.5782 1.06554 14.9201 1.32273 15.1773C1.57991 15.4345 1.92173 15.572 2.28538 15.562C2.64897 15.5529 2.9836 15.3994 3.2276 15.1297L9.85686 7.80255C9.99471 7.65024 10.2156 7.60539 10.402 7.69189C11.6463 8.26992 13.1346 8.00602 14.1053 7.03536C14.7159 6.42471 15.0522 5.59924 15.0624 4.75234L13.8843 5.93046C13.7735 6.0413 13.6146 6.0894 13.4609 6.05868L11.251 5.61671C11.0655 5.57959 10.9204 5.43455 10.8833 5.24896L10.4414 3.03909C10.4106 2.8854 10.4587 2.7265 10.5696 2.61568L11.7476 1.43759Z" fill="#1996F0" />
                                                        <path d="M2.31738 14.6512C2.57627 14.6512 2.78613 14.4414 2.78613 14.1825C2.78613 13.9236 2.57627 13.7137 2.31738 13.7137C2.0585 13.7137 1.84863 13.9236 1.84863 14.1825C1.84863 14.4414 2.0585 14.6512 2.31738 14.6512Z" fill="#1996F0" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_54_15422">
                                                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                                {log.vehicle_number}
                                            </div>
                                        </td>
                                        <td className='w-[10%]'>{log.driver}</td>
                                        <td className='w-[7%]'>{log.make}</td>
                                        <td className='w-[7%]'>{log.model}</td>
                                        <td className='w-[7%]'>{log.license_plate_state}</td>
                                        <td className='w-[7%]'>{log.eld_device_type}</td>
                                        <td className='w-[10%]'>not available</td>
                                        <td className='w-[10%]'>{log.eld_serial_number}</td>
                                        <td className='w-[7%]'>{log.notes}</td>
                                        <td className='w-[10%]'>{log.vin}</td>
                                        <td className='w-[7%]'>
                                            <button
                                                className='w-[65px] p-1 rounded-lg text-white'
                                                style={{
                                                    background: log.status ? '#5CB800' : '#ED3237',
                                                }}
                                            >
                                                {log.status ? 'Active' : 'Not active'}
                                            </button>
                                        </td>
                                        <td className='w-[10%]'>{log.activated_date || 'not available'}</td>
                                        <td className='w-[10%]'>{log.terminated_date || 'not available'}</td>
                                        <td className='w-[10%] h-[50px] flex items-center gap-3'>
                                            <Link to={`/layout/vehicles/${log.id}`}>
                                                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="30" height="30" rx="4" fill="#FFEECE" />
                                                    <path d="M15.9631 8.6727L9.80558 15.1902C9.57308 15.4377 9.34808 15.9252 9.30308 16.2627L9.02558 18.6927C8.92808 19.5702 9.55808 20.1702 10.4281 20.0202L12.8431 19.6077C13.1806 19.5477 13.6531 19.3002 13.8856 19.0452L20.0431 12.5277C21.1081 11.4027 21.5881 10.1202 19.9306 8.5527C18.2806 7.0002 17.0281 7.5477 15.9631 8.6727Z" stroke="#FFA800" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.9346 9.76013C15.2571 11.8301 16.9371 13.4126 19.0221 13.6226" stroke="#FFA800" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.26758 22.4727H21.7676" stroke="#FFA800" stroke-width="1.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </Link>
                                            <button onClick={() => openDeleteDialog(log.id)}>
                                                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="30" height="30" rx="4" fill="#FFE2E3" />
                                                    <path d="M11.6279 18.5613L18.699 11.4902" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M18.699 18.5613L11.6279 11.4902" stroke="#ED3237" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className='flex items-center justify-center'>
                            <Pagination count={totalPages} shape='rounded' color='primary' onChange={handleChange} />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Vehisles