import React, { useCallback, useContext, useEffect, useState } from 'react';
import './dashboardnavbar.css';
import axios from 'axios';
import { UserContext } from '../../../App';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
// import AddAlertIcon from '@mui/icons-material/AddAlert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


export function timezoneName(time) {
  return time

}

export function searchDriverFunction(value) {
  return value
}





const Navbar = () => {
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [notification, setNotification] = useState([
    { id: 1, name: "Carens", date: new Date(), news: "Danger zone" },
    { id: 2, name: "Alex", date: new Date(), news: "Danger zone" },
    { id: 3, name: "Abdulelah", date: new Date(), news: "Danger zone" },
    { id: 4, name: "Estil", date: new Date(), news: "Danger zone" },
    { id: 5, name: "Daniel", date: new Date(), news: "Danger zone" },
  ])
  const [navbarStatus, setNavbarStatus] = useState({
    succes: true,
    data: {
      dr: 0,
      sb: 0,
      off: 0,
      on: 0,
      ym: 0,
    }
  })
  const accessToken = localStorage.getItem('accessToken');
  const companyId = localStorage.getItem('companyId');
  const [timeZones, setTimeZones] = useState([]);
  const [currentTimes, setCurrentTimes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const timeZoneMapping = {
    Eastern: "America/New_York",
    Central: "America/Chicago",
    Mountain: "America/Denver",
    Pacific: "America/Los_Angeles",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://xertzdigital.net:8001/api/v1/accounts/user/companies-list/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setTimeZones(response.data);
        setError(null);
      } catch (err) {
        console.error("Navbar Error:", err);
        setError("API call failed!");
      }
    };

    if (accessToken) {
      fetchData();
    } else {
      setError("AccessToken topilmadi!");
    }
  }, [accessToken,companyId]);

  useEffect(() => {
    const updateTimes = () => {
      if (timeZones && timeZones.length > 0) {
        const times = timeZones.map((tz) => {
          const timeZoneName = timeZoneMapping[tz.timezone] || tz.timezone;
          try {
            const date = new Date();

            // Local time in the specific timezone
            const formatter = new Intl.DateTimeFormat("en-US", {
              timeZone: timeZoneName,
              hour: "2-digit",
              minute: "2-digit",
            });
            const localTime = formatter.format(date);

            // UTC time
            const utcFormatter = new Intl.DateTimeFormat("en-US", {
              timeZone: "UTC",
              hour: "2-digit",
              minute: "2-digit",
            });
            const utcTime = utcFormatter.format(date);

            // Return an object for each timezone
            return {
              timeZone: tz.timezone,
              localTime: localTime,
              utcTime: utcTime,
            };
          } catch (e) {
            console.error(`Invalid timezone: ${timeZoneName}`, e);
            return {
              timeZone: tz.timezone,
              error: "Invalid timezone",
            };
          }
        });
        setCurrentTimes(times);
      }
    };

    const intervalId = setInterval(updateTimes, 60000);
    updateTimes();

    return () => clearInterval(intervalId);
  }, [timeZones]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get('https://xertzdigital.net:8001/api/v1/main/activity-summary/1/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setNavbarStatus(response.data);

      } catch (err) {
        console.log("navbar", err);

      }
    };

    if (accessToken) {
      fetchData();
    }
  }, [accessToken,companyId]);

  // const readNotifItem = async (id) =>{
  //   await axios.post(`https://xertzdigital.net:8001/api/v1/accounts/notifications/${id}/${companyId}/read/`);
  
  // }

  // const readAllNotification = async () =>{
  //   await axios.post(`https://xertzdigital.net:8001/api/v1/accounts/notifications/${companyId}/read-all/`);
  // }

  const readNotifItem = async (id) => {
    try {
      const response = await axios.post(
        `https://xertzdigital.net:8001/api/v1/accounts/notifications/${id}/${companyId}/read/`
      );
  
      if (response.status === 200) {
        // Update the state for the specific notification
        setNotification((prevNotifications) =>
          prevNotifications.map((notif) =>
            notif.id === id ? { ...notif, is_read: true } : notif
          )
        );
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };
  
  const readAllNotification = async () => {
    try {
      const response = await axios.post(
        `https://xertzdigital.net:8001/api/v1/accounts/notifications/${companyId}/read-all/`
      );
  
      if (response.status === 200) {
        // Update the state for all notifications
        setNotification((prevNotifications) =>
          prevNotifications.map((notif) => ({ ...notif, is_read: true }))
        );
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };
  

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://xertzdigital.net:8001/api/v1/accounts/notificiations/${companyId}/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setNotification(response.data);

      } catch (err) {
        console.log("navbar", err);

      }
    };

    if (accessToken) {
      fetchData();
    }
  }, [accessToken,companyId, ]);


  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);



  function notificationsLabel(count) {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  }

  const user = useContext(UserContext)

  const handleCloseTime = () => {
    setAnchorEl(null);
  };

  const timeOpen = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };



console.log("notifikation:",notification);
  

  return (
    <div className="navbar" style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>
      {/* Driver Search */}

      <div className='flex items-center gap-2 border-[2px] border-gray-300 p-1 rounded-lg'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.58268 17.5C13.9549 17.5 17.4993 13.9555 17.4993 9.58329C17.4993 5.21104 13.9549 1.66663 9.58268 1.66663C5.21043 1.66663 1.66602 5.21104 1.66602 9.58329C1.66602 13.9555 5.21043 17.5 9.58268 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18.3327 18.3333L16.666 16.6666" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <input
          type="text"
          maxLength={100}
          placeholder='Search driver'
          className={`focus:outline-none bg-transparent ${user.night ? "text-gray-400" : 'text-white'}`}
          onChange={(e) => searchDriverFunction(e.target.value)}
        />

      </div>

      {/* Status buttons */}
      <div className="status-buttons">
        <div className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-[600] gap-3" style={{ background: "#E2F0ED", color: "#5CB800" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: '#5CB800' }}>{navbarStatus.data.dr}</span>DR</div>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-[600] gap-3" style={{ background: '#FFE2E3', color: "#ED3237" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#ED3237" }}>{navbarStatus.data.off}</span>OFF</span>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-[600] gap-3" style={{ background: '#E8F3FF', color: "#1996F0" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#1996F0" }}>{navbarStatus.data.on}</span>ON</span>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-[600] gap-3" style={{ background: '#F3F3F4', color: "#808080" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#808080" }}>0</span>PC</span>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-[600] gap-3" style={{ background: '#FFEECE', color: "#FFA800" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#FFA800" }}>{navbarStatus.data.sb}</span>SB</span>
        <span className=" p-1 pl-[10px] pr-[10px] rounded-lg flex items-center font-[600] gap-3" style={{ background: '#DCFAFF', color: "#06B6D4" }}><span className='pt-[1px] pl-[8px] pr-[8px] rounded-md font-semibold bg-white text-md' style={{ color: "#06B6D4" }}>{navbarStatus.data.ym}</span>YM</span>

      </div>

      {/* Time period */}


      <div className='flex items-center gap-4'>

        <div>
        <div onClick={handleClickOpen('paper')}>         
              <Badge badgeContent={notification.length} color="primary">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_14_5878)">
                    <rect width="36" height="36" rx="8" fill="white" />
                    <path d="M0.5 16C0.5 12.2146 0.501062 9.4259 0.788435 7.28845C1.07387 5.16538 1.63351 3.75989 2.6967 2.6967C3.75989 1.63351 5.16538 1.07387 7.28845 0.788435C9.4259 0.501062 12.2146 0.5 16 0.5H190C193.785 0.5 196.574 0.501062 198.712 0.788435C200.835 1.07387 202.24 1.63351 203.303 2.6967C204.366 3.75989 204.926 5.16538 205.212 7.28845C205.499 9.4259 205.5 12.2146 205.5 16V20C205.5 23.7854 205.499 26.5741 205.212 28.7116C204.926 30.8346 204.366 32.2401 203.303 33.3033C202.24 34.3665 200.835 34.9261 198.712 35.2116C196.574 35.4989 193.785 35.5 190 35.5H16C12.2146 35.5 9.4259 35.4989 7.28845 35.2116C5.16538 34.9261 3.75989 34.3665 2.6967 33.3033C1.63351 32.2401 1.07387 30.8346 0.788435 28.7116C0.501062 26.5741 0.5 23.7854 0.5 20V16Z" fill="#F8F8FA" stroke="#E6E6E6" />
                    <path d="M18.0167 10.425C15.2584 10.425 13.0167 12.6667 13.0167 15.425V17.8334C13.0167 18.3417 12.8001 19.1167 12.5417 19.55L11.5834 21.1417C10.9917 22.125 11.4001 23.2167 12.4834 23.5834C16.0751 24.7834 19.9501 24.7834 23.5417 23.5834C24.5501 23.25 24.9917 22.0584 24.4417 21.1417L23.4834 19.55C23.2334 19.1167 23.0167 18.3417 23.0167 17.8334V15.425C23.0167 12.675 20.7667 10.425 18.0167 10.425Z" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" />
                    <path d="M19.5582 10.6666C19.2999 10.5916 19.0332 10.5332 18.7582 10.4999C17.9582 10.3999 17.1915 10.4582 16.4749 10.6666C16.7165 10.0499 17.3165 9.61658 18.0165 9.61658C18.7165 9.61658 19.3165 10.0499 19.5582 10.6666Z" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20.5166 23.8834C20.5166 25.2584 19.3916 26.3834 18.0166 26.3834C17.3333 26.3834 16.6999 26.1001 16.2499 25.6501C15.7999 25.2001 15.5166 24.5668 15.5166 23.8834" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" />
                  </g>
                  <rect x="0.625" y="0.625" width="34.75" height="34.75" rx="7.375" stroke="#E6E6E6" stroke-width="1.25" />
                  <defs>
                    <clipPath id="clip0_14_5878">
                      <rect width="36" height="36" rx="8" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Badge>           
          </div>

          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title" className='flex items-center justify-between'>Notification<Button onClick={handleClose}><CancelIcon color='primary' /></Button></DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
                style={{ width: "400px" }}
              >

                {
                  notification.map((item) => (

                    <div className='w-[400px] border-b-[1px] border-gray-300 p-4 cursor-pointer hover:bg-gray-100' onClick={() => readNotifItem(item.id)}>

                      <div className='flex items-start gap-4 '>
                      <p className={`text-[20px] mb-2 ${item.is_read ? 'text-green-500' : 'text-black'} `} >{item.title}</p>
                      {
                        item.is_read ? <DoneAllIcon style={{color:"green"}}/> : <ErrorOutlineIcon color='error'/>
                      }
                      </div>
                      <p className={`text-xs mb-2 ${item.is_read ? 'text-green-500' : 'text-gray-500'}`}>{item.date}</p>
                      <p className={`text-xs ${item.is_read ? 'text-green-500' : 'text-gray-500'}`}>{item.description}</p>

                    </div>

                  ))
                }

              </DialogContentText>
            </DialogContent>
            <DialogActions>

              <Button onClick={() => readAllNotification()} style={{ width: "100%", padding: "5px", background: "#E8F3FF" }}>Mark all as read</Button>
            </DialogActions>
          </Dialog>
        </div>
        
        
          
        <button onClick={() => user.NightMode()}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_54_7545)">
              <rect width="36" height="36" rx="8" fill="white" />
              <path d="M0.5 16C0.5 12.2146 0.501062 9.4259 0.788435 7.28845C1.07387 5.16538 1.63351 3.75989 2.6967 2.6967C3.75989 1.63351 5.16538 1.07387 7.28845 0.788435C9.4259 0.501062 12.2146 0.5 16 0.5H190C193.785 0.5 196.574 0.501062 198.712 0.788435C200.835 1.07387 202.24 1.63351 203.303 2.6967C204.366 3.75989 204.926 5.16538 205.212 7.28845C205.499 9.4259 205.5 12.2146 205.5 16V20C205.5 23.7854 205.499 26.5741 205.212 28.7116C204.926 30.8346 204.366 32.2401 203.303 33.3033C202.24 34.3665 200.835 34.9261 198.712 35.2116C196.574 35.4989 193.785 35.5 190 35.5H16C12.2146 35.5 9.4259 35.4989 7.28845 35.2116C5.16538 34.9261 3.75989 34.3665 2.6967 33.3033C1.63351 32.2401 1.07387 30.8346 0.788435 28.7116C0.501062 26.5741 0.5 23.7854 0.5 20V16Z" fill="#F8F8FA" stroke="#E6E6E6" />
              <path d="M9.6906 18.35C9.9906 22.6417 13.6323 26.1333 17.9906 26.325C21.0656 26.4583 23.8156 25.025 25.4656 22.7667C26.1489 21.8417 25.7823 21.225 24.6406 21.4333C24.0823 21.5333 23.5073 21.575 22.9073 21.55C18.8323 21.3833 15.4989 17.975 15.4823 13.95C15.4739 12.8667 15.6989 11.8417 16.1073 10.9083C16.5573 9.875 16.0156 9.38333 14.9739 9.825C11.6739 11.2167 9.4156 14.5417 9.6906 18.35Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <rect x="0.625" y="0.625" width="34.75" height="34.75" rx="7.375" stroke="#E6E6E6" stroke-width="1.25" />
            <defs>
              <clipPath id="clip0_54_7545">
                <rect width="36" height="36" rx="8" fill="white" />
              </clipPath>
            </defs>
          </svg>

        </button>   

        <div>
          <button onClick={handleClickListItem} 
          style={{
            padding:"4.5px",
            background:"#F8F8FA",
            border:"2px solid #E6E6E6",
            borderRadius:"6px"
          }}
          ><AccessTimeIcon style={{color:"#808080"}}/></button>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={timeOpen}
            onClose={handleCloseTime}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            <MenuItem

            >

              {
                currentTimes.map((item,index) => {
                  return (
                    <div key={index}>
                      <div className={`menu-item flex items-center gap-3 p-2 rounded-sm border-b-2`}>
                        {item.timeZone}: <br /> {item.localTime}
                      </div>
                      <div className={`menu-item flex items-center gap-3 p-2 rounded-sm`}>
                        UTC: <br /> {item.utcTime}
                      </div>
                    </div>
                  )
                })
              }

            </MenuItem>

          </Menu>
        </div>
       
      </div>

    </div>
  );
};

export default Navbar;
