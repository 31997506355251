import React, { useState, useRef, useEffect, useContext } from 'react'
import 'react-circular-progressbar/dist/styles.css';
import './driverProgression.css'
import { Flat, Heat, Nested } from '@alptugidin/react-circular-progress-bar'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../App';
import { timezoneName } from '../DashboarNavbar/DashboardNavbar';
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContext } from '../../../LoaderProvider';
import { Data } from '@react-google-maps/api';



function DriverProgression({ paramsId }) {
    const user = useContext(UserContext);
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)
    const [weeklyData, setWeeklyData] = useState([]);
    const [progressData, setProgressData] = useState({
        hos_complience: {
            remaining_drive_time: "00:00",
            remaining_on_duty_time: "00:00",
            sleeper_berth_time: "08:00",
            weekly_on_duty_limit: "-42:59",
            violations: [
                "Exceeded 11-hour driving limit.",
                "Exceeded 14-hour on-duty limit.",
                "No 30-minute break after 8 hours of driving.",
                "Exceeded 41.0-hour on-duty limit in rolling 7 days."
            ],
            total_milage: 0
        },
    })
    const [driver, setDriver] = useState([])
    const accessToken = localStorage.getItem('accessToken');
    const [selectedDate, setSelectedDate] = useState(null);
    const { isLoading, setIsLoading } = useContext(LoaderContext);



    function trimActivities(list) {
        if (list.length === 0) {
            return list;
        }
        let first = list[0];

        if (first.startTime + first.duration > 24) {
            list[0] = { ...first, startTime: 0, duration: first.startTime + first.duration - 24 };
        }
        if (list.length < 2) {
            return list;
        }
        let last = list[list.length - 1];

        if (last.startTime + last.duration > 24) {
            list[list.length - 1] = { ...last, endTime: 24, duration: 24 - last.startTime };
        }
        return list;
    }

    const zones = {
        Eastern: -5,
        Central: -6,
        Mountain: -7,
        Pacific: -8,
    };

    function timeToHour(time) {
        if (!time.includes(':')) {
            return 0;
        }
        const mins = time.split(':');
        if (mins.length === 2) {
            return (parseInt(mins[0]) || 0) + (parseInt(mins[1]) || 0) / 60;
        } else {
            return (parseInt(mins[0]) || 0) + (parseInt(mins[1]) || 0) / 60 + (parseInt(mins[mins.length - 1]) || 0) / 3600;
        }
    }


    const fetchData = async () => {
        setIsLoading(true)
        try {

            let response = await axios.get(`https://xertzdigital.net:8001/api/v1/main/logs/driver/${paramsId}/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`, // Tokenni yuborish
                },
            });
            console.log("progress: ", response);


            setProgressData(response.data)
            setDriver(response.data.driver_data)


            const activities = response.data.activities;



            for (let i = 0; i < activities.length; i++) {



                if (activities[i].activities.length > 0) {

                    activities[i].activities = trimActivities(activities[i].activities);
                    for (let j = 0; j < activities[i].activities.length; j++) {

                        let ac = activities[i].activities[j];


                        ac.step = ac.activity == 'sb' ? 3 : ac.activity == 'dr' ? 2 : ac.activity == 'on' ? 1 : ac.activity == 'off' ? 4 : ac.activity == 'intermidiate' ? 2 : 0;

                        ac.startTimeDouble = timeToHour(ac.start_time == undefined ? "00:00:00" : ac.start_time);



                        let dd = timeToHour(ac.duration ?? "00:00:00")
                        ac.durtiondouble = dd == 0 ? 24 - ac.startTimeDouble : dd

                        ac.endTime = ac.start_time == undefined ? "24:00:00" : ac.start_time;

                        activities[i].activities[j] = ac;


                    }
                }
            }
            if (activities[0].activities.length > 0) {

                const first = activities[0].activities[activities[0].activities.length - 1];

                if (first.endTime === 0) {

                    const now = new Date();

                    const nowzone = now.getTimezoneOffset() / -60;

                    const f = (zones[timezoneName] || 0) - nowzone;

                    const zoneHour = now.getHours() + now.getMinutes() / 60; //+ f;

                    activities[0].activities[activities[0].activities.length - 1] = {
                        ...first,
                        duration: zoneHour - first.startTimeDouble,
                        endTime: zoneHour,
                    };

                }
            }


            setWeeklyData(activities);
        }

        catch (err) {
            setError(err.message || 'Xato yuz berdi!');
        } finally {
            setIsLoading(false)
            setLoading(false);
        }
    };

    useEffect(() => {



        if (accessToken) {
            fetchData();
        } else {
            setError('AccessToken topilmadi!');
            setLoading(false);
        }
    }, [accessToken, paramsId]);


    const ProgressBars = () => {

        const timeStringToDecimal = (timeString) => {
            const [hours, minutes] = timeString.split(':').map(Number);
            return hours + minutes / 60;
        };





        if (!progressData || !progressData.remaining_drive_time) {


            const remainingDriveTime = timeStringToDecimal(progressData.hos_complience.remaining_drive_time);
            const remainingOnDutyTime = timeStringToDecimal(progressData.hos_complience.remaining_on_duty_time);
            const sleeperBerthTime = timeStringToDecimal(progressData.hos_complience.sleeper_berth_time);
            const weeklyOnDutyLimit = timeStringToDecimal(progressData.hos_complience.weekly_on_duty_limit);


            return (
                <div className="flex items-center gap-3">

                    <div className="w-[80px]">
                        <Flat
                            progress={sleeperBerthTime}
                            range={{ from: 0, to: 24 }}
                            sign={{ value: '06:00', position: 'start' }}
                            showValue={false}
                            text={progressData.hos_complience.sleeper_berth_time}

                            sx={{
                                strokeColor: '#FFA800',
                                bgStrokeColor: '#FFEECE',
                                barWidth: 8,
                                strokeLinecap: 10,
                                textSize: 25,
                                bgColor: { value: '#fefbfb', transparency: '10' },
                                textColor: "#FFA800",
                                valueFamily: 'Lucida Grande',
                                miniCircleSize: 0,
                                valueAnimation: true
                            }}
                        />
                    </div>
                    <div className="w-[80px]">
                        <Flat
                            progress={remainingDriveTime}
                            range={{ from: 0, to: 24 }}
                            sign={{ value: '06:00', position: 'start' }}
                            showValue={false}
                            text={progressData.hos_complience.remaining_drive_time}

                            sx={{
                                strokeColor: '#5CB800',
                                bgStrokeColor: '#E2F0ED',
                                barWidth: 8,
                                strokeLinecap: 10,
                                textSize: 25,
                                bgColor: { value: '#fefbfb', transparency: '10' },
                                textColor: "#5CB800",
                                valueFamily: 'Lucida Grande',
                                miniCircleSize: 0,
                                valueAnimation: true
                            }}
                        />
                    </div>
                    <div className="w-[80px]">
                        <Flat
                            progress={remainingOnDutyTime}
                            range={{ from: 0, to: 24 }}
                            sign={{ value: '06:00', position: 'start' }}
                            showValue={false}
                            text={progressData.hos_complience.remaining_on_duty_time}

                            sx={{
                                strokeColor: '#06B6D4',
                                bgStrokeColor: '#DCFAFF',
                                barWidth: 8,
                                strokeLinecap: 10,
                                textSize: 25,
                                bgColor: { value: '#fefbfb', transparency: '10' },
                                textColor: "#06B6D4",
                                valueFamily: 'Lucida Grande',
                                miniCircleSize: 0,
                                valueAnimation: true
                            }}
                        />
                    </div>
                    <div className="w-[80px]">
                        <Flat
                            progress={weeklyOnDutyLimit}
                            range={{ from: 0, to: 24 }}
                            sign={{ value: '06:00', position: 'start' }}
                            showValue={false}
                            text={progressData.hos_complience.weekly_on_duty_limit}

                            sx={{
                                strokeColor: '#ED3237',
                                bgStrokeColor: '#FFE2E3',
                                barWidth: 8,
                                strokeLinecap: 10,
                                textSize: 25,
                                bgColor: { value: '#fefbfb', transparency: '10' },
                                textColor: "#ED3237",
                                valueFamily: 'Lucida Grande',
                                miniCircleSize: 0,
                                valueAnimation: true
                            }}
                        />
                    </div>
                </div>
            );

        }


    };

    const DriverInfo = () => {
        return (
            <div className="w-full flex items-center justify-between border-b-2 pb-3 mb-3">
                <div className='flex items-center gap-5'>
                    <div className='border-r-2 pr-4'>
                        <p className='text-xl font-semibold'>
                            <small className='text-gray-400 text-xs'>Driver:</small> <br />
                            {driver.first_name}
                        </p>
                        <p className='text-xl font-semibold'>
                            <small className='text-gray-400 text-xs'>Phone number:</small> <br />
                            {driver.phone}
                        </p>
                    </div>
                    <div className=''>
                        <p className='text-xl font-semibold'>
                            <small className='text-gray-400 text-xs'>Driver ID:</small> <br />
                            {driver.driver_id}
                        </p>
                        <p className='text-xl font-semibold'>
                            <small className='text-gray-400 text-xs'>Vehicle ID:</small> <br />
                            {driver.vehicle_id}
                        </p>
                    </div>
                </div>



                <div>
                    <ProgressBars />
                </div>

                <div className='flex items-center gap-5'>
                    <div className='border-r-2 pr-4'>
                        <p className='text-xl font-semibold'>
                            <small className='text-gray-400 text-xs'>Worked hours:</small> <br />
                            {driver.worked_hours}
                        </p>
                        <p className='text-xl font-semibold'>
                            <small className='text-gray-400 text-xs'>Total miles:</small> <br />
                            {progressData.hos_complience.total_milage}
                        </p>
                    </div>
                    <div className=''>
                        <small className='text-gray-400 text-xs'>Violation</small> <br />
                        {progressData.hos_complience.violations.map((item) => (

                            <p className='text-[14px] font-semibold'>
                                {item}
                            </p>
                        ))}
                        <p className='text-xl font-semibold text-green-500'>
                            <small className='text-gray-400 text-xs'>ELD connection</small> <br />
                            {progressData.hos_complience.eld_connection}
                        </p>
                    </div>
                </div>
            </div>
        );
    };


    const filterDataByDate = (selectedDate) => {
        if (!selectedDate) return weeklyData;

        // Kirilgan sanani haftalik ma'lumotlar bilan taqqoslaymiz
        const filteredData = weeklyData.filter(item => item.date === selectedDate);

        return filteredData;
    };

    const Header = () => {
        const [telematics, setTelematics] = useState(true);
        return (
            <div className=''>
                <div className="w-full flex items-center justify-between">

                    <div className='flex items-center gap-2'>
                        {/* <button className='pr-3 pl-3 font-semibold rounded-md' style={{ background: "#E2F0ED", color: '#5CB800' }}>DR</button>
                        <button className=' p-1 font-semibold rounded-md' style={{ background: "#E2F0ED" }}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_40_16496)">
                                <path d="M12.0874 20C12.7561 20 13.3393 19.4168 13.3393 18.7481V15.8244H14.1752C14.6768 15.8244 15.0111 15.49 15.0111 14.9885V6.63818H4.98828V14.9886C4.98828 15.4901 5.32265 15.8245 5.82418 15.8245H6.66015V18.7482C6.66015 19.4169 7.24316 20 7.91202 20C8.58065 20 9.16377 19.4169 9.16377 18.7482V15.8245H10.8356V18.7482C10.8355 19.4168 11.4187 20 12.0874 20Z" fill="#5CB800" />
                                <path d="M17.099 14.9886C17.7677 14.9886 18.3508 14.4055 18.3508 13.7367V7.88951C18.3508 7.22431 17.7678 6.63818 17.099 6.63818C16.4303 6.63818 15.8472 7.22435 15.8472 7.88951V13.7367C15.8472 14.4055 16.4302 14.9886 17.099 14.9886Z" fill="#5CB800" />
                                <path d="M2.90068 14.9886C3.56947 14.9886 4.15255 14.4055 4.15255 13.7367V7.88951C4.15255 7.22431 3.56954 6.63818 2.90068 6.63818C2.23197 6.63818 1.64893 7.22435 1.64893 7.88951V13.7367C1.64896 14.4055 2.23197 14.9886 2.90068 14.9886Z" fill="#5CB800" />
                                <path d="M14.0079 0.126123C13.8407 -0.0420409 13.592 -0.0420409 13.4249 0.126123L12.3037 1.2435L12.252 1.29514C11.5872 0.962252 10.8419 0.796003 10.0119 0.794401C10.0078 0.794401 10.0038 0.794284 9.99975 0.794284H9.99963C9.99541 0.794284 9.99154 0.794401 9.98736 0.794401C9.15741 0.796003 8.4121 0.962252 7.74737 1.29514L7.69554 1.2435L6.57441 0.126123C6.40718 -0.0420409 6.15851 -0.0420409 5.9914 0.126123C5.82418 0.293348 5.82418 0.541551 5.9914 0.708659L7.07589 1.79334C6.72644 2.02666 6.40956 2.31217 6.13402 2.63873C5.47433 3.42068 5.05352 4.43837 4.99566 5.54102C4.99512 5.55251 4.99398 5.56384 4.99348 5.57532C4.98992 5.6504 4.98828 5.72587 4.98828 5.80165H15.011C15.011 5.72591 15.0092 5.65044 15.0058 5.57532C15.0053 5.56384 15.0041 5.55251 15.0035 5.54102C14.9457 4.43837 14.5248 3.42064 13.8651 2.63881C13.5897 2.31225 13.2727 2.02674 12.9233 1.79342L14.0079 0.708737C14.1751 0.541551 14.1751 0.293388 14.0079 0.126123ZM7.91038 4.34216C7.56456 4.34216 7.28417 4.06181 7.28417 3.71595C7.28417 3.37009 7.56452 3.08974 7.91038 3.08974C8.2562 3.08974 8.53659 3.37009 8.53659 3.71595C8.53659 4.06181 8.2562 4.34216 7.91038 4.34216ZM12.0889 4.34216C11.7431 4.34216 11.4627 4.06181 11.4627 3.71595C11.4627 3.37009 11.743 3.08974 12.0889 3.08974C12.4347 3.08974 12.7151 3.37009 12.7151 3.71595C12.7151 4.06181 12.4347 4.34216 12.0889 4.34216Z" fill="#5CB800" />
                            </g>
                            <defs>
                                <clipPath id="clip0_40_16496">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        </button>
    
                        <button
                            className='pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none'
                            style={{ background: "#E8F3FF", color: "#1996F0" }}
                        > <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.6668 5.83329V14.1666C16.6668 17.5 15.8335 18.3333 12.5002 18.3333H7.50016C4.16683 18.3333 3.3335 17.5 3.3335 14.1666V5.83329C3.3335 2.49996 4.16683 1.66663 7.50016 1.66663H12.5002C15.8335 1.66663 16.6668 2.49996 16.6668 5.83329Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.6668 4.58337H8.3335" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10.0002 15.9167C10.7135 15.9167 11.2918 15.3384 11.2918 14.625C11.2918 13.9117 10.7135 13.3334 10.0002 13.3334C9.2868 13.3334 8.7085 13.9117 8.7085 14.625C8.7085 15.3384 9.2868 15.9167 10.0002 15.9167Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p>Device Info</p>
                        </button>
                        <button
                            className='pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none'
                            style={{ background: "#E8F3FF", color: "#1996F0" }}
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 18.3333V16.6666" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 15V13.3334" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 11.6667V9.16671C10 5.94171 12.6083 3.33337 15.8333 3.33337H18.3333" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1.66699 3.33337H4.16699C7.39199 3.33337 10.0003 5.94171 10.0003 9.16671V10V11.6667" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3.33366 1.66663L1.66699 3.33329L3.33366 4.99996" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.667 1.66663L18.3337 3.33329L16.667 4.99996" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
    
                            <p>Timing</p>
                        </button> */}

                        <Link
                            to={`trippanel`}
                            className='pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none'
                            style={{ background: "#E8F3FF", color: "#1996F0" }}
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.9082 6.48335V14.5917C1.9082 16.175 3.0332 16.825 4.39987 16.0417L6.3582 14.925C6.7832 14.6834 7.49154 14.6584 7.9332 14.8834L12.3082 17.075C12.7499 17.2917 13.4582 17.275 13.8832 17.0334L17.4915 14.9667C17.9499 14.7 18.3332 14.05 18.3332 13.5167V5.40835C18.3332 3.82502 17.2082 3.17502 15.8415 3.95835L13.8832 5.07502C13.4582 5.31669 12.7499 5.34169 12.3082 5.11669L7.9332 2.93335C7.49154 2.71669 6.7832 2.73335 6.3582 2.97502L2.74987 5.04169C2.2832 5.30835 1.9082 5.95835 1.9082 6.48335Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.13379 3.33337V14.1667" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.1084 5.51672V16.6667" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <p>Trip Planner</p>
                        </Link>

                        <Link
                            to={`telematics`}
                            className='pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none'
                            style={{ background: telematics ? '#E8F3FF' : '#FFE2E3', color: telematics ? '#1996F0' : '#ED3237' }}
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_275_10378)">
                                    <path d="M2.81309 20C2.06184 20 1.35817 19.7088 0.824699 19.1753C0.274036 18.6246 -0.0185419 17.8925 0.000911221 17.114C0.0203643 16.3355 0.349114 15.619 0.926612 15.0965L9.77949 7.08671C9.07972 5.17624 9.54609 2.99558 11.0021 1.53965C12.326 0.215744 14.2765 -0.306365 16.0924 0.177501C16.2948 0.231408 16.4531 0.389298 16.5074 0.591641C16.5618 0.793946 16.504 1.00992 16.3559 1.15805L14.2624 3.25152L14.6767 5.32327L16.7485 5.73761L18.842 3.64414C18.9901 3.49597 19.2059 3.43816 19.4084 3.49257C19.6107 3.54695 19.7686 3.70519 19.8226 3.90765C20.3063 5.72347 19.7843 7.67397 18.4604 8.99791C17.0044 10.454 14.8238 10.9202 12.9133 10.2205L4.90356 19.0734C4.38106 19.6509 3.66458 19.9797 2.88602 19.9991C2.86169 19.9997 2.83735 20 2.81309 20ZM14.6845 1.17199C13.6258 1.18465 12.5942 1.60496 11.8308 2.36836C10.6175 3.58171 10.2876 5.44202 11.0101 6.99757C11.1183 7.23046 11.0622 7.50663 10.8718 7.67893L1.7129 15.9655C1.37579 16.2705 1.18388 16.6888 1.17251 17.1433C1.16114 17.5978 1.33192 18.0251 1.65341 18.3466C1.97489 18.6681 2.40216 18.84 2.85673 18.8275C3.31122 18.8161 3.7295 18.6242 4.0345 18.2871L12.3211 9.12819C12.4934 8.9378 12.7695 8.88174 13.0024 8.98987C14.5579 9.7124 16.4182 9.38252 17.6316 8.1692C18.3949 7.40589 18.8152 6.37405 18.828 5.31542L17.3554 6.78807C17.2169 6.92663 17.0183 6.98675 16.8262 6.94835L14.0638 6.39589C13.8319 6.34948 13.6505 6.16819 13.6042 5.9362L13.0517 3.17386C13.0133 2.98175 13.0734 2.78312 13.2119 2.64461L14.6845 1.17199Z" fill="#1996F0" />
                                    <path d="M2.89648 17.6891C3.22009 17.6891 3.48242 17.4268 3.48242 17.1031C3.48242 16.7795 3.22009 16.5172 2.89648 16.5172C2.57288 16.5172 2.31055 16.7795 2.31055 17.1031C2.31055 17.4268 2.57288 17.6891 2.89648 17.6891Z" fill="#1996F0" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_275_10378">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <p>Telematics</p>
                        </Link>


                    </div>

                    <div className='flex items-center gap-3'>                                             
                     

                        <div className='p-1 flex items-center gap-1 rounded-lg' style={{ background: "#F8F8FA" }}>
                            <div className='flex items-center gap-1'>
                                <input
                                    type="date"
                                    className='w-[90px] font-semibold text-gray-500 bg-transparent focus:outline-none'
                                // onChange={(e) => setSelectedDate(e.target.value)}
                                /> <span className='text-gray-500'>/</span>
                                <input
                                    type="date"
                                    className=' w-[100px] font-semibold text-gray-500 bg-transparent focus:outline-none'
                                // onChange={(e) => setSelectedDate(e.target.value)}
                                />
                            </div> <span style={{color:"#E6E6E6"}}>|</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.66699 1.66663V4.16663" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.333 1.66663V4.16663" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.91699 7.57495H17.0837" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.5 7.08329V14.1666C17.5 16.6666 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6666 2.5 14.1666V7.08329C2.5 4.58329 3.75 2.91663 6.66667 2.91663H13.3333C16.25 2.91663 17.5 4.58329 17.5 7.08329Z" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.0791 11.4167H13.0866" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.0791 13.9167H13.0866" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.99607 11.4167H10.0036" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.99607 13.9167H10.0036" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.91209 11.4167H6.91957" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.91209 13.9167H6.91957" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>


                        <div className='flex items-center gap- 3 p-2 rounded-md' style={{ background: '#E8F3FF', color: '#1996F0' }}>
                            <button
                                className=' pr-1 pl-1   border-none'

                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.4995 16.6L7.06621 11.1667C6.42454 10.525 6.42454 9.47503 7.06621 8.83336L12.4995 3.40002" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </button>
                            <button
                                className=' pr-1 pl-1  border-none'

                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.50046 3.39997L12.9338 8.83331C13.5755 9.47498 13.5755 10.525 12.9338 11.1666L7.50046 16.6" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </button>
                        </div>

                        <button
                            className='pl-4 pr-4 p-1 rounded-md flex items-center justify-between gap-2 border-none'
                            style={{ background: "#E8F3FF", color: "#1996F0" }}
                        >

                            <span>Current week</span>
                        </button>

                        <button onClick={() => setSelectedDate(false)}>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.1298 11.3199L18.7598 13.7799" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </button>
                    </div>
                </div>

                <DriverInfo />

            </div>
        );
    };


    // const ActivityComponent = ({ id, timezoneName, zones }) => {
    //   const [data, setData] = useState([]);
    //   const [isLoading, setIsLoading] = useState(true);

    //   useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         const result = await axios.get(`https://xertzdigital.net:8001/api/v1/main/driver/65/activities/`);
    //         setIsLoading(false);
    //         if (result.status === 200 && Array.isArray(result.data)) {
    //           const fetchedData = result.data.map((x) => transformLogModel(x));
    //           fetchedData.reverse();
    //           const firstLog = fetchedData[0];
    //           if (firstLog.activities.length > 0) {
    //             const lastActivity = firstLog.activities[firstLog.activities.length - 1];
    //             if (lastActivity.endTime === 0) {
    //               const now = new Date();
    //               const nowzone = now.getTimezoneOffset() / 60;
    //               const f = (zones[timezoneName] ?? 0) - nowzone;
    //               const zoneHour = now.getHours() + now.getMinutes() / 60; // + f;
    //               firstLog.activities[firstLog.activities.length - 1] = {
    //                 ...lastActivity,
    //                 duration: zoneHour - lastActivity.startTime,
    //                 endTime: zoneHour,
    //               };
    //             }
    //           }
    //           setData(fetchedData);
    //         }
    //       } catch (error) {
    //         console.error('Error fetching data:', error);
    //         setIsLoading(false);
    //       }
    //     };

    //     fetchData();
    //   }, [id, timezoneName, zones]);

    //   const transformLogModel = (json) => {
    //     const activities = trimActivities(json.activities);
    //     return {
    //       date: json.date,
    //       signatureDate: json.signature_date || null,
    //       activities,
    //     };
    //   };

    //   const trimActivities = (activities) => {
    //     if (activities.length === 0) return [];
    //     const transformed = activities.map((x) => transformActivityModel(x));
    //     const first = transformed[0];
    //     if (first.startTime + first.duration > 24) {
    //       transformed[0] = {
    //         ...first,
    //         startTime: 0,
    //         duration: first.startTime + first.duration - 24,
    //       };
    //     }
    //     if (transformed.length < 2) return transformed;
    //     const last = transformed[transformed.length - 1];
    //     if (last.startTime + last.duration > 24) {
    //       transformed[transformed.length - 1] = {
    //         ...last,
    //         endTime: 24,
    //         duration: 24 - last.startTime,
    //       };
    //     }
    //     return transformed;
    //   };

    //   const transformActivityModel = (json) => {
    //     return {
    //       step: stepData[json.activity] || 0,
    //       startTime: timeToHour(json.start_time),
    //       endTime: json.end_time ? timeToHour(json.end_time) : 0,
    //       duration: json.duration ? timeToHour(json.duration) : 0,
    //       location: json.location || '',
    //       unitNumber: json.unit_number || '',
    //       odometer: json.odometer || '',
    //       engineHour: json.engine_hour || '',
    //       note: json.note || '',
    //       trailer: json.trailer || '',
    //       document: json.document || '',
    //     };
    //   };
    //   const timeToHour = (time) => {
    //     if (!time.includes(':')) {
    //       return 0;
    //     }

    //     const mins = time.split(':');
    //     if (mins.length === 2) {
    //       return (
    //         (parseInt(mins[0], 10) || 0) +
    //         (parseInt(mins[1], 10) || 0) / 60
    //       );
    //     } else {
    //       return (
    //         (parseInt(mins[0], 10) || 0) +
    //         (parseInt(mins[1], 10) || 0) / 60 +
    //         (parseInt(mins[2], 10) || 0) / 3600
    //       );
    //     }
    //   };


    //   const timeToHour = (timeString) => {
    //     const [hours, minutes] = timeString.split(':').map(Number);
    //     return hours + minutes / 60;
    //   };

    //   if (isLoading) {
    //     return <div>Loading...</div>;
    //   }

    //   return (
    //     <div>
    //       {data.map((log, index) => (
    //         <div key={index}>
    //           <h3>Date: {log.date}</h3>
    //           {log.activities.map((activity, idx) => (
    //             <div key={idx}>
    //               <p>Step: {activity.step}</p>
    //               <p>Start Time: {activity.startTime}</p>
    //               <p>End Time: {activity.endTime}</p>
    //               <p>Duration: {activity.duration}</p>
    //               <p>Location: {activity.location}</p>
    //             </div>
    //           ))}
    //         </div>
    //       ))}
    //     </div>
    //   );
    // };



    // Theme object (simulating Flutter's theme)


    const theme = {
        textStyle: {
            fontWeight: 700,
            color: "#888", // Grey color
        },
        grey: "#888",
        line: "#ccc", // Line color
    };

    const ChartBox = () => {
        return (
            <div style={{ paddingRight: "15px" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    {/* Header Row */}
                    <div style={{ height: "30px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                        {/* Left Column */}
                        <div style={{ width: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
                            <span style={{ fontWeight: theme.textStyle.fontWeight, color: theme.grey }}>M</span>
                            <div style={{ backgroundColor: theme.line, width: "1px", height: "10px" }}></div>
                        </div>

                        {/* Center Columns */}
                        {Array.from({ length: 23 }, (_, i) => (
                            <div
                                key={i}
                                style={{ width: "40px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}
                            >
                                <span style={{ fontWeight: theme.textStyle.fontWeight, color: theme.grey }}>{i + 1}</span>
                                <div style={{ backgroundColor: theme.line, width: "1px", height: "8px" }}></div>
                            </div>
                        ))}

                        {/* Right Column */}
                        <div style={{ width: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>
                            <span style={{ fontWeight: theme.textStyle.fontWeight, color: theme.grey }}>M</span>
                            <div style={{ backgroundColor: theme.line, width: "1px", height: "10px" }}></div>
                        </div>
                    </div>

                    {/* Divider */}
                    <div style={{ backgroundColor: theme.line, width: "960px", height: "1px", marginTop: "10px" }}></div>

                    {/* Chart Rows */}
                    {Array.from({ length: 4 }, (_, i) => (
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            {/* Left Border */}
                            <div style={{ backgroundColor: theme.line, width: "0.5px", height: "30px" }}></div>
                            {/* Line Boxes */}
                            {Array.from({ length: 24 }, (_, j) => (
                                <ChartLineBox key={j} />
                            ))}
                            {/* Right Border */}
                            <div style={{ backgroundColor: theme.line, width: "0.5px", height: "30px" }}></div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const ChartLineBox = () => {
        return (
            <div style={{ width: "40px", height: "30px", display: "flex", flexDirection: "column" }}>
                <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <div style={{ backgroundColor: theme.line, width: "0.5px", height: "30px" }}></div>
                    <div style={{ backgroundColor: theme.line, width: "1px", height: "7.5px" }}></div>
                    <div style={{ backgroundColor: theme.line, width: "1px", height: "7.5px" }}></div>
                    <div style={{ backgroundColor: theme.line, width: "1px", height: "15px" }}></div>
                    <div style={{ backgroundColor: theme.line, width: "1px", height: "7.5px" }}></div>
                    <div style={{ backgroundColor: theme.line, width: "1px", height: "7.5px" }}></div>
                    <div style={{ backgroundColor: theme.line, width: "0.5px", height: "30px" }}></div>
                </div>
                <div style={{ backgroundColor: theme.line, width: "40px", height: "1px" }}></div>
            </div>
        );
    };




    function Diogramma() {
        const [hideStates, setHideStates] = useState(weeklyData.map(() => false));

        const copyToClipboard = (text) => {
            navigator.clipboard.writeText(text)
        };

        const toggleHide = (index) => {
            setHideStates((prevState) => {
                const newState = [...prevState];
                newState[index] = !newState[index];
                return newState;
            });
        };

        const filteredData = filterDataByDate(selectedDate);


        return (
            <div>
                {
                    filteredData.map((item, index) => {





                        return (
                            <div className='p-2 w-[1230px] border-2 border-gray-200 rounded-md'>

                                <div className='diogram_desc flex items-center justify-between'>
                                    <div className='flex items-center gap-5'>
                                        <p className='text-xl font-semibold'>
                                            <small className='text-gray-400 text-base'>Data:</small> <br />
                                            10.18.2024
                                        </p>
                                        <p className='text-xl font-semibold text-green-500'>
                                            <small className='text-gray-400 text-base'>Status:</small> <br />
                                            Certified
                                        </p>

                                        <p className='text-xl font-semibold'>
                                            <small className='text-gray-400 text-base'>Co-driver:</small> <br />
                                            Murodillo Ismailov
                                        </p>
                                    </div>

                                    <div className='flex items-center gap-3'>
                                        <button
                                            className='flex items-center gap-3 p-1 pl-2 pr-2 rounded-lg font-semibold'
                                            style={{ background: '#E8F3FF', color: "#1996F0" }}>

                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.99967 9.99996C12.3009 9.99996 14.1663 8.13448 14.1663 5.83329C14.1663 3.53211 12.3009 1.66663 9.99967 1.66663C7.69849 1.66663 5.83301 3.53211 5.83301 5.83329C5.83301 8.13448 7.69849 9.99996 9.99967 9.99996Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M2.8418 18.3333C2.8418 15.1083 6.05013 12.5 10.0001 12.5C10.8001 12.5 11.5751 12.6083 12.3001 12.8083" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M18.3337 15C18.3337 15.2666 18.3003 15.525 18.2337 15.775C18.1587 16.1083 18.0253 16.4333 17.8503 16.7166C17.2753 17.6833 16.217 18.3333 15.0003 18.3333C14.142 18.3333 13.367 18.0083 12.7837 17.4749C12.5337 17.2583 12.317 17 12.1503 16.7166C11.842 16.2166 11.667 15.625 11.667 15C11.667 14.1 12.0253 13.275 12.6087 12.675C13.217 12.05 14.067 11.6666 15.0003 11.6666C15.9837 11.6666 16.8753 12.0916 17.4753 12.775C18.0087 13.3666 18.3337 14.15 18.3337 15Z" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.2421 14.9834H13.7588" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15 13.7667V16.2584" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>


                                            <p>Add co-driver</p>
                                        </button>
                                        <button onClick={fetchData}>
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                                <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M22.1298 11.3199L18.7598 13.7799" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </button>
                                        <button onClick={() => toggleHide(index)}>
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                                <path d="M24.6004 15.5L19.1671 20.9333C18.5254 21.575 17.4754 21.575 16.8337 20.9333L11.4004 15.5" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </button>
                                    </div>
                                </div>

                                <div className='w-[1200px] m-auto'>
                                    <div className='w-[95%] m-auto'>
                                        <svg width="100%" height="19" viewBox="0 0 1502 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.2436 14H10.9556V6.528L7.94756 14H6.95556L3.94756 6.528V14H1.67556V3.328H4.85956L7.45156 9.776L10.0436 3.328H13.2436V14Z" fill="#808080" />
                                            <path d="M64.424 14H62.152V6.256L60.376 8.064L59.08 6.704L62.44 3.328H64.424V14Z" fill="#808080" />
                                            <path d="M129.25 14H121.218V12.224C123.5 10.528 125.01 9.32267 125.746 8.608C126.492 7.88267 126.866 7.20533 126.866 6.576C126.866 6.13867 126.695 5.79733 126.354 5.552C126.012 5.30667 125.607 5.184 125.138 5.184C124.018 5.184 123.063 5.59467 122.274 6.416L120.978 4.912C121.479 4.336 122.092 3.904 122.818 3.616C123.554 3.31733 124.311 3.168 125.09 3.168C126.263 3.168 127.234 3.47733 128.002 4.096C128.78 4.71467 129.17 5.54133 129.17 6.576C129.17 7.45067 128.823 8.30933 128.13 9.152C127.447 9.984 126.37 10.9333 124.898 12H129.25V14Z" fill="#808080" />
                                            <path d="M187.644 14.192C186.705 14.192 185.873 14.0373 185.148 13.728C184.422 13.408 183.852 12.992 183.436 12.48L184.62 10.96C184.982 11.3333 185.43 11.632 185.964 11.856C186.497 12.0693 187.03 12.176 187.564 12.176C188.204 12.176 188.7 12.0533 189.052 11.808C189.404 11.552 189.58 11.2213 189.58 10.816C189.58 10.3893 189.409 10.08 189.068 9.888C188.726 9.68533 188.182 9.584 187.436 9.584C186.764 9.584 186.321 9.59467 186.108 9.616V7.568C186.236 7.57867 186.678 7.584 187.436 7.584C188.748 7.584 189.404 7.20533 189.404 6.448C189.404 6.04267 189.217 5.73333 188.844 5.52C188.481 5.296 188.006 5.184 187.42 5.184C186.385 5.184 185.484 5.552 184.716 6.288L183.58 4.864C184.582 3.73333 185.942 3.168 187.66 3.168C188.908 3.168 189.889 3.42933 190.604 3.952C191.329 4.47467 191.692 5.17867 191.692 6.064C191.692 6.704 191.462 7.24267 191.004 7.68C190.545 8.10667 189.996 8.37867 189.356 8.496C189.985 8.56 190.556 8.82133 191.068 9.28C191.59 9.728 191.852 10.32 191.852 11.056C191.852 11.984 191.468 12.7413 190.7 13.328C189.932 13.904 188.913 14.192 187.644 14.192Z" fill="#808080" />
                                            <path d="M253.361 14H251.089V11.792H245.985V10L250.225 3.328H253.361V9.792H254.737V11.792H253.361V14ZM251.089 9.792V5.344L248.209 9.792H251.089Z" fill="#808080" />
                                            <path d="M312.936 14.192C311.176 14.192 309.806 13.6587 308.824 12.592L310.088 11.008C310.835 11.7867 311.779 12.176 312.92 12.176C313.528 12.176 314.008 12.0267 314.36 11.728C314.723 11.4187 314.904 11.04 314.904 10.592C314.904 10.112 314.734 9.728 314.392 9.44C314.051 9.152 313.582 9.008 312.984 9.008C312.099 9.008 311.352 9.296 310.744 9.872L309.16 9.456V3.328H316.488V5.328H311.432V7.936C312.03 7.33867 312.814 7.04 313.784 7.04C314.734 7.04 315.539 7.35467 316.2 7.984C316.872 8.61333 317.208 9.45067 317.208 10.496C317.208 11.6267 316.819 12.528 316.04 13.2C315.262 13.8613 314.227 14.192 312.936 14.192Z" fill="#808080" />
                                            <path d="M375.74 14.192C374.204 14.192 373.052 13.68 372.284 12.656C371.516 11.632 371.132 10.3093 371.132 8.688C371.132 7.03467 371.575 5.70133 372.46 4.688C373.346 3.67467 374.562 3.168 376.108 3.168C377.346 3.168 378.396 3.536 379.26 4.272L378.236 6.016C377.65 5.46133 376.94 5.184 376.108 5.184C375.33 5.184 374.69 5.47733 374.188 6.064C373.687 6.64 373.436 7.35467 373.436 8.208C373.436 8.304 373.442 8.37867 373.452 8.432C373.719 8.048 374.103 7.72267 374.604 7.456C375.106 7.18933 375.634 7.056 376.188 7.056C377.202 7.056 378.06 7.36533 378.764 7.984C379.468 8.592 379.82 9.44 379.82 10.528C379.82 11.584 379.442 12.4587 378.684 13.152C377.927 13.8453 376.946 14.192 375.74 14.192ZM375.612 12.176C376.188 12.176 376.647 12.016 376.988 11.696C377.34 11.376 377.516 11.0133 377.516 10.608C377.516 10.0853 377.324 9.69067 376.94 9.424C376.556 9.14667 376.087 9.008 375.532 9.008C374.679 9.008 373.986 9.344 373.452 10.016C373.506 10.6133 373.708 11.1253 374.06 11.552C374.412 11.968 374.93 12.176 375.612 12.176Z" fill="#808080" />
                                            <path d="M437.555 14H435.059L438.883 5.328H433.507V3.328H441.523V4.912L437.555 14Z" fill="#808080" />
                                            <path d="M500.549 14.192C499.343 14.192 498.325 13.936 497.493 13.424C496.671 12.912 496.261 12.1813 496.261 11.232C496.261 10.6133 496.474 10.064 496.901 9.584C497.327 9.09333 497.877 8.73067 498.549 8.496C497.141 8.00533 496.437 7.168 496.437 5.984C496.437 5.056 496.842 4.35733 497.653 3.888C498.474 3.408 499.439 3.168 500.549 3.168C501.253 3.168 501.903 3.25867 502.501 3.44C503.098 3.62133 503.605 3.936 504.021 4.384C504.447 4.82133 504.661 5.35467 504.661 5.984C504.661 7.15733 503.951 7.99467 502.533 8.496C503.205 8.73067 503.754 9.09333 504.181 9.584C504.618 10.064 504.837 10.6133 504.837 11.232C504.837 12.1707 504.421 12.9013 503.589 13.424C502.757 13.936 501.743 14.192 500.549 14.192ZM500.549 7.6C500.986 7.536 501.391 7.39733 501.765 7.184C502.149 6.97067 502.341 6.688 502.341 6.336C502.341 5.96267 502.175 5.66933 501.845 5.456C501.514 5.232 501.082 5.12 500.549 5.12C500.015 5.12 499.578 5.232 499.237 5.456C498.906 5.66933 498.741 5.96267 498.741 6.336C498.741 6.688 498.933 6.97067 499.317 7.184C499.701 7.39733 500.111 7.536 500.549 7.6ZM500.549 12.24C501.103 12.24 501.573 12.1227 501.957 11.888C502.341 11.6427 502.533 11.328 502.533 10.944C502.533 10.5493 502.314 10.2293 501.877 9.984C501.45 9.73867 501.007 9.584 500.549 9.52C500.09 9.584 499.642 9.73867 499.205 9.984C498.778 10.2293 498.565 10.5493 498.565 10.944C498.565 11.328 498.751 11.6427 499.125 11.888C499.509 12.1227 499.983 12.24 500.549 12.24Z" fill="#808080" />
                                            <path d="M562.441 14.176C561.172 14.176 560.126 13.808 559.305 13.072L560.313 11.328C560.9 11.8827 561.609 12.16 562.441 12.16C563.273 12.16 563.924 11.8667 564.393 11.28C564.873 10.6827 565.113 9.97333 565.113 9.152V8.912C564.836 9.296 564.446 9.62133 563.945 9.888C563.444 10.1547 562.916 10.288 562.361 10.288C561.348 10.288 560.484 9.984 559.769 9.376C559.065 8.75733 558.713 7.904 558.713 6.816C558.713 5.76 559.092 4.88533 559.849 4.192C560.617 3.49867 561.604 3.152 562.809 3.152C563.844 3.152 564.713 3.408 565.417 3.92C566.132 4.432 566.638 5.09333 566.937 5.904C567.246 6.704 567.401 7.62667 567.401 8.672C567.401 10.3147 566.958 11.6427 566.073 12.656C565.198 13.6693 563.988 14.176 562.441 14.176ZM563.001 8.336C563.854 8.336 564.553 8.00533 565.097 7.344C565.044 6.74667 564.841 6.23467 564.489 5.808C564.137 5.38133 563.614 5.168 562.921 5.168C562.345 5.168 561.886 5.328 561.545 5.648C561.204 5.968 561.033 6.33067 561.033 6.736C561.033 7.25867 561.225 7.65867 561.609 7.936C561.993 8.20267 562.457 8.336 563.001 8.336Z" fill="#808080" />
                                            <path d="M622.398 14H620.126V6.256L618.35 8.064L617.054 6.704L620.414 3.328H622.398V14ZM630.896 13.376C630.213 13.92 629.386 14.192 628.416 14.192C627.445 14.192 626.613 13.92 625.92 13.376C625.237 12.8213 624.746 12.1387 624.448 11.328C624.149 10.5173 624 9.632 624 8.672C624 7.712 624.149 6.82667 624.448 6.016C624.746 5.20533 625.237 4.528 625.92 3.984C626.613 3.44 627.445 3.168 628.416 3.168C629.386 3.168 630.213 3.44 630.896 3.984C631.578 4.528 632.069 5.20533 632.368 6.016C632.677 6.82667 632.832 7.712 632.832 8.672C632.832 9.632 632.677 10.5173 632.368 11.328C632.069 12.1387 631.578 12.8213 630.896 13.376ZM626.816 11.216C627.157 11.856 627.69 12.176 628.416 12.176C629.141 12.176 629.669 11.856 630 11.216C630.341 10.576 630.512 9.728 630.512 8.672C630.512 7.616 630.341 6.77333 630 6.144C629.669 5.504 629.141 5.184 628.416 5.184C627.69 5.184 627.157 5.504 626.816 6.144C626.474 6.77333 626.304 7.616 626.304 8.672C626.304 9.728 626.474 10.576 626.816 11.216Z" fill="#808080" />
                                            <path d="M687.139 14H684.867V6.256L683.091 8.064L681.795 6.704L685.155 3.328H687.139V14ZM693.717 14H691.445V6.256L689.669 8.064L688.373 6.704L691.733 3.328H693.717V14Z" fill="#808080" />
                                            <path d="M755.525 14H753.333L748.245 7.04V14H745.973V3.328H748.309L753.253 10.032V3.328H755.525V14Z" fill="#808080" />
                                            <path d="M815.003 14H812.731V6.256L810.955 8.064L809.659 6.704L813.019 3.328H815.003V14Z" fill="#808080" />
                                            <path d="M879.828 14H871.796V12.224C874.079 10.528 875.588 9.32267 876.324 8.608C877.071 7.88267 877.444 7.20533 877.444 6.576C877.444 6.13867 877.274 5.79733 876.932 5.552C876.591 5.30667 876.186 5.184 875.716 5.184C874.596 5.184 873.642 5.59467 872.852 6.416L871.556 4.912C872.058 4.336 872.671 3.904 873.396 3.616C874.132 3.31733 874.89 3.168 875.668 3.168C876.842 3.168 877.812 3.47733 878.58 4.096C879.359 4.71467 879.748 5.54133 879.748 6.576C879.748 7.45067 879.402 8.30933 878.708 9.152C878.026 9.984 876.948 10.9333 875.476 12H879.828V14Z" fill="#808080" />
                                            <path d="M938.223 14.192C937.284 14.192 936.452 14.0373 935.727 13.728C935.001 13.408 934.431 12.992 934.015 12.48L935.199 10.96C935.561 11.3333 936.009 11.632 936.543 11.856C937.076 12.0693 937.609 12.176 938.143 12.176C938.783 12.176 939.279 12.0533 939.631 11.808C939.983 11.552 940.159 11.2213 940.159 10.816C940.159 10.3893 939.988 10.08 939.647 9.888C939.305 9.68533 938.761 9.584 938.015 9.584C937.343 9.584 936.9 9.59467 936.687 9.616V7.568C936.815 7.57867 937.257 7.584 938.015 7.584C939.327 7.584 939.983 7.20533 939.983 6.448C939.983 6.04267 939.796 5.73333 939.423 5.52C939.06 5.296 938.585 5.184 937.999 5.184C936.964 5.184 936.063 5.552 935.295 6.288L934.159 4.864C935.161 3.73333 936.521 3.168 938.239 3.168C939.487 3.168 940.468 3.42933 941.183 3.952C941.908 4.47467 942.271 5.17867 942.271 6.064C942.271 6.704 942.041 7.24267 941.583 7.68C941.124 8.10667 940.575 8.37867 939.935 8.496C940.564 8.56 941.135 8.82133 941.647 9.28C942.169 9.728 942.431 10.32 942.431 11.056C942.431 11.984 942.047 12.7413 941.279 13.328C940.511 13.904 939.492 14.192 938.223 14.192Z" fill="#808080" />
                                            <path d="M1003.94 14H1001.67V11.792H996.565V10L1000.8 3.328H1003.94V9.792H1005.32V11.792H1003.94V14ZM1001.67 9.792V5.344L998.789 9.792H1001.67Z" fill="#808080" />
                                            <path d="M1063.52 14.192C1061.76 14.192 1060.38 13.6587 1059.4 12.592L1060.67 11.008C1061.41 11.7867 1062.36 12.176 1063.5 12.176C1064.11 12.176 1064.59 12.0267 1064.94 11.728C1065.3 11.4187 1065.48 11.04 1065.48 10.592C1065.48 10.112 1065.31 9.728 1064.97 9.44C1064.63 9.152 1064.16 9.008 1063.56 9.008C1062.68 9.008 1061.93 9.296 1061.32 9.872L1059.74 9.456V3.328H1067.07V5.328H1062.01V7.936C1062.61 7.33867 1063.39 7.04 1064.36 7.04C1065.31 7.04 1066.12 7.35467 1066.78 7.984C1067.45 8.61333 1067.79 9.45067 1067.79 10.496C1067.79 11.6267 1067.4 12.528 1066.62 13.2C1065.84 13.8613 1064.81 14.192 1063.52 14.192Z" fill="#808080" />
                                            <path d="M1126.32 14.192C1124.78 14.192 1123.63 13.68 1122.86 12.656C1122.1 11.632 1121.71 10.3093 1121.71 8.688C1121.71 7.03467 1122.15 5.70133 1123.04 4.688C1123.92 3.67467 1125.14 3.168 1126.69 3.168C1127.92 3.168 1128.98 3.536 1129.84 4.272L1128.82 6.016C1128.23 5.46133 1127.52 5.184 1126.69 5.184C1125.91 5.184 1125.27 5.47733 1124.77 6.064C1124.27 6.64 1124.02 7.35467 1124.02 8.208C1124.02 8.304 1124.02 8.37867 1124.03 8.432C1124.3 8.048 1124.68 7.72267 1125.18 7.456C1125.68 7.18933 1126.21 7.056 1126.77 7.056C1127.78 7.056 1128.64 7.36533 1129.34 7.984C1130.05 8.592 1130.4 9.44 1130.4 10.528C1130.4 11.584 1130.02 12.4587 1129.26 13.152C1128.51 13.8453 1127.52 14.192 1126.32 14.192ZM1126.19 12.176C1126.77 12.176 1127.23 12.016 1127.57 11.696C1127.92 11.376 1128.1 11.0133 1128.1 10.608C1128.1 10.0853 1127.9 9.69067 1127.52 9.424C1127.14 9.14667 1126.67 9.008 1126.11 9.008C1125.26 9.008 1124.56 9.344 1124.03 10.016C1124.08 10.6133 1124.29 11.1253 1124.64 11.552C1124.99 11.968 1125.51 12.176 1126.19 12.176Z" fill="#808080" />
                                            <path d="M1188.13 14H1185.64L1189.46 5.328H1184.09V3.328H1192.1V4.912L1188.13 14Z" fill="#808080" />
                                            <path d="M1251.13 14.192C1249.92 14.192 1248.9 13.936 1248.07 13.424C1247.25 12.912 1246.84 12.1813 1246.84 11.232C1246.84 10.6133 1247.05 10.064 1247.48 9.584C1247.91 9.09333 1248.46 8.73067 1249.13 8.496C1247.72 8.00533 1247.02 7.168 1247.02 5.984C1247.02 5.056 1247.42 4.35733 1248.23 3.888C1249.05 3.408 1250.02 3.168 1251.13 3.168C1251.83 3.168 1252.48 3.25867 1253.08 3.44C1253.68 3.62133 1254.18 3.936 1254.6 4.384C1255.03 4.82133 1255.24 5.35467 1255.24 5.984C1255.24 7.15733 1254.53 7.99467 1253.11 8.496C1253.78 8.73067 1254.33 9.09333 1254.76 9.584C1255.2 10.064 1255.42 10.6133 1255.42 11.232C1255.42 12.1707 1255 12.9013 1254.17 13.424C1253.34 13.936 1252.32 14.192 1251.13 14.192ZM1251.13 7.6C1251.57 7.536 1251.97 7.39733 1252.34 7.184C1252.73 6.97067 1252.92 6.688 1252.92 6.336C1252.92 5.96267 1252.75 5.66933 1252.42 5.456C1252.09 5.232 1251.66 5.12 1251.13 5.12C1250.59 5.12 1250.16 5.232 1249.82 5.456C1249.49 5.66933 1249.32 5.96267 1249.32 6.336C1249.32 6.688 1249.51 6.97067 1249.9 7.184C1250.28 7.39733 1250.69 7.536 1251.13 7.6ZM1251.13 12.24C1251.68 12.24 1252.15 12.1227 1252.54 11.888C1252.92 11.6427 1253.11 11.328 1253.11 10.944C1253.11 10.5493 1252.89 10.2293 1252.46 9.984C1252.03 9.73867 1251.59 9.584 1251.13 9.52C1250.67 9.584 1250.22 9.73867 1249.78 9.984C1249.36 10.2293 1249.14 10.5493 1249.14 10.944C1249.14 11.328 1249.33 11.6427 1249.7 11.888C1250.09 12.1227 1250.56 12.24 1251.13 12.24Z" fill="#808080" />
                                            <path d="M1313.02 14.176C1311.75 14.176 1310.71 13.808 1309.88 13.072L1310.89 11.328C1311.48 11.8827 1312.19 12.16 1313.02 12.16C1313.85 12.16 1314.5 11.8667 1314.97 11.28C1315.45 10.6827 1315.69 9.97333 1315.69 9.152V8.912C1315.41 9.296 1315.03 9.62133 1314.52 9.888C1314.02 10.1547 1313.49 10.288 1312.94 10.288C1311.93 10.288 1311.06 9.984 1310.35 9.376C1309.64 8.75733 1309.29 7.904 1309.29 6.816C1309.29 5.76 1309.67 4.88533 1310.43 4.192C1311.2 3.49867 1312.18 3.152 1313.39 3.152C1314.42 3.152 1315.29 3.408 1316 3.92C1316.71 4.432 1317.22 5.09333 1317.52 5.904C1317.83 6.704 1317.98 7.62667 1317.98 8.672C1317.98 10.3147 1317.54 11.6427 1316.65 12.656C1315.78 13.6693 1314.57 14.176 1313.02 14.176ZM1313.58 8.336C1314.43 8.336 1315.13 8.00533 1315.68 7.344C1315.62 6.74667 1315.42 6.23467 1315.07 5.808C1314.72 5.38133 1314.19 5.168 1313.5 5.168C1312.92 5.168 1312.47 5.328 1312.12 5.648C1311.78 5.968 1311.61 6.33067 1311.61 6.736C1311.61 7.25867 1311.8 7.65867 1312.19 7.936C1312.57 8.20267 1313.04 8.336 1313.58 8.336Z" fill="#808080" />
                                            <path d="M1372.98 14H1370.7V6.256L1368.93 8.064L1367.63 6.704L1370.99 3.328H1372.98V14ZM1381.47 13.376C1380.79 13.92 1379.97 14.192 1378.99 14.192C1378.02 14.192 1377.19 13.92 1376.5 13.376C1375.82 12.8213 1375.33 12.1387 1375.03 11.328C1374.73 10.5173 1374.58 9.632 1374.58 8.672C1374.58 7.712 1374.73 6.82667 1375.03 6.016C1375.33 5.20533 1375.82 4.528 1376.5 3.984C1377.19 3.44 1378.02 3.168 1378.99 3.168C1379.97 3.168 1380.79 3.44 1381.47 3.984C1382.16 4.528 1382.65 5.20533 1382.95 6.016C1383.26 6.82667 1383.41 7.712 1383.41 8.672C1383.41 9.632 1383.26 10.5173 1382.95 11.328C1382.65 12.1387 1382.16 12.8213 1381.47 13.376ZM1377.39 11.216C1377.74 11.856 1378.27 12.176 1378.99 12.176C1379.72 12.176 1380.25 11.856 1380.58 11.216C1380.92 10.576 1381.09 9.728 1381.09 8.672C1381.09 7.616 1380.92 6.77333 1380.58 6.144C1380.25 5.504 1379.72 5.184 1378.99 5.184C1378.27 5.184 1377.74 5.504 1377.39 6.144C1377.05 6.77333 1376.88 7.616 1376.88 8.672C1376.88 9.728 1377.05 10.576 1377.39 11.216Z" fill="#808080" />
                                            <path d="M1437.72 14H1435.45V6.256L1433.67 8.064L1432.37 6.704L1435.73 3.328H1437.72V14ZM1444.3 14H1442.02V6.256L1440.25 8.064L1438.95 6.704L1442.31 3.328H1444.3V14Z" fill="#808080" />
                                            <path d="M1499.81 14H1497.52V6.528L1494.51 14H1493.52L1490.51 6.528V14H1488.24V3.328H1491.42L1494.02 9.776L1496.61 3.328H1499.81V14Z" fill="#808080" />
                                        </svg>
                                    </div>

                                    <div className='flex items-center justify-between'>

                                        <div
                                            style={
                                                {
                                                    height: "190px",
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: "12px",
                                                    alignItems: 'center',

                                                }}
                                        >
                                            <div>OFF</div>
                                            <div>SB</div>
                                            <div>DR</div>
                                            <div>ON</div>
                                        </div>



                                        <div className='chart' style={{ display: 'flex' }}>

                                            {

                                                weeklyData.length > 0 ?


                                                    filteredData[index].activities.map((item, activityIndex) => {

                                                        let height = 0;
                                                        let minStep = 0;

                                                        if (filteredData[index].activities.length > activityIndex + 1) {
                                                            height = filteredData[index].activities[activityIndex + 1].step - item.step
                                                            minStep = Math.min(filteredData[index].activities[activityIndex + 1].step, item.step);

                                                            // console.log("filter: ", filteredData[index].activities[activityIndex + 1].step );
                                                            // console.log("index:", item.step);
                                                            // console.log("heigth:", height);

                                                        } else {
                                                            minStep = item.step;

                                                        }



                                                        return (
                                                            <div
                                                                style={{

                                                                    height: "190px",
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                    alignItems: 'end',

                                                                }}
                                                            >
                                                                <div
                                                                    style={{

                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end',
                                                                        alignItems: 'flex-end',
                                                                        position: 'relative',
                                                                        bottom: '30px',

                                                                    }}
                                                                >

                                                                    <div
                                                                        style={
                                                                            {
                                                                                background: item.activity == 'on' ? '#1996F0' : item.activity == 'off' ? "#808080" : item.activity == 'dr' ? "#5CB800" : item.activity == 'intermidiate' ? "#5CB800" : item.activity == 'sb' ? '#FFA800' : '',
                                                                                width: item.durtiondouble * 47,
                                                                                height: '5px',
                                                                                marginBottom: item.step * 32,


                                                                            }
                                                                        }></div>


                                                                    <div

                                                                        style={{
                                                                            width: '3px',
                                                                            paddingTop: Math.abs(height) * 30 + 7,
                                                                            marginBottom: minStep * 32,
                                                                            background: `linear-gradient(${height > 0 ? 'to bottom' : 'to top'},${filteredData[index].activities[activityIndex + 1] == undefined ? 'none' : filteredData[index].activities[activityIndex + 1].activity == "on" ? '#1996F0' : filteredData[index].activities[activityIndex + 1].activity == "sb" ? '#FFA800' : filteredData[index].activities[activityIndex + 1].activity == "dr" ? '#5CB800' : filteredData[index].activities[activityIndex + 1].activity == "off" ? '#808080' : ''},${item.activity == 'off' ? '#808080' : item.activity == 'sb' ? '#FFA800' : item.activity == 'dr' ? '#5CB800' : item.activity == "on" ? '#1996F0' : ''} )`


                                                                        }}> </div>


                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                                    : console.log("data pustoy")




                                            }
                                        </div>

                                        <div
                                            style={
                                                {
                                                    height: "190px",
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: "12px",
                                                    alignItems: 'center',

                                                }}>
                                            <div>08:13</div>
                                            <div>10:36</div>
                                            <div>03:59</div>
                                            <div>01:11</div>
                                        </div>
                                    </div>

                                </div>

                                <div className={`w-full h-[500px] mt-[50px] ${hideStates[index] ? 'block' : 'hidden'}`}>
                                    <table className='w-full '>
                                        <thead className='text[17px] border-b-[2px]' style={{ color: '#808080' }}>
                                            <th>STATUS</th>
                                            <th>DRIVER</th>
                                            <th>DURATION</th>
                                            <th>LAST KNOW LOCATION</th>
                                            <th>VEHICLE ID</th>
                                            <th>ODOMETR</th>
                                            <th>ENG.HOURS</th>
                                            <th>NOTES</th>
                                            <th>DOCUMENTS</th>
                                            <th>TRAILERS</th>
                                            <th>VIOLATIONS</th>
                                        </thead>

                                        <tbody>

                                            {
                                                item.activities.map((table) => (
                                                    <tr className=' h-[45px] border-b-[2px] border-gray-200 p-3 mt-3'>
                                                        <td className='text-center'>

                                                            <button
                                                                className=' pl-[8px] pr-[8px] rounded-[8px]'
                                                                style={{
                                                                    background: table.activity == "dr" ? '#E2F0ED' : table.activity == "on" ? '#E8F3FF' : table.activity == "off" ? '#F3F3F4' : table.activity == "sb" ? '#FFEECE' : '',
                                                                    color: table.activity == "dr" ? '#5CB800' : table.activity == "on" ? '#1996F0' : table.activity == "off" ? '#808080' : table.activity == "sb" ? '#FFA800' : '',
                                                                }}
                                                            >
                                                                {table.activity}
                                                            </button>

                                                        </td>

                                                        <td>{item.driver != null ? item.driver : 'not available'}</td>
                                                        <td>{table.duration}</td>
                                                        <td >
                                                            <div className='flex items-center justify-center gap-2'>
                                                                {table.location}
                                                                <span>{table.last_connect}</span>
                                                                <span>
                                                                    <a target='_blank' href={`https://www.google.com/search?q=${table.last_connect}&rlz=1C1VDKB_enUZ1075UZ1075&oq=kun&gs_lcrp=EgZjaHJvbWUyDwgAEEUYORiDARixAxiABDIGCAEQRRg8MgoIAhAAGLEDGIAEMhAIAxAuGIMBGNQCGLEDGIAEMgoIBBAAGLEDGIAEMhAIBRAAGIMBGLEDGIAEGIoFMg0IBhAAGIMBGLEDGIAEMgYIBxAFGEDSAQkxMTE5NmowajSoAgiwAgE&sourceid=chrome&ie=UTF-8`}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                                                            <path d="M10.5 9.50002L14.6 5.40002" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M14.9996 7.4V5H12.5996" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M9.5 5H8.5C6 5 5 6 5 8.5V11.5C5 14 6 15 8.5 15H11.5C14 15 15 14 15 11.5V10.5" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>

                                                                    </a>
                                                                </span>
                                                                <span>
                                                                    <button onClick={() => copyToClipboard(table.last_connect)}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                                                            <path d="M12.5 11.2001V12.7001C12.5 14.7001 11.7 15.5001 9.7 15.5001H7.8C5.8 15.5001 5 14.7001 5 12.7001V10.8001C5 8.80006 5.8 8.00006 7.8 8.00006H9.3" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M12.5008 11.2001H10.9008C9.70078 11.2001 9.30078 10.8001 9.30078 9.60006V8.00006L12.5008 11.2001Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M9.80078 5.50006H11.8008" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M7.5 7.00006C7.5 6.17006 8.17 5.50006 9 5.50006H10.31" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M15.0007 8.50006V11.5951C15.0007 12.3701 14.3707 13.0001 13.5957 13.0001" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                            <path d="M15 8.50006H13.5C12.375 8.50006 12 8.12506 12 7.00006V5.50006L15 8.50006Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>


                                                                    </button>
                                                                </span>

                                                            </div>
                                                        </td>
                                                        <td className='text-center'>{table.vehicle_id != null ? table.vehicle_id : "not available"}</td>
                                                        <td className='text-center'>{table.odometer != null ? table.odometer : "not available"}</td>
                                                        <td className='text-center'>{table.engine_hour != null ? table.engine_hour : "not available"}</td>
                                                        <td className='text-center'>{table.note != null ? table.note : "not available"}</td>
                                                        <td className='text-center'>{table.document != null ? table.document : "not available"}</td>
                                                        <td className='text-center'>{table.trailer != null ? table.trailer : ""}</td>
                                                        <td className='text-center'>{table.violations != null ? table.violations : "not available"}</td>
                                                    </tr>

                                                ))
                                            }


                                        </tbody>

                                    </table>
                                </div>



                            </div>
                        )
                    })
                }
            </div>
        )


    }


    return (

        <div className='w-full'>
            <div className=' w-full  h-auto p-2' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>
                <div>
                    {isLoading && (
                        <div style={
                            {
                                width: "100%",
                                height: "100vh",
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: 'center',
                                position: "absolute",
                                top: "0px",
                                bottom: "0px",
                                left: "100px",
                                right: "0px",
                            }}>
                            <CircularProgress />
                        </div>
                    )}

                </div>
                <Header paramsId={paramsId} />
                <Diogramma paramsId={paramsId} />

            </div>

        </div>


    )
}

export default DriverProgression