import React from 'react'
import TrackingDoc from './TrackingDoc'

function TrackingLayout() {
  return (
    <div className='pl-2 pr-2'>


        

        <main className="main-content">
            <div className='main-content'>
          
            </div>
            <TrackingDoc/>
        </main>

    </div>
  )
}

export default TrackingLayout