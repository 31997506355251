import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';



function Inspaction() {

    const [logs, setLogs] = useState([]);
    const itemsPerPage = 5; // har bir sahifada ko'rsatish uchun elementlar soni
    const [page, setPage] = useState(0);
    const [copied, setCopied] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://xertzdigital.net/inspection.json');
                setLogs(response.data);
            } catch (error) {
                console.error("Ma'lumotlarni yuklashda xatolik:", error);
            }
        };
        fetchData();
    }, []);

    const totalPages = Math.ceil(logs.length / itemsPerPage);

    const getPageData = () => {
        const start = page * itemsPerPage;
        return logs.slice(start, start + itemsPerPage);
    };

    const handleCopy = (location) => { 
        navigator.clipboard.writeText(location).then(() => 
            { setCopied(true); setTimeout(() => setCopied(false), 2000); 

            }).catch(err => { console.error('Failed to copy: ', err); });}

    return (
        <div className=' pl-2 pr-2'>
       
            <main className='main-content'>

              

                <div className='p-3'>
                    <div className='main-content'>
                    <div className='w-full h-[80px] flex items-center border-b-[2px] border-gray-300 mb-5'>
                        <h1 className='text-2xl font-bold' style={{ color: '#1996F0' }}>Inspection history</h1>
                    </div>


                    <Carousel showThumbs={false} showStatus={false}>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <div key={i} className='w-full bg-white h-full'>



                                {/* ------------------------------------------------------------------------------------------------------------------ */}

                                <div className='w-full border-2 rounded-lg p-4'>


                                    <table style={{ height: '60vh', width: '100%' }}>
                                        <thead>
                                            <tr className='font-normal text-sm border-b-2 pb-3' style={{ color: "#808080" }}>

                                                <th>DRIVERS</th>
                                                <th>VEHICLE ID</th>
                                                <th>LST KNOW LOCATION</th>
                                                <th>DATE & TIME</th>
                                                <th>GEOLOCATION</th>
                                                <th>VIEW LOGS</th>


                                            </tr>
                                        </thead>
                                        <tbody>

                                            {getPageData().map((log, index) => (

                                                <tr key={index} className='border-b-[2px]'>

                                                    <td>{log.drover}</td>
                                                    <td>{log.vehicle_id}</td>
                                                    <td >
                                                        <div className='flex items-center justify-center gap-2'>
                                                            {log.last_know_location}
                                                            <span>{log.last_connect}</span>
                                                            <span>
                                                                <button>
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                                                        <path d="M10.5 9.50002L14.6 5.40002" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M14.9996 7.4V5H12.5996" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M9.5 5H8.5C6 5 5 6 5 8.5V11.5C5 14 6 15 8.5 15H11.5C14 15 15 14 15 11.5V10.5" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>

                                                                </button>
                                                            </span>
                                                            <span>
                                                                <button onClick={() => handleCopy(log.last_know_location)}>
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                                                        <path d="M12.5 11.2001V12.7001C12.5 14.7001 11.7 15.5001 9.7 15.5001H7.8C5.8 15.5001 5 14.7001 5 12.7001V10.8001C5 8.80006 5.8 8.00006 7.8 8.00006H9.3" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.5008 11.2001H10.9008C9.70078 11.2001 9.30078 10.8001 9.30078 9.60006V8.00006L12.5008 11.2001Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M9.80078 5.50006H11.8008" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.5 7.00006C7.5 6.17006 8.17 5.50006 9 5.50006H10.31" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M15.0007 8.50006V11.5951C15.0007 12.3701 14.3707 13.0001 13.5957 13.0001" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M15 8.50006H13.5C12.375 8.50006 12 8.12506 12 7.00006V5.50006L15 8.50006Z" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>


                                                                </button>
                                                            </span>

                                                        </div>
                                                    </td>
                                                    <td>{log.date_time}</td>

                                                    <td className=' h-full flex items-center justify-center'>
                                                        <button>
                                                            <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="0.5" width="35" height="35" rx="4" fill="#E8F3FF" />
                                                                <path d="M24.9826 25.6083C24.8159 25.6083 24.6576 25.5417 24.5409 25.425L19.2242 20.1083C18.9826 19.8666 18.9826 19.4667 19.2242 19.225L25.6659 12.7833C25.8242 12.625 26.0659 12.5667 26.2826 12.625C26.4992 12.6917 26.6659 12.8667 26.7159 13.0833C26.8742 13.7917 26.9576 14.5833 26.9576 15.5V20.5C26.9576 22.8083 26.4742 24.3667 25.4242 25.425C25.3076 25.5417 25.1326 25.5667 24.9826 25.6083ZM20.5492 19.6666L24.9326 24.05C25.4576 23.2416 25.7076 22.0916 25.7076 20.5V15.5C25.7076 15.1583 25.6992 14.8417 25.6742 14.5417L20.5492 19.6666Z" fill="#1996F0" />
                                                                <path d="M13.2243 26.7334C13.1743 26.7334 13.1327 26.725 13.0827 26.7167C10.3244 26.0834 9.04102 24.1084 9.04102 20.5V15.5C9.04102 10.975 10.9743 9.04169 15.4993 9.04169H20.4994C24.1077 9.04169 26.0827 10.325 26.716 13.0834C26.766 13.2917 26.6994 13.5167 26.5494 13.6667L13.666 26.55C13.5493 26.6667 13.391 26.7334 13.2243 26.7334ZM15.4993 10.2917C11.6577 10.2917 10.291 11.6584 10.291 15.5V20.5C10.291 23.3917 11.091 24.8417 13.0327 25.4167L25.4077 13.0417C24.841 11.1 23.3827 10.3 20.491 10.3H15.4993V10.2917Z" fill="#1996F0" />
                                                                <path d="M20.5003 26.9583H15.5003C14.5836 26.9583 13.8003 26.8834 13.0837 26.7167C12.8587 26.6667 12.6836 26.5 12.6253 26.2833C12.5586 26.0667 12.6253 25.8333 12.7836 25.6667L19.2253 19.225C19.467 18.9833 19.867 18.9833 20.1086 19.225L25.4253 24.5417C25.542 24.6583 25.6086 24.8167 25.6086 24.9833C25.6086 25.15 25.542 25.3083 25.4253 25.425C24.367 26.475 22.8086 26.9583 20.5003 26.9583ZM14.542 25.675C14.842 25.7 15.1586 25.7083 15.5003 25.7083H20.5003C22.1003 25.7083 23.242 25.4584 24.0503 24.9334L19.667 20.55L14.542 25.675Z" fill="#1996F0" />
                                                                <path d="M15.5991 19.0916C15.0741 19.0916 14.5491 18.9 14.1325 18.5083C12.8075 17.25 12.2741 15.8666 12.5908 14.5166C12.9075 13.1333 14.1158 12.2 15.5991 12.2C17.0825 12.2 18.2908 13.1333 18.6075 14.5166C18.9158 15.875 18.3825 17.25 17.0575 18.5083C16.6491 18.8916 16.1241 19.0916 15.5991 19.0916ZM13.8075 14.7916C13.5408 15.925 14.3075 16.9416 14.9991 17.6C15.3408 17.925 15.8658 17.925 16.1991 17.6C16.8825 16.95 17.6491 15.9333 17.3908 14.7916C17.1658 13.8 16.2825 13.4416 15.5991 13.4416C14.9158 13.4416 14.0408 13.8 13.8075 14.7916Z" fill="#1996F0" />
                                                                <path d="M15.6243 15.9083C15.166 15.9083 14.791 15.5333 14.791 15.075C14.791 14.6166 15.1577 14.2416 15.6243 14.2416H15.6327C16.091 14.2416 16.466 14.6166 16.466 15.075C16.466 15.5333 16.0827 15.9083 15.6243 15.9083Z" fill="#1996F0" />
                                                            </svg>

                                                        </button>
                                                    </td>

                                                    <td className=''>
                                                   <button>
                                                         <a href={log.view_logs} download>
                                                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect width="35" height="35" rx="4" fill="#E8F3FF" />
                                                                <path d="M15.7656 17.7333L17.899 19.8667L20.0323 17.7333" stroke="#1996F0" stroke-width="1.45833" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M17.9004 11.3333V19.8083" stroke="#1996F0" stroke-width="1.45833" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M24.6673 18.15C24.6673 21.8334 22.1673 24.8167 18.0007 24.8167C13.834 24.8167 11.334 21.8334 11.334 18.15" stroke="#1996F0" stroke-width="1.45833" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>

                                                        </a>
                                                   </button>
                                                    </td>

                                                </tr>

                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </Carousel>

                    </div>
                </div>

            </main>
        </div>
    )
}

export default Inspaction