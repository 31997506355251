import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../App';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContext } from '../../../LoaderProvider';
import CopyButton from '../Logs/CopyButton';
import TableNav from '../TableNav/TableNav';



function TrackingDoc() {
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { isLoading, setIsLoading } = useContext(LoaderContext);

    let companyId = localStorage.getItem("companyId");
    const navigate = useNavigate()

    const fetchData = async (page) => {
        setIsLoading(true)
        try {
            const response = await axios.get(`https://xertzdigital.net:8001/api/v1/main/dashboard/tracking/${companyId}/?page=${page}`);
            setLogs(response.data.results);
            setTotalPages(response.data.total_pages);
            console.log(response);


        } catch (error) {
            console.error('Xato:', error);
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const handleChange = (e, p) => {

        setCurrentPage(p);
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
    };


    const Table = () => {
        const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
      
        const sortedLogs = React.useMemo(() => {
          if (!sortConfig.key) return logs;
      
          return [...logs].sort((a, b) => {
            const aValue = (a[sortConfig.key] || "").toString().charAt(0).toLowerCase();
            const bValue = (b[sortConfig.key] || "").toString().charAt(0).toLowerCase();
      
            if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
          });
        }, [logs, sortConfig]);
      
        const requestSort = (key) => {
          let direction = "asc";
          if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
          }
          setSortConfig({ key, direction });
        };
      
        const getSortIndicator = (key) => {
          if (sortConfig.key === key) {
            return sortConfig.direction === "asc" ? <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.3002 6.27083L5.58353 3.55416C5.2627 3.23333 4.7377 3.23333 4.41686 3.55416L1.7002 6.27083" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg> : <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.69981 3.72917L4.41647 6.44584C4.7373 6.76667 5.2623 6.76667 5.58314 6.44584L8.2998 3.72917" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>;
          }
          return <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.3002 6.27083L5.58353 3.55416C5.2627 3.23333 4.7377 3.23333 4.41686 3.55416L1.7002 6.27083" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1.69981 13.7292L4.41647 16.4458C4.7373 16.7667 5.2623 16.7667 5.58314 16.4458L8.2998 13.7292" stroke="#808080" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          ; // Default indicator for sortable columns
        };
      
        return (
          <div className="h-[73vh] w-full border-2 rounded-lg p-4 flex items-center justify-between flex-col">
            <table className="w-full border-collapse">
              <thead className="w-full text-[13px] text-gray-500">
                <tr className="font-bold border-b-2 pb-3" style={{ color: "#808080" }}>
                  <th className="font-[600] p-2 text-left cursor-pointer" onClick={() => requestSort("status")}> <div className='flex gap-2'>STATUS <span>{getSortIndicator("status")}</span></div></th>
                  <th className="font-[600] p-2 text-left cursor-pointer" onClick={() => requestSort("first_name")}> <div className='flex gap-2'>DRIVER <p>{getSortIndicator("first_name")}</p></div></th>
                  <th className="font-[600] p-2 text-left cursor-pointer" onClick={() => requestSort("vehicle_number")}> <div className='flex gap-2'>VEHICLE ID <p>{getSortIndicator("vehicle_number")}</p></div></th>
                  <th className="font-[600] p-2 text-left cursor-pointer" onClick={() => requestSort("location")}> <div className='flex gap-2'>LAST KNOW LOCATION <span>{getSortIndicator("location")}</span></div></th>
                </tr>
              </thead>
              <tbody className="text-[12px]">
                {sortedLogs.map((log, index) => (
                  <tr key={index} className="border-b-2 h-[50px] text-left hover:bg-gray-100 hover:text-black">
                    <td>
                      <Link to={`/layout/tracking/${log.id}`}>
                        <div className="pb-3 pt-3">
                          <button
                            className="pl-3 pr-3 rounded-lg text-green-600 font-bold bg-green-100"
                            style={{
                              backgroundColor:
                                log.status === "DR"
                                  ? "#E2F0ED"
                                  : log.status === "SB"
                                  ? "#FFEECE"
                                  : "#FFE2E3",
                              color:
                                log.status === "DR"
                                  ? "#5CB800"
                                  : log.status === "SB"
                                  ? "#FFA800"
                                  : "#ED3237",
                            }}
                          >
                            {log.last_activity != null ? log.last_activity.activity : ""}
                          </button>
                        </div>
                      </Link>
                    </td>
      
                    <td>
                      <Link to={`/layout/tracking/${log.id}`}>
                        <div className="pt-3 pb-3">
                          <p>
                            {log.first_name} {log.last_name}
                          </p>
                        </div>
                      </Link>
                    </td>
      
                    <td>
                      <Link to={`/layout/tracking/${log.id}`}>
                        <div className="pt-3 pb-3">
                          <p>
                            {log.last_activity != null ? log.last_activity.vehicle_number : "not active"}
                          </p>
                        </div>
                      </Link>
                    </td>
      
                    <td className="t-item flex items-center gap-2 p-2 z-0">
                      <TableNav url={`/layout/tracking/${log.id}`} />
      
                      <p className="flex-1 text-left">
                        {log.last_activity != null ? log.last_activity.location : "not active"}
                      </p>
      
                      <button
                        className={`$ {
                          log.last_activity != null
                            ? log.last_activity.location == null
                              ? "isB-disabled"
                              : ""
                            : "isB-disabled"
                        }`}
                      >
                        <a
                          target="_blank"
                          href={`https://www.google.com/search?q=${
                            log.last_activity != null ? log.last_activity.location : "not active"
                          }`}
                          rel="noopener noreferrer"
                          className="hover:underline"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                            <path
                              d="M10.5 9.50002L14.6 5.40002"
                              stroke="#1996F0"
                              strokeWidth="1.25"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.9996 7.4V5H12.5996"
                              stroke="#1996F0"
                              strokeWidth="1.25"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.5 5H8.5C6 5 5 6 5 8.5V11.5C5 14 6 15 8.5 15H11.5C14 15 15 14 15 11.5V10.5"
                              stroke="#1996F0"
                              strokeWidth="1.25"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </a>
                      </button>
      
                      <CopyButton
                        text={log.last_activity != null ? log.last_activity.location : "not active"}
                        copy={log.last_activity != null ? true : false}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
      
            <div className="flex items-center justify-center">
              <Pagination count={totalPages} shape="rounded" color="primary" onChange={handleChange} />
            </div>
          </div>
        );
      };
      
      


    const user = useContext(UserContext);

    return (
        <div className='p-3' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>

            <div className='main-content'>
                <div className='w-full h-full' style={{ background: user.night ? '#fff' : "#1E2D4C", color: user.night ? '' : "#fff", transition: 'all 0.3s ease' }}>


                    <div className='flex items-center justify-between border-b-[1px] border-gray-300 mb-3 pb-[5px]'>
                        <h1 className='text-2xl font-bold' style={{ color: '#1996F0' }}>Tracking</h1>
                        <div className='flex items-center gap-3'>

                            <div className='flex items-center gap-2 rounded-lg p-1' style={{ border: "2px solid #E6E6E6" }}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.58366 17.5C13.9559 17.5 17.5003 13.9556 17.5003 9.58335C17.5003 5.2111 13.9559 1.66669 9.58366 1.66669C5.2114 1.66669 1.66699 5.2111 1.66699 9.58335C1.66699 13.9556 5.2114 17.5 9.58366 17.5Z" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.3337 18.3334L16.667 16.6667" stroke="#808080" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <input type="text" placeholder='Search' maxLength={100} className='focus:outline-none placeholder:text-gray-500 text-gray-500 bg-transparent' />
                            </div>

                            <div className='p-1 rounded-lg text-gray-500 ' style={{ border: "2px solid #E6E6E6" }}>
                                <input type="date" className='bg-transparent' />
                            </div>

                            <div className='flex items-center gap-3 p-2 rounded-md'  style={{ background: '#E8F3FF', color: '#1996F0' }}>
                                <button
                                    className=' pr-1 pl-1   border-none'
                                    
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4995 16.6L7.06621 11.1667C6.42454 10.525 6.42454 9.47503 7.06621 8.83336L12.4995 3.40002" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                </button>
                                <button
                                    className=' pr-1 pl-1  border-none'
                                   
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.50046 3.39997L12.9338 8.83331C13.5755 9.47498 13.5755 10.525 12.9338 11.1666L7.50046 16.6" stroke="#1996F0" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                </button>
                            </div>

                            <button onClick={fetchData}>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="36" width="36" height="36" rx="4" transform="rotate(90 36 0)" fill="#E8F3FF" />
                                    <path d="M20.8901 11.08C20.0201 10.82 19.0601 10.65 18.0001 10.65C13.2101 10.65 9.33008 14.53 9.33008 19.32C9.33008 24.12 13.2101 28 18.0001 28C22.7901 28 26.6701 24.12 26.6701 19.33C26.6701 17.55 26.1301 15.89 25.2101 14.51" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1302 11.32L19.2402 8" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22.1298 11.3199L18.7598 13.7799" stroke="#1996F0" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            </button>

                        </div>
                    </div>

                    <div>
                        {isLoading && (
                            <div style={
                                {
                                    width: "100%",
                                    height: "100vh",
                                    display: 'flex',
                                    alignItems: "center",
                                    justifyContent: 'center',
                                    position: "absolute",
                                    top: "0px",
                                    bottom: "0px",
                                    left: "100px",
                                    right: "0px",
                                }}>
                                <CircularProgress />
                            </div>
                        )}

                    </div>

                    {/* <div className=' h-[73vh] w-full border-2 rounded-lg p-4 flex items-center justify-between flex-col'>



                        <table className="w-full border-collapse">
                            <thead className="w-full text-[13px] text-gray-500">
                                <tr className="font-bold border-b-2 pb-3" style={{ color: "#808080" }}>
                                    <th className="font-[600] p-2 text-left">STATUS</th>
                                    <th className="font-[600] p-2 text-left">DRIVER</th>
                                    <th className="font-[600] p-2 text-left">VEHICLE ID</th>
                                    <th className="font-[600] p-2 text-left">LAST KNOW LOCATION</th>
                                </tr>
                            </thead>
                            <tbody className="text-[12px]">
                                {logs.map((log, index) => (
                                    <tr
                                        key={index}
                                        className="border-b-2 h-[50px] text-left hover:bg-gray-100 hover:text-black"
                                    >
                                        <td>
                                            <Link to={`/layout/tracking/${log.id}`}>
                                                <div className="pb-3 pt-3">
                                                    <button
                                                        className="pl-3 pr-3 rounded-lg text-green-600 font-bold bg-green-100"
                                                        style={{
                                                            backgroundColor:
                                                                log.status === "DR"
                                                                    ? "#E2F0ED"
                                                                    : log.status === "SB"
                                                                        ? "#FFEECE"
                                                                        : "#FFE2E3",
                                                            color:
                                                                log.status === "DR"
                                                                    ? "#5CB800"
                                                                    : log.status === "SB"
                                                                        ? "#FFA800"
                                                                        : "#ED3237",
                                                        }}
                                                    >
                                                        {log.last_activity != null
                                                            ? log.last_activity.activity
                                                            : ""}
                                                    </button>
                                                </div>
                                            </Link>
                                        </td>

                                        <td>
                                            <Link to={`/layout/tracking/${log.id}`}>
                                                <div className="pt-3 pb-3">
                                                    <p>
                                                        {log.first_name} {log.last_name}
                                                    </p>
                                                </div>
                                            </Link>
                                        </td>

                                        <td>
                                            <Link to={`/layout/tracking/${log.id}`}>
                                                <div className="pt-3 pb-3">
                                                    <p>
                                                        {log.last_activity != null
                                                            ? log.last_activity.vehicle_number
                                                            : "not active"}
                                                    </p>
                                                </div>
                                            </Link>
                                        </td>

                                        <td className="t-item flex items-center gap-2 p-2 z-0">
                                            <TableNav url={`/layout/tracking/${log.id}`} />

                                            <p className="flex-1 text-left">
                                                {log.last_activity != null ? log.last_activity.location : "not active"}
                                            </p>

                                            <button className={`${log.last_activity != null ? log.last_activity.location == null ? 'isB-disabled' : '' : 'isB-disabled'}`}>
                                                <a
                                                    target="_blank"
                                                    href={`https://www.google.com/search?q=${log.last_activity != null ? log.last_activity.location : "not active"}`}
                                                    rel="noopener noreferrer"
                                                    className="hover:underline"
                                                >
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect width="20" height="20" rx="4" fill="#E8F3FF" />
                                                        <path
                                                            d="M10.5 9.50002L14.6 5.40002"
                                                            stroke="#1996F0"
                                                            strokeWidth="1.25"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M14.9996 7.4V5H12.5996"
                                                            stroke="#1996F0"
                                                            strokeWidth="1.25"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M9.5 5H8.5C6 5 5 6 5 8.5V11.5C5 14 6 15 8.5 15H11.5C14 15 15 14 15 11.5V10.5"
                                                            stroke="#1996F0"
                                                            strokeWidth="1.25"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </a>
                                            </button>


                                            <CopyButton text={log.last_activity != null ? log.last_activity.location : "not active"} copy={log.last_activity != null ? true : false} />

                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>


                        <div className=' flex items-center justify-center'>
                            <Pagination count={totalPages} shape="rounded" color='primary' onChange={handleChange} />
                        </div>

                    </div> */}
                    <Table/>
                </div>
            </div>

        </div>
    )
}

export default TrackingDoc