import React from 'react'
import Vehisles from './Vehicles'

function VehiclesLayout() {
  return (
    <div>



      <main className="main-content">

        <Vehisles />
      </main>

    </div>
  )
}

export default VehiclesLayout