import React, { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DriverProgression from './DriverProgression';

function DriverDoc() {
    const params = useParams();
    
  return (
    <div style={{ background: "#E6E6E6" }}>

    

        <main className="main-content">
          

          <div className='w-full'>
          <DriverProgression paramsId={params.id}/>
          </div>


        </main>

    </div>
  )
}

export default DriverDoc;