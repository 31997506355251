import React from 'react'
import Resources from './Resources'

function ResourcesLayout() {
  return (
    <div className='pl-2 pr-2'>
      
        <main className='main-content'>
           
            <Resources/>
        </main>
    </div>
  )
}

export default ResourcesLayout