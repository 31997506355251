import React from 'react'
import { useNavigate } from 'react-router-dom'

const TableNav = ({ url }) => {
    const navigate = useNavigate()

    return (
        <div className={"table-bg hover:bg-gray-100"} onClick={() => navigate(url)}></div>
    )
}

export default TableNav