import React from 'react'
import DriverDoc from './DriverDoc'

function DriverLayout() {
  return (
    <div >

    

        <main className="main-content">            
            <DriverDoc/>
        </main>

    </div>
  )
}

export default DriverLayout